:root {
  --cream: #f8f8e5;
  --limeGreen: #c0cf37;
  --black: #000000;
  --teal: #225d66;
  --gray: #313539;
}

.carousel .slide {
  background: transparent !important;
  color: var(--black);
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: transparent;

}

.carousel .control-dots {
  bottom: 0;
}

.myCarousel h3 {
  color: var(--cream);
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: var(--limeGreen);
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: var(--cream);
  font-size: 15px;
  font-family: sans-serif;
/* make it italic */
  font-style: italic;
  width: 75vw;
}

.myCarousel p:before {
  content: "“";
  color: var(--limeGreen);
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: var(--limeGreen);
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: var(--cream) !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: var(--limeGreen) !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
  opacity: 1 !important;
}

.carousel .control-prev.control-arrow:before{
  border-right-color: var(--black)
}

.carousel .control-next.control-arrow:before{
  border-left-color: var(--black)
}

.App {
  text-align: center;
}

.carousel.carousel-slider{
  padding-bottom: 25px;
}

@media only screen and (max-width: 934px) {

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    /* margin-top: -8%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    height: 269px; */
    padding-bottom: 25px;

  }

  .carousel.carousel-slider{
    padding-bottom: 0px;
  }

  .carousel .slide img {
    width: 35vw !important;
    border-radius: 50%;
    max-width: 140px;
    max-height: 140px;
  }
}
