
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*='language-'],
pre[class*='language-'] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.jqvmap-label,
.jqvmap-pin {
  pointer-events: none;
}
.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: #fff;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
}
.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000;
  padding: 3px;
  color: #fff;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}
.jqvmap-zoomin {
  top: 10px;
}
.jqvmap-zoomout {
  top: 30px;
}
.jqvmap-region {
  cursor: pointer;
}
.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}

/*!

=========================================================
* Leaf Non-Profit environmental Bootstrap 4 Theme
=========================================================

* Product Page: https://themesberg.com/product/web-templates/leaf-non-profit-environmental-bootstrap-4-theme
* Copyright 2019 Themesberg (https://www.themesberg.com)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sanchez:400,400i&display=swap); /*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #fa5252;
  --orange: #ff7f00;
  --yellow: #f5b759;
  --green: #00bf9a;
  --teal: #1e90ff;
  --cyan: #2bffc6;
  --white: #ffffff;
  --gray: #869ab8;
  --gray-dark: #506690;
  --primary: #11ab7c;
  --secondary: #005ad4;
  --success: #00bf9a;
  --info: #1e90ff;
  --warning: #f5b759;
  --danger: #fa5252;
  --light: #dde1ed;
  --dark: #2a354f;
  --tertiary: #de6926;
  --orange: #ff7f00;
  --white: #ffffff;
  --gray: #66799e;
  --neutral: #ffffff;
  --soft: #f3f7fa;
  --black: #3e4555;
  --purple: #8965e0;
  --gray-100: #f3f7fa;
  --gray-200: #e9ecef;
  --gray-300: #dde1ed;
  --gray-400: #e0e6f0;
  --gray-500: #adb5bd;
  --gray-600: #869ab8;
  --gray-700: #66799e;
  --gray-800: #506690;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 17, 17, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #506690;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 2rem;
}
p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 600;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: 700;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #11ab7c;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0c7d5a;
  text-decoration: none;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #869ab8;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 2rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.4;
  color: #2a354f;
}
.h1,
h1 {
  font-size: 2.5rem;
}
@media (max-width: 1200px) {
  .h1,
  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}
.h2,
h2 {
  font-size: 2rem;
}
@media (max-width: 1200px) {
  .h2,
  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}
.h3,
h3 {
  font-size: 1.75rem;
}
@media (max-width: 1200px) {
  .h3,
  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}
.h4,
h4 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  .h4,
  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 5rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
  }
}
.display-2 {
  font-size: 3.3rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.455rem + 2.46vw);
  }
}
.display-3 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.375rem + 1.5vw);
  }
}
.display-4 {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.3125rem + 0.75vw);
  }
}
hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 0.0625rem solid rgba(17, 17, 17, 0.05);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #869ab8;
}
.blockquote-footer::before {
  content: '\2014\00A0';
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid #dde1ed;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(17, 17, 17, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #869ab8;
}
code {
  font-size: 87.5%;
  color: #f3a4b5;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #3e4555;
  border-radius: 0.125rem;
  box-shadow: inset 0 -0.1rem 0 rgba(17, 17, 17, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #3e4555;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 786px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1325px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #506690;
  background-color: transparent;
}
.table td,
.table th {
  padding: 1rem;
  vertical-align: top;
  border-top: 0.0625rem solid #e9ecef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0.125rem solid #e9ecef;
}
.table tbody + tbody {
  border-top: 0.125rem solid #e9ecef;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 0.0625rem solid #e9ecef;
}
.table-bordered td,
.table-bordered th {
  border: 0.0625rem solid #e9ecef;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 0.125rem;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(17, 17, 17, 0.05);
}
.table-hover tbody tr:hover {
  color: #506690;
  background-color: rgba(17, 17, 17, 0.03);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #bce7da;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #83d3bb;
}
.table-hover .table-primary:hover {
  background-color: #a9e0d0;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a9e0d0;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #b8d1f3;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #7aa9e9;
}
.table-hover .table-secondary:hover {
  background-color: #a2c3ef;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #a2c3ef;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #b8ede3;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #7adeca;
}
.table-hover .table-success:hover {
  background-color: #a4e8db;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a4e8db;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #c0e0ff;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #8ac5ff;
}
.table-hover .table-info:hover {
  background-color: #a7d3ff;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a7d3ff;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fcebd1;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fadaa9;
}
.table-hover .table-warning:hover {
  background-color: #fae1b9;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae1b9;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #fecfcf;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #fca5a5;
}
.table-hover .table-danger:hover {
  background-color: #fdb6b6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdb6b6;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #f5f7fa;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #edeff6;
}
.table-hover .table-light:hover {
  background-color: #e4e9f2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e4e9f2;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c3c6ce;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #9096a3;
}
.table-hover .table-dark:hover {
  background-color: #b5b9c3;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b5b9c3;
}
.table-tertiary,
.table-tertiary > td,
.table-tertiary > th {
  background-color: #f6d5c2;
}
.table-tertiary tbody + tbody,
.table-tertiary td,
.table-tertiary th,
.table-tertiary thead th {
  border-color: #eeb18e;
}
.table-hover .table-tertiary:hover {
  background-color: #f3c6ac;
}
.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #f3c6ac;
}
.table-orange,
.table-orange > td,
.table-orange > th {
  background-color: #ffdbb8;
}
.table-orange tbody + tbody,
.table-orange td,
.table-orange th,
.table-orange thead th {
  border-color: #ffbc7a;
}
.table-hover .table-orange:hover {
  background-color: #ffce9f;
}
.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #ffce9f;
}
.table-white,
.table-white > td,
.table-white > th {
  background-color: #fff;
}
.table-white tbody + tbody,
.table-white td,
.table-white th,
.table-white thead th {
  border-color: #fff;
}
.table-hover .table-white:hover {
  background-color: #f2f2f2;
}
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}
.table-gray,
.table-gray > td,
.table-gray > th {
  background-color: #d4d9e4;
}
.table-gray tbody + tbody,
.table-gray td,
.table-gray th,
.table-gray thead th {
  border-color: #afb9cd;
}
.table-hover .table-gray:hover {
  background-color: #c4cbda;
}
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #c4cbda;
}
.table-neutral,
.table-neutral > td,
.table-neutral > th {
  background-color: #fff;
}
.table-neutral tbody + tbody,
.table-neutral td,
.table-neutral th,
.table-neutral thead th {
  border-color: #fff;
}
.table-hover .table-neutral:hover {
  background-color: #f2f2f2;
}
.table-hover .table-neutral:hover > td,
.table-hover .table-neutral:hover > th {
  background-color: #f2f2f2;
}
.table-soft,
.table-soft > td,
.table-soft > th {
  background-color: #fcfdfe;
}
.table-soft tbody + tbody,
.table-soft td,
.table-soft th,
.table-soft thead th {
  border-color: #f9fbfc;
}
.table-hover .table-soft:hover {
  background-color: #e9f0f8;
}
.table-hover .table-soft:hover > td,
.table-hover .table-soft:hover > th {
  background-color: #e9f0f8;
}
.table-black,
.table-black > td,
.table-black > th {
  background-color: #c9cbcf;
}
.table-black tbody + tbody,
.table-black td,
.table-black th,
.table-black thead th {
  border-color: #9b9ea7;
}
.table-hover .table-black:hover {
  background-color: #bcbec3;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #bcbec3;
}
.table-purple,
.table-purple > td,
.table-purple > th {
  background-color: #ded4f6;
}
.table-purple tbody + tbody,
.table-purple td,
.table-purple th,
.table-purple thead th {
  border-color: #c2afef;
}
.table-hover .table-purple:hover {
  background-color: #cebff2;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #cebff2;
}
.table-gray-100,
.table-gray-100 > td,
.table-gray-100 > th {
  background-color: #fcfdfe;
}
.table-gray-100 tbody + tbody,
.table-gray-100 td,
.table-gray-100 th,
.table-gray-100 thead th {
  border-color: #f9fbfc;
}
.table-hover .table-gray-100:hover {
  background-color: #e9f0f8;
}
.table-hover .table-gray-100:hover > td,
.table-hover .table-gray-100:hover > th {
  background-color: #e9f0f8;
}
.table-gray-200,
.table-gray-200 > td,
.table-gray-200 > th {
  background-color: #f9fafb;
}
.table-gray-200 tbody + tbody,
.table-gray-200 td,
.table-gray-200 th,
.table-gray-200 thead th {
  border-color: #f4f5f7;
}
.table-hover .table-gray-200:hover {
  background-color: #eaedf1;
}
.table-hover .table-gray-200:hover > td,
.table-hover .table-gray-200:hover > th {
  background-color: #eaedf1;
}
.table-gray-300,
.table-gray-300 > td,
.table-gray-300 > th {
  background-color: #f5f7fa;
}
.table-gray-300 tbody + tbody,
.table-gray-300 td,
.table-gray-300 th,
.table-gray-300 thead th {
  border-color: #edeff6;
}
.table-hover .table-gray-300:hover {
  background-color: #e4e9f2;
}
.table-hover .table-gray-300:hover > td,
.table-hover .table-gray-300:hover > th {
  background-color: #e4e9f2;
}
.table-gray-400,
.table-gray-400 > td,
.table-gray-400 > th {
  background-color: #f6f8fb;
}
.table-gray-400 tbody + tbody,
.table-gray-400 td,
.table-gray-400 th,
.table-gray-400 thead th {
  border-color: #eff2f7;
}
.table-hover .table-gray-400:hover {
  background-color: #e4eaf3;
}
.table-hover .table-gray-400:hover > td,
.table-hover .table-gray-400:hover > th {
  background-color: #e4eaf3;
}
.table-gray-500,
.table-gray-500 > td,
.table-gray-500 > th {
  background-color: #e8eaed;
}
.table-gray-500 tbody + tbody,
.table-gray-500 td,
.table-gray-500 th,
.table-gray-500 thead th {
  border-color: #d4d9dd;
}
.table-hover .table-gray-500:hover {
  background-color: #dadde2;
}
.table-hover .table-gray-500:hover > td,
.table-hover .table-gray-500:hover > th {
  background-color: #dadde2;
}
.table-gray-600,
.table-gray-600 > td,
.table-gray-600 > th {
  background-color: #dde3eb;
}
.table-gray-600 tbody + tbody,
.table-gray-600 td,
.table-gray-600 th,
.table-gray-600 thead th {
  border-color: #c0cada;
}
.table-hover .table-gray-600:hover {
  background-color: #cdd6e2;
}
.table-hover .table-gray-600:hover > td,
.table-hover .table-gray-600:hover > th {
  background-color: #cdd6e2;
}
.table-gray-700,
.table-gray-700 > td,
.table-gray-700 > th {
  background-color: #d4d9e4;
}
.table-gray-700 tbody + tbody,
.table-gray-700 td,
.table-gray-700 th,
.table-gray-700 thead th {
  border-color: #afb9cd;
}
.table-hover .table-gray-700:hover {
  background-color: #c4cbda;
}
.table-hover .table-gray-700:hover > td,
.table-hover .table-gray-700:hover > th {
  background-color: #c4cbda;
}
.table-gray-800,
.table-gray-800 > td,
.table-gray-800 > th {
  background-color: #ced4e0;
}
.table-gray-800 tbody + tbody,
.table-gray-800 td,
.table-gray-800 th,
.table-gray-800 thead th {
  border-color: #a4afc5;
}
.table-hover .table-gray-800:hover {
  background-color: #bec6d6;
}
.table-hover .table-gray-800:hover > td,
.table-hover .table-gray-800:hover > th {
  background-color: #bec6d6;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(17, 17, 17, 0.03);
}
.table-hover .table-active:hover {
  background-color: rgba(4, 4, 4, 0.03);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(4, 4, 4, 0.03);
}
.table .thead-dark th {
  color: #fff;
  background-color: #3e4555;
  border-color: #4e576b;
}
.table .thead-light th {
  color: #66799e;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.table-dark {
  color: #fff;
  background-color: #3e4555;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #4e576b;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.95rem + 1.2rem + 0.0625rem);
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid #e3ecf2;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 30px !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  border-color: #b7c3d2;
  background-color: #fafbfe;
  outline: none;
}
.form-control:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:focus::-ms-value {
  color: #869ab8;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.6rem + 0.0625rem);
  padding-bottom: calc(0.6rem + 0.0625rem);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.875rem + 0.0625rem);
  padding-bottom: calc(0.875rem + 0.0625rem);
  font-size: 1rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 0.0625rem);
  padding-bottom: calc(0.25rem + 0.0625rem);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #506690;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 0.0625rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}
.form-control-lg {
  height: calc(1.5em + 1.75rem + 0.0625rem);
  padding: 0.875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #869ab8;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00bf9a;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 191, 154, 0.9);
  border-radius: 0.5rem;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #00bf9a;
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bf9a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.3rem);
  background-size: calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #00bf9a;
  box-shadow: 0 0 0 0 rgba(0, 191, 154, 0.25);
}
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #00bf9a;
  padding-right: calc((1em + 1.2rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23506690' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bf9a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.4875rem + 0.6rem)
      calc(0.4875rem + 0.6rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #00bf9a;
  box-shadow: 0 0 0 0 rgba(0, 191, 154, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #00bf9a;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #00bf9a;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #00bf9a;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #00f2c3;
  background-color: #00f2c3;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 191, 154, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #00bf9a;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #00bf9a;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #00bf9a;
  box-shadow: 0 0 0 0 rgba(0, 191, 154, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fa5252;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(250, 82, 82, 0.9);
  border-radius: 0.5rem;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #fa5252;
  padding-right: calc(1.5em + 1.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FA5252' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FA5252' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.3rem);
  background-size: calc(0.4875rem + 0.6rem) calc(0.4875rem + 0.6rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #fa5252;
  box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.2rem);
  background-position: top calc(0.375em + 0.3rem) right calc(0.375em + 0.3rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #fa5252;
  padding-right: calc((1em + 1.2rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23506690' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FA5252' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FA5252' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.4875rem + 0.6rem)
      calc(0.4875rem + 0.6rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #fa5252;
  box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #fa5252;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #fa5252;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #fa5252;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #fb8484;
  background-color: #fb8484;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #fa5252;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #fa5252;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #fa5252;
  box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 600;
  color: #506690;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.55rem 0.95rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #506690;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0e8863;
  border-color: #0c7d5a;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(53, 184, 144, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c7d5a;
  border-color: #0b7152;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(53, 184, 144, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #004aae;
  border-color: #0044a1;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(38, 115, 218, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0044a1;
  border-color: #003f94;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(38, 115, 218, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-success:hover {
  color: #fff;
  background-color: #00997b;
  border-color: #008c71;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(38, 201, 169, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #008c71;
  border-color: #007f67;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(38, 201, 169, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-info:hover {
  color: #fff;
  background-color: #007df7;
  border-color: #0077ea;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(64, 161, 255, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0077ea;
  border-color: #0070dd;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(64, 161, 255, 0.5);
}
.btn-warning {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-warning:hover {
  color: #fff;
  background-color: #f3a735;
  border-color: #f2a229;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(247, 194, 114, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f2a229;
  border-color: #f19d1d;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(247, 194, 114, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-danger:hover {
  color: #fff;
  background-color: #f92d2d;
  border-color: #f92020;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(251, 108, 108, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f92020;
  border-color: #f81414;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(251, 108, 108, 0.5);
}
.btn-light {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-light:hover {
  color: #506690;
  background-color: #c4cbe0;
  border-color: #bcc4db;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(200, 207, 223, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #bcc4db;
  border-color: #b3bcd7;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(200, 207, 223, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-dark:hover {
  color: #fff;
  background-color: #1d2436;
  border-color: #181f2e;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(74, 83, 105, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #181f2e;
  border-color: #141925;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(74, 83, 105, 0.5);
}
.btn-tertiary {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-tertiary:hover {
  color: #fff;
  background-color: #c0591d;
  border-color: #b5541c;
}
.btn-tertiary.focus,
.btn-tertiary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(227, 128, 71, 0.5);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-tertiary:not(:disabled):not(.disabled).active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.show > .btn-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #b5541c;
  border-color: #aa4f1a;
}
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(227, 128, 71, 0.5);
}
.btn-orange {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-orange:hover {
  color: #fff;
  background-color: #d96c00;
  border-color: #c60;
}
.btn-orange.focus,
.btn-orange:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(255, 146, 38, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c60;
  border-color: #bf5f00;
}
.btn-orange:not(:disabled):not(.disabled).active:focus,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 146, 38, 0.5);
}
.btn-white {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-white:hover {
  color: #506690;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white.focus,
.btn-white:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(229, 232, 238, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  color: #506690;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(229, 232, 238, 0.5);
}
.btn-gray {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray:hover {
  color: #fff;
  background-color: #566788;
  border-color: #516180;
}
.btn-gray.focus,
.btn-gray:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(125, 141, 173, 0.5);
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #516180;
  border-color: #4c5b78;
}
.btn-gray:not(:disabled):not(.disabled).active:focus,
.btn-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(125, 141, 173, 0.5);
}
.btn-neutral {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-neutral:hover {
  color: #506690;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-neutral.focus,
.btn-neutral:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(229, 232, 238, 0.5);
}
.btn-neutral.disabled,
.btn-neutral:disabled {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active,
.show > .btn-neutral.dropdown-toggle {
  color: #506690;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(229, 232, 238, 0.5);
}
.btn-soft {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-soft:hover {
  color: #506690;
  background-color: #d8e5ef;
  border-color: #cfdfeb;
}
.btn-soft.focus,
.btn-soft:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(219, 225, 234, 0.5);
}
.btn-soft.disabled,
.btn-soft:disabled {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-soft:not(:disabled):not(.disabled).active,
.btn-soft:not(:disabled):not(.disabled):active,
.show > .btn-soft.dropdown-toggle {
  color: #506690;
  background-color: #cfdfeb;
  border-color: #c6d9e7;
}
.btn-soft:not(:disabled):not(.disabled).active:focus,
.btn-soft:not(:disabled):not(.disabled):active:focus,
.show > .btn-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(219, 225, 234, 0.5);
}
.btn-black {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-black:hover {
  color: #fff;
  background-color: #2e333f;
  border-color: #282d38;
}
.btn-black.focus,
.btn-black:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(91, 97, 111, 0.5);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #282d38;
  border-color: #232730;
}
.btn-black:not(:disabled):not(.disabled).active:focus,
.btn-black:not(:disabled):not(.disabled):active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(91, 97, 111, 0.5);
}
.btn-purple {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-purple:hover {
  color: #fff;
  background-color: #7145da;
  border-color: #683bd7;
}
.btn-purple.focus,
.btn-purple:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(155, 124, 229, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #683bd7;
  border-color: #6030d5;
}
.btn-purple:not(:disabled):not(.disabled).active:focus,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(155, 124, 229, 0.5);
}
.btn-gray-100 {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-100:hover {
  color: #506690;
  background-color: #d8e5ef;
  border-color: #cfdfeb;
}
.btn-gray-100.focus,
.btn-gray-100:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(219, 225, 234, 0.5);
}
.btn-gray-100.disabled,
.btn-gray-100:disabled {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-gray-100:not(:disabled):not(.disabled).active,
.btn-gray-100:not(:disabled):not(.disabled):active,
.show > .btn-gray-100.dropdown-toggle {
  color: #506690;
  background-color: #cfdfeb;
  border-color: #c6d9e7;
}
.btn-gray-100:not(:disabled):not(.disabled).active:focus,
.btn-gray-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(219, 225, 234, 0.5);
}
.btn-gray-200 {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-200:hover {
  color: #506690;
  background-color: #d3d9df;
  border-color: #cbd3da;
}
.btn-gray-200.focus,
.btn-gray-200:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(210, 216, 225, 0.5);
}
.btn-gray-200.disabled,
.btn-gray-200:disabled {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-gray-200:not(:disabled):not(.disabled).active,
.btn-gray-200:not(:disabled):not(.disabled):active,
.show > .btn-gray-200.dropdown-toggle {
  color: #506690;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}
.btn-gray-200:not(:disabled):not(.disabled).active:focus,
.btn-gray-200:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(210, 216, 225, 0.5);
}
.btn-gray-300 {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-300:hover {
  color: #506690;
  background-color: #c4cbe0;
  border-color: #bcc4db;
}
.btn-gray-300.focus,
.btn-gray-300:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(200, 207, 223, 0.5);
}
.btn-gray-300.disabled,
.btn-gray-300:disabled {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-gray-300:not(:disabled):not(.disabled).active,
.btn-gray-300:not(:disabled):not(.disabled):active,
.show > .btn-gray-300.dropdown-toggle {
  color: #fff;
  background-color: #bcc4db;
  border-color: #b3bcd7;
}
.btn-gray-300:not(:disabled):not(.disabled).active:focus,
.btn-gray-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(200, 207, 223, 0.5);
}
.btn-gray-400 {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-400:hover {
  color: #506690;
  background-color: #c6d1e4;
  border-color: #becadf;
}
.btn-gray-400.focus,
.btn-gray-400:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(202, 211, 226, 0.5);
}
.btn-gray-400.disabled,
.btn-gray-400:disabled {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-gray-400:not(:disabled):not(.disabled).active,
.btn-gray-400:not(:disabled):not(.disabled):active,
.show > .btn-gray-400.dropdown-toggle {
  color: #506690;
  background-color: #becadf;
  border-color: #b5c3db;
}
.btn-gray-400:not(:disabled):not(.disabled).active:focus,
.btn-gray-400:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(202, 211, 226, 0.5);
}
.btn-gray-500 {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-500:hover {
  color: #fff;
  background-color: #98a2ac;
  border-color: #919ca6;
}
.btn-gray-500.focus,
.btn-gray-500:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(185, 192, 199, 0.5);
}
.btn-gray-500.disabled,
.btn-gray-500:disabled {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-gray-500:not(:disabled):not(.disabled).active,
.btn-gray-500:not(:disabled):not(.disabled):active,
.show > .btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #919ca6;
  border-color: #8a95a1;
}
.btn-gray-500:not(:disabled):not(.disabled).active:focus,
.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(185, 192, 199, 0.5);
}
.btn-gray-600 {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-600:hover {
  color: #fff;
  background-color: #6e86aa;
  border-color: #667fa5;
}
.btn-gray-600.focus,
.btn-gray-600:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(152, 169, 195, 0.5);
}
.btn-gray-600.disabled,
.btn-gray-600:disabled {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-gray-600:not(:disabled):not(.disabled).active,
.btn-gray-600:not(:disabled):not(.disabled):active,
.show > .btn-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #667fa5;
  border-color: #5e79a0;
}
.btn-gray-600:not(:disabled):not(.disabled).active:focus,
.btn-gray-600:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(152, 169, 195, 0.5);
}
.btn-gray-700 {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-700:hover {
  color: #fff;
  background-color: #566788;
  border-color: #516180;
}
.btn-gray-700.focus,
.btn-gray-700:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(125, 141, 173, 0.5);
}
.btn-gray-700.disabled,
.btn-gray-700:disabled {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray-700:not(:disabled):not(.disabled).active,
.btn-gray-700:not(:disabled):not(.disabled):active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #516180;
  border-color: #4c5b78;
}
.btn-gray-700:not(:disabled):not(.disabled).active:focus,
.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(125, 141, 173, 0.5);
}
.btn-gray-800 {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #425577;
  border-color: #3e4f6f;
}
.btn-gray-800.focus,
.btn-gray-800:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(106, 125, 161, 0.5);
}
.btn-gray-800.disabled,
.btn-gray-800:disabled {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-gray-800:not(:disabled):not(.disabled).active,
.btn-gray-800:not(:disabled):not(.disabled):active,
.show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #3e4f6f;
  border-color: #394967;
}
.btn-gray-800:not(:disabled):not(.disabled).active:focus,
.btn-gray-800:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(106, 125, 161, 0.5);
}
.btn-outline-primary {
  color: #11ab7c;
  border-color: #11ab7c;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.0625rem rgba(17, 171, 124, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #11ab7c;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(17, 171, 124, 0.5);
}
.btn-outline-secondary {
  color: #005ad4;
  border-color: #005ad4;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.0625rem rgba(0, 90, 212, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #005ad4;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(0, 90, 212, 0.5);
}
.btn-outline-success {
  color: #00bf9a;
  border-color: #00bf9a;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.0625rem rgba(0, 191, 154, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00bf9a;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(0, 191, 154, 0.5);
}
.btn-outline-info {
  color: #1e90ff;
  border-color: #1e90ff;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.0625rem rgba(30, 144, 255, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #1e90ff;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(30, 144, 255, 0.5);
}
.btn-outline-warning {
  color: #f5b759;
  border-color: #f5b759;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.0625rem rgba(245, 183, 89, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f5b759;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(245, 183, 89, 0.5);
}
.btn-outline-danger {
  color: #fa5252;
  border-color: #fa5252;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.0625rem rgba(250, 82, 82, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fa5252;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(250, 82, 82, 0.5);
}
.btn-outline-light {
  color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-light:hover {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #dde1ed;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-outline-dark {
  color: #2a354f;
  border-color: #2a354f;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.0625rem rgba(42, 53, 79, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #2a354f;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(42, 53, 79, 0.5);
}
.btn-outline-tertiary {
  color: #de6926;
  border-color: #de6926;
}
.btn-outline-tertiary:hover {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-outline-tertiary.focus,
.btn-outline-tertiary:focus {
  box-shadow: 0 0 0 0.0625rem rgba(222, 105, 38, 0.5);
}
.btn-outline-tertiary.disabled,
.btn-outline-tertiary:disabled {
  color: #de6926;
  background-color: transparent;
}
.btn-outline-tertiary:not(:disabled):not(.disabled).active,
.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.show > .btn-outline-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(222, 105, 38, 0.5);
}
.btn-outline-orange {
  color: #ff7f00;
  border-color: #ff7f00;
}
.btn-outline-orange:hover {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-outline-orange.focus,
.btn-outline-orange:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 127, 0, 0.5);
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #ff7f00;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled).active,
.btn-outline-orange:not(:disabled):not(.disabled):active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 127, 0, 0.5);
}
.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white.focus,
.btn-outline-white:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled).active,
.btn-outline-white:not(:disabled):not(.disabled):active,
.show > .btn-outline-white.dropdown-toggle {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-outline-gray {
  color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray:hover {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray.focus,
.btn-outline-gray:focus {
  box-shadow: 0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #66799e;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled).active,
.btn-outline-gray:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-outline-neutral {
  color: #fff;
  border-color: #fff;
}
.btn-outline-neutral:hover {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-neutral.focus,
.btn-outline-neutral:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-outline-neutral.disabled,
.btn-outline-neutral:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-neutral:not(:disabled):not(.disabled).active,
.btn-outline-neutral:not(:disabled):not(.disabled):active,
.show > .btn-outline-neutral.dropdown-toggle {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
.btn-outline-neutral:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-outline-soft {
  color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-soft:hover {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-soft.focus,
.btn-outline-soft:focus {
  box-shadow: 0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-outline-soft.disabled,
.btn-outline-soft:disabled {
  color: #f3f7fa;
  background-color: transparent;
}
.btn-outline-soft:not(:disabled):not(.disabled).active,
.btn-outline-soft:not(:disabled):not(.disabled):active,
.show > .btn-outline-soft.dropdown-toggle {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-soft:not(:disabled):not(.disabled).active:focus,
.btn-outline-soft:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-outline-black {
  color: #3e4555;
  border-color: #3e4555;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-outline-black.focus,
.btn-outline-black:focus {
  box-shadow: 0 0 0 0.0625rem rgba(62, 69, 85, 0.5);
}
.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #3e4555;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled).active,
.btn-outline-black:not(:disabled):not(.disabled):active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(62, 69, 85, 0.5);
}
.btn-outline-purple {
  color: #8965e0;
  border-color: #8965e0;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-outline-purple.focus,
.btn-outline-purple:focus {
  box-shadow: 0 0 0 0.0625rem rgba(137, 101, 224, 0.5);
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #8965e0;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(137, 101, 224, 0.5);
}
.btn-outline-gray-100 {
  color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-gray-100:hover {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-gray-100.focus,
.btn-outline-gray-100:focus {
  box-shadow: 0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-outline-gray-100.disabled,
.btn-outline-gray-100:disabled {
  color: #f3f7fa;
  background-color: transparent;
}
.btn-outline-gray-100:not(:disabled):not(.disabled).active,
.btn-outline-gray-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-100.dropdown-toggle {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-outline-gray-200 {
  color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-gray-200:hover {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-gray-200.focus,
.btn-outline-gray-200:focus {
  box-shadow: 0 0 0 0.0625rem rgba(233, 236, 239, 0.5);
}
.btn-outline-gray-200.disabled,
.btn-outline-gray-200:disabled {
  color: #e9ecef;
  background-color: transparent;
}
.btn-outline-gray-200:not(:disabled):not(.disabled).active,
.btn-outline-gray-200:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-200.dropdown-toggle {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(233, 236, 239, 0.5);
}
.btn-outline-gray-300 {
  color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-gray-300:hover {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-gray-300.focus,
.btn-outline-gray-300:focus {
  box-shadow: 0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-outline-gray-300.disabled,
.btn-outline-gray-300:disabled {
  color: #dde1ed;
  background-color: transparent;
}
.btn-outline-gray-300:not(:disabled):not(.disabled).active,
.btn-outline-gray-300:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-300.dropdown-toggle {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-outline-gray-400 {
  color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-outline-gray-400:hover {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-outline-gray-400.focus,
.btn-outline-gray-400:focus {
  box-shadow: 0 0 0 0.0625rem rgba(224, 230, 240, 0.5);
}
.btn-outline-gray-400.disabled,
.btn-outline-gray-400:disabled {
  color: #e0e6f0;
  background-color: transparent;
}
.btn-outline-gray-400:not(:disabled):not(.disabled).active,
.btn-outline-gray-400:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-400.dropdown-toggle {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(224, 230, 240, 0.5);
}
.btn-outline-gray-500 {
  color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-gray-500:hover {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-gray-500.focus,
.btn-outline-gray-500:focus {
  box-shadow: 0 0 0 0.0625rem rgba(173, 181, 189, 0.5);
}
.btn-outline-gray-500.disabled,
.btn-outline-gray-500:disabled {
  color: #adb5bd;
  background-color: transparent;
}
.btn-outline-gray-500:not(:disabled):not(.disabled).active,
.btn-outline-gray-500:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(173, 181, 189, 0.5);
}
.btn-outline-gray-600 {
  color: #869ab8;
  border-color: #869ab8;
}
.btn-outline-gray-600:hover {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-outline-gray-600.focus,
.btn-outline-gray-600:focus {
  box-shadow: 0 0 0 0.0625rem rgba(134, 154, 184, 0.5);
}
.btn-outline-gray-600.disabled,
.btn-outline-gray-600:disabled {
  color: #869ab8;
  background-color: transparent;
}
.btn-outline-gray-600:not(:disabled):not(.disabled).active,
.btn-outline-gray-600:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-600:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(134, 154, 184, 0.5);
}
.btn-outline-gray-700 {
  color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray-700:hover {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray-700.focus,
.btn-outline-gray-700:focus {
  box-shadow: 0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-outline-gray-700.disabled,
.btn-outline-gray-700:disabled {
  color: #66799e;
  background-color: transparent;
}
.btn-outline-gray-700:not(:disabled):not(.disabled).active,
.btn-outline-gray-700:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-outline-gray-800 {
  color: #506690;
  border-color: #506690;
}
.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-outline-gray-800.focus,
.btn-outline-gray-800:focus {
  box-shadow: 0 0 0 0.0625rem rgba(80, 102, 144, 0.5);
}
.btn-outline-gray-800.disabled,
.btn-outline-gray-800:disabled {
  color: #506690;
  background-color: transparent;
}
.btn-outline-gray-800:not(:disabled):not(.disabled).active,
.btn-outline-gray-800:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-800:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.0625rem rgba(80, 102, 144, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #11ab7c;
  text-decoration: none;
}
.btn-link:hover {
  color: #0c7d5a;
  text-decoration: none;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #869ab8;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.875rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.7rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #506690;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(17, 17, 17, 0.15);
  border-radius: 0.2rem;
  box-shadow: 0 1rem 3rem rgba(42, 53, 79, 0.125);
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #3e4555;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #333946;
  text-decoration: none;
  background-color: #f3f7fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e0e6f0;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #869ab8;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.7rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #3e4555;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #3e4555;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -0.0625rem;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.7125rem;
  padding-left: 0.7125rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -0.0625rem;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -0.0625rem;
}
.input-group-prepend {
  margin-right: -0.0625rem;
}
.input-group-append {
  margin-left: -0.0625rem;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 0.1rem solid #e3ecf2;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 30px !important;
  border-radius: 0.5rem;
}
.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1.75rem + 0.0625rem);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 0.0625rem);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 3rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #11ab7c;
  background-color: #11ab7c;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05), none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dde1ed;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #7cf2ce;
  border-color: #11ab7c;
  box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #869ab8;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -3rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}
.custom-control-label::after {
  position: absolute;
  top: 0.125rem;
  left: -3rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: '';
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: 'Font Awesome 5 Free';
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #11ab7c;
  background-color: #11ab7c;
  box-shadow: none;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(17, 171, 124, 0.7);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(17, 171, 124, 0.7);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(17, 171, 124, 0.7);
}
.custom-switch {
  padding-left: 2.9375rem;
}
.custom-switch .custom-control-label::before {
  left: -2.9375rem;
  width: 2.1875rem;
  pointer-events: all;
  border-radius: 0.625rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.125rem + 2px);
  left: calc(-2.9375rem + 2px);
  width: calc(1.25rem - 4px);
  height: calc(1.25rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.625rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.9375rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(17, 171, 124, 0.7);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.95rem + 1.2rem + 0.0625rem);
  padding: 0.6rem 1.75rem 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23506690' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 0.1rem solid #e3ecf2;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 30px !important;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(17, 17, 17, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #dde1ed;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(17, 17, 17, 0.075), 0 0 0 0 #11ab7c;
}
.custom-select:focus::-ms-value {
  color: #869ab8;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #869ab8;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 0.0625rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1.75rem + 0.0625rem);
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 1rem;
  font-size: 1rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.95rem + 1.2rem + 0.0625rem);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.95rem + 1.2rem + 0.0625rem);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #dde1ed;
  box-shadow: none;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.95rem + 1.2rem + 0.0625rem);
  padding: 0.6rem 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  color: #869ab8;
  background-color: #fff;
  border: 0.0625rem solid #f3f7fa;
  border-radius: 0.5rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.2rem);
  padding: 0.6rem 0.75rem;
  line-height: 1.5;
  color: #869ab8;
  content: 'Browse';
  background-color: #fff;
  border-left: inherit;
  border-radius: 0 0.5rem 0.5rem 0;
}
.custom-range {
  width: 100%;
  height: calc(1rem + 0);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #11ab7c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(17, 17, 17, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #7cf2ce;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dde1ed;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(17, 17, 17, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #11ab7c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(17, 17, 17, 0.1);
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #7cf2ce;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dde1ed;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(17, 17, 17, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #11ab7c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(17, 17, 17, 0.1);
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #7cf2ce;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(17, 17, 17, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dde1ed;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dde1ed;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #869ab8;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 0.0625rem solid #e9ecef;
}
.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}
.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #e9ecef;
}
.nav-tabs .nav-link.disabled {
  color: #869ab8;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #11ab7c;
  background-color: #f3f7fa;
  border-color: #dde1ed #dde1ed #f3f7fa;
}
.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.5rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #11ab7c;
  background-color: #fff;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(80, 102, 144, 0.8);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(80, 102, 144, 0.8);
}
.navbar-light .navbar-nav .nav-link {
  color: #66799e;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #506690;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(80, 102, 144, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(80, 102, 144, 0.8);
}
.navbar-light .navbar-toggler {
  color: #66799e;
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2366799e' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: #66799e;
}
.navbar-light .navbar-text a {
  color: rgba(80, 102, 144, 0.8);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(80, 102, 144, 0.8);
}
.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-dark .navbar-text a {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: rgba(255, 255, 255, 0.65);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(243, 247, 250, 0.05);
  border-radius: 0.5rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}
.card-title {
  margin-bottom: 1.25rem;
}
.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}
.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #f3f7fa;
  border-bottom: 0.0625rem solid rgba(243, 247, 250, 0.05);
}
.card-header:first-child {
  border-radius: calc(0.5rem - 0.0625rem) calc(0.5rem - 0.0625rem) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #f3f7fa;
  border-top: 0.0625rem solid rgba(243, 247, 250, 0.05);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 0.0625rem) calc(0.5rem - 0.0625rem);
}
.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.25rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.5rem - 0.0625rem);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.5rem - 0.0625rem);
  border-top-right-radius: calc(0.5rem - 0.0625rem);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.5rem - 0.0625rem);
  border-bottom-left-radius: calc(0.5rem - 0.0625rem);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 1.25rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -0.0625rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #66799e;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #fff;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.5rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #869ab8;
  background-color: #fff;
  border: 0.0625rem solid #e9ecef;
}
.page-link:hover {
  z-index: 2;
  color: #869ab8;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.page-item.disabled .page-link {
  color: #869ab8;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dde1ed;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.badge {
  display: inline-block;
  padding: 0.1rem 0.4rem;
  font-size: 66%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #11ab7c;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0c7d5a;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(17, 171, 124, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #005ad4;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #0044a1;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 90, 212, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #00bf9a;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #008c71;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 191, 154, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #1e90ff;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #0077ea;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 144, 255, 0.5);
}
.badge-warning {
  color: #fff;
  background-color: #f5b759;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #f2a229;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(245, 183, 89, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #fa5252;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #f92020;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(250, 82, 82, 0.5);
}
.badge-light {
  color: #506690;
  background-color: #dde1ed;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #506690;
  background-color: #bcc4db;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(221, 225, 237, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #2a354f;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #181f2e;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(42, 53, 79, 0.5);
}
.badge-tertiary {
  color: #fff;
  background-color: #de6926;
}
a.badge-tertiary:focus,
a.badge-tertiary:hover {
  color: #fff;
  background-color: #b5541c;
}
a.badge-tertiary.focus,
a.badge-tertiary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(222, 105, 38, 0.5);
}
.badge-orange {
  color: #fff;
  background-color: #ff7f00;
}
a.badge-orange:focus,
a.badge-orange:hover {
  color: #fff;
  background-color: #c60;
}
a.badge-orange.focus,
a.badge-orange:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 127, 0, 0.5);
}
.badge-white {
  color: #506690;
  background-color: #fff;
}
a.badge-white:focus,
a.badge-white:hover {
  color: #506690;
  background-color: #e6e6e6;
}
a.badge-white.focus,
a.badge-white:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.badge-gray {
  color: #fff;
  background-color: #66799e;
}
a.badge-gray:focus,
a.badge-gray:hover {
  color: #fff;
  background-color: #516180;
}
a.badge-gray.focus,
a.badge-gray:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(102, 121, 158, 0.5);
}
.badge-neutral {
  color: #506690;
  background-color: #fff;
}
a.badge-neutral:focus,
a.badge-neutral:hover {
  color: #506690;
  background-color: #e6e6e6;
}
a.badge-neutral.focus,
a.badge-neutral:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}
.badge-soft {
  color: #506690;
  background-color: #f3f7fa;
}
a.badge-soft:focus,
a.badge-soft:hover {
  color: #506690;
  background-color: #cfdfeb;
}
a.badge-soft.focus,
a.badge-soft:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(243, 247, 250, 0.5);
}
.badge-black {
  color: #fff;
  background-color: #3e4555;
}
a.badge-black:focus,
a.badge-black:hover {
  color: #fff;
  background-color: #282d38;
}
a.badge-black.focus,
a.badge-black:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(62, 69, 85, 0.5);
}
.badge-purple {
  color: #fff;
  background-color: #8965e0;
}
a.badge-purple:focus,
a.badge-purple:hover {
  color: #fff;
  background-color: #683bd7;
}
a.badge-purple.focus,
a.badge-purple:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(137, 101, 224, 0.5);
}
.badge-gray-100 {
  color: #506690;
  background-color: #f3f7fa;
}
a.badge-gray-100:focus,
a.badge-gray-100:hover {
  color: #506690;
  background-color: #cfdfeb;
}
a.badge-gray-100.focus,
a.badge-gray-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(243, 247, 250, 0.5);
}
.badge-gray-200 {
  color: #506690;
  background-color: #e9ecef;
}
a.badge-gray-200:focus,
a.badge-gray-200:hover {
  color: #506690;
  background-color: #cbd3da;
}
a.badge-gray-200.focus,
a.badge-gray-200:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(233, 236, 239, 0.5);
}
.badge-gray-300 {
  color: #506690;
  background-color: #dde1ed;
}
a.badge-gray-300:focus,
a.badge-gray-300:hover {
  color: #506690;
  background-color: #bcc4db;
}
a.badge-gray-300.focus,
a.badge-gray-300:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(221, 225, 237, 0.5);
}
.badge-gray-400 {
  color: #506690;
  background-color: #e0e6f0;
}
a.badge-gray-400:focus,
a.badge-gray-400:hover {
  color: #506690;
  background-color: #becadf;
}
a.badge-gray-400.focus,
a.badge-gray-400:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(224, 230, 240, 0.5);
}
.badge-gray-500 {
  color: #fff;
  background-color: #adb5bd;
}
a.badge-gray-500:focus,
a.badge-gray-500:hover {
  color: #fff;
  background-color: #919ca6;
}
a.badge-gray-500.focus,
a.badge-gray-500:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5);
}
.badge-gray-600 {
  color: #fff;
  background-color: #869ab8;
}
a.badge-gray-600:focus,
a.badge-gray-600:hover {
  color: #fff;
  background-color: #667fa5;
}
a.badge-gray-600.focus,
a.badge-gray-600:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(134, 154, 184, 0.5);
}
.badge-gray-700 {
  color: #fff;
  background-color: #66799e;
}
a.badge-gray-700:focus,
a.badge-gray-700:hover {
  color: #fff;
  background-color: #516180;
}
a.badge-gray-700.focus,
a.badge-gray-700:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(102, 121, 158, 0.5);
}
.badge-gray-800 {
  color: #fff;
  background-color: #506690;
}
a.badge-gray-800:focus,
a.badge-gray-800:hover {
  color: #fff;
  background-color: #3e4f6f;
}
a.badge-gray-800.focus,
a.badge-gray-800:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 600;
}
.alert-dismissible {
  padding-right: 4.5rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  color: inherit;
}
.alert-primary {
  color: #11926b;
  background-color: #24b286;
  border-color: #119f73;
}
.alert-primary hr {
  border-top-color: #0f8862;
}
.alert-primary .alert-link {
  color: #0c644a;
}
.alert-secondary {
  color: #034eb5;
  background-color: #1467d7;
  border-color: #0154c4;
}
.alert-secondary hr {
  border-top-color: #0149ab;
}
.alert-secondary .alert-link {
  color: #023883;
}
.alert-success {
  color: #03a384;
  background-color: #14c4a2;
  border-color: #01b18f;
}
.alert-success hr {
  border-top-color: #01987b;
}
.alert-success .alert-link {
  color: #02715b;
}
.alert-info {
  color: #1c7cd9;
  background-color: #3099ff;
  border-color: #1d86ec;
}
.alert-info hr {
  border-top-color: #1379dd;
}
.alert-info .alert-link {
  color: #1662ac;
}
.alert-warning {
  color: #d19c4d;
  background-color: #f6bd66;
  border-color: #e3aa53;
}
.alert-warning hr {
  border-top-color: #df9f3d;
}
.alert-warning .alert-link {
  color: #bb8330;
}
.alert-danger {
  color: #d54848;
  background-color: #fa6060;
  border-color: #e74d4d;
}
.alert-danger hr {
  border-top-color: #e43737;
}
.alert-danger .alert-link {
  color: #be2c2c;
}
.alert-light {
  color: #bcc0ca;
  background-color: #e0e3ee;
  border-color: #cdd0db;
}
.alert-light hr {
  border-top-color: #bec2d0;
}
.alert-light .alert-link {
  color: #a0a5b3;
}
.alert-dark {
  color: #262f45;
  background-color: #3b455d;
  border-color: #28324a;
}
.alert-dark hr {
  border-top-color: #1f2739;
}
.alert-dark .alert-link {
  color: #141924;
}
.alert-tertiary {
  color: #bd5b23;
  background-color: #e17537;
  border-color: #ce6224;
}
.alert-tertiary hr {
  border-top-color: #b85820;
}
.alert-tertiary .alert-link {
  color: #92461b;
}
.alert-orange {
  color: #d96d03;
  background-color: #ff8914;
  border-color: #ec7601;
}
.alert-orange hr {
  border-top-color: #d36901;
}
.alert-orange .alert-link {
  color: #a75402;
}
.alert-white {
  color: #d9d9d9;
  background-color: #fff;
  border-color: #ececec;
}
.alert-white hr {
  border-top-color: #dfdfdf;
}
.alert-white .alert-link {
  color: silver;
}
.alert-gray {
  color: #586887;
  background-color: #7284a6;
  border-color: #5f7193;
}
.alert-gray hr {
  border-top-color: #556584;
}
.alert-gray .alert-link {
  color: #445068;
}
.alert-neutral {
  color: #d9d9d9;
  background-color: #fff;
  border-color: #ececec;
}
.alert-neutral hr {
  border-top-color: #dfdfdf;
}
.alert-neutral .alert-link {
  color: silver;
}
.alert-soft {
  color: #cfd2d5;
  background-color: #f4f8fa;
  border-color: #e1e5e7;
}
.alert-soft hr {
  border-top-color: #d3d9dc;
}
.alert-soft .alert-link {
  color: #b4b9bd;
}
.alert-black {
  color: #373d4a;
  background-color: #4d5463;
  border-color: #3a4150;
}
.alert-black hr {
  border-top-color: #2f3541;
}
.alert-black .alert-link {
  color: #21252d;
}
.alert-purple {
  color: #7658bf;
  background-color: #9271e2;
  border-color: #7f5ecf;
}
.alert-purple hr {
  border-top-color: #6f4ac9;
}
.alert-purple .alert-link {
  color: #5d3fa5;
}
.alert-gray-100 {
  color: #cfd2d5;
  background-color: #f4f8fa;
  border-color: #e1e5e7;
}
.alert-gray-100 hr {
  border-top-color: #d3d9dc;
}
.alert-gray-100 .alert-link {
  color: #b4b9bd;
}
.alert-gray-200 {
  color: #c6c9cb;
  background-color: #ebeef0;
  border-color: #d8dadd;
}
.alert-gray-200 hr {
  border-top-color: #cacdd1;
}
.alert-gray-200 .alert-link {
  color: #abb0b3;
}
.alert-gray-300 {
  color: #bcc0ca;
  background-color: #e0e3ee;
  border-color: #cdd0db;
}
.alert-gray-300 hr {
  border-top-color: #bec2d0;
}
.alert-gray-300 .alert-link {
  color: #a0a5b3;
}
.alert-gray-400 {
  color: #bfc4cc;
  background-color: #e2e8f1;
  border-color: #cfd5de;
}
.alert-gray-400 hr {
  border-top-color: #c0c8d4;
}
.alert-gray-400 .alert-link {
  color: #a3aab5;
}
.alert-gray-500 {
  color: #949ba1;
  background-color: #b4bbc2;
  border-color: #a1a8af;
}
.alert-gray-500 hr {
  border-top-color: #939ba3;
}
.alert-gray-500 .alert-link {
  color: #798289;
}
.alert-gray-600 {
  color: #73849d;
  background-color: #90a2be;
  border-color: #7d8fab;
}
.alert-gray-600 hr {
  border-top-color: #6e82a1;
}
.alert-gray-600 .alert-link {
  color: #5b6b82;
}
.alert-gray-700 {
  color: #586887;
  background-color: #7284a6;
  border-color: #5f7193;
}
.alert-gray-700 hr {
  border-top-color: #556584;
}
.alert-gray-700 .alert-link {
  color: #445068;
}
.alert-gray-800 {
  color: #46587c;
  background-color: #5e7299;
  border-color: #4b5f86;
}
.alert-gray-800 hr {
  border-top-color: #425376;
}
.alert-gray-800 .alert-link {
  color: #34415b;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(17, 17, 17, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #11ab7c;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #66799e;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #66799e;
  text-decoration: none;
  background-color: #f3f7fa;
}
.list-group-item-action:active {
  color: #66799e;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;
  background-color: #fff;
  border: 0.0625rem solid #e9ecef;
}
.list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #869ab8;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -0.0625rem;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -0.0625rem;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -0.0625rem;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -0.0625rem;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -0.0625rem;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -0.0625rem;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-item-primary {
  color: #116149;
  background-color: #bce7da;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #116149;
  background-color: #a9e0d0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #116149;
  border-color: #116149;
}
.list-group-item-secondary {
  color: #083776;
  background-color: #b8d1f3;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #083776;
  background-color: #a2c3ef;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #083776;
  border-color: #083776;
}
.list-group-item-success {
  color: #086b58;
  background-color: #b8ede3;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #086b58;
  background-color: #a4e8db;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #086b58;
  border-color: #086b58;
}
.list-group-item-info {
  color: #18538d;
  background-color: #c0e0ff;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #18538d;
  background-color: #a7d3ff;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #18538d;
  border-color: #18538d;
}
.list-group-item-warning {
  color: #886736;
  background-color: #fcebd1;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #886736;
  background-color: #fae1b9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #886736;
  border-color: #886736;
}
.list-group-item-danger {
  color: #8a3333;
  background-color: #fecfcf;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #8a3333;
  background-color: #fdb6b6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8a3333;
  border-color: #8a3333;
}
.list-group-item-light {
  color: #7b7d83;
  background-color: #f5f7fa;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #7b7d83;
  background-color: #e4e9f2;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7b7d83;
  border-color: #7b7d83;
}
.list-group-item-dark {
  color: #1e2431;
  background-color: #c3c6ce;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1e2431;
  background-color: #b5b9c3;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1e2431;
  border-color: #1e2431;
}
.list-group-item-tertiary {
  color: #7c3f1c;
  background-color: #f6d5c2;
}
.list-group-item-tertiary.list-group-item-action:focus,
.list-group-item-tertiary.list-group-item-action:hover {
  color: #7c3f1c;
  background-color: #f3c6ac;
}
.list-group-item-tertiary.list-group-item-action.active {
  color: #fff;
  background-color: #7c3f1c;
  border-color: #7c3f1c;
}
.list-group-item-orange {
  color: #8d4a08;
  background-color: #ffdbb8;
}
.list-group-item-orange.list-group-item-action:focus,
.list-group-item-orange.list-group-item-action:hover {
  color: #8d4a08;
  background-color: #ffce9f;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #8d4a08;
  border-color: #8d4a08;
}
.list-group-item-white {
  color: #8d8d8d;
  background-color: #fff;
}
.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
  color: #8d8d8d;
  background-color: #f2f2f2;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #8d8d8d;
  border-color: #8d8d8d;
}
.list-group-item-gray {
  color: #3d475a;
  background-color: #d4d9e4;
}
.list-group-item-gray.list-group-item-action:focus,
.list-group-item-gray.list-group-item-action:hover {
  color: #3d475a;
  background-color: #c4cbda;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #3d475a;
  border-color: #3d475a;
}
.list-group-item-neutral {
  color: #8d8d8d;
  background-color: #fff;
}
.list-group-item-neutral.list-group-item-action:focus,
.list-group-item-neutral.list-group-item-action:hover {
  color: #8d8d8d;
  background-color: #f2f2f2;
}
.list-group-item-neutral.list-group-item-action.active {
  color: #fff;
  background-color: #8d8d8d;
  border-color: #8d8d8d;
}
.list-group-item-soft {
  color: #87898a;
  background-color: #fcfdfe;
}
.list-group-item-soft.list-group-item-action:focus,
.list-group-item-soft.list-group-item-action:hover {
  color: #87898a;
  background-color: #e9f0f8;
}
.list-group-item-soft.list-group-item-action.active {
  color: #fff;
  background-color: #87898a;
  border-color: #87898a;
}
.list-group-item-black {
  color: #282c34;
  background-color: #c9cbcf;
}
.list-group-item-black.list-group-item-action:focus,
.list-group-item-black.list-group-item-action:hover {
  color: #282c34;
  background-color: #bcbec3;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #282c34;
  border-color: #282c34;
}
.list-group-item-purple {
  color: #4f3d7d;
  background-color: #ded4f6;
}
.list-group-item-purple.list-group-item-action:focus,
.list-group-item-purple.list-group-item-action:hover {
  color: #4f3d7d;
  background-color: #cebff2;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #4f3d7d;
  border-color: #4f3d7d;
}
.list-group-item-gray-100 {
  color: #87898a;
  background-color: #fcfdfe;
}
.list-group-item-gray-100.list-group-item-action:focus,
.list-group-item-gray-100.list-group-item-action:hover {
  color: #87898a;
  background-color: #e9f0f8;
}
.list-group-item-gray-100.list-group-item-action.active {
  color: #fff;
  background-color: #87898a;
  border-color: #87898a;
}
.list-group-item-gray-200 {
  color: #818384;
  background-color: #f9fafb;
}
.list-group-item-gray-200.list-group-item-action:focus,
.list-group-item-gray-200.list-group-item-action:hover {
  color: #818384;
  background-color: #eaedf1;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #818384;
  border-color: #818384;
}
.list-group-item-gray-300 {
  color: #7b7d83;
  background-color: #f5f7fa;
}
.list-group-item-gray-300.list-group-item-action:focus,
.list-group-item-gray-300.list-group-item-action:hover {
  color: #7b7d83;
  background-color: #e4e9f2;
}
.list-group-item-gray-300.list-group-item-action.active {
  color: #fff;
  background-color: #7b7d83;
  border-color: #7b7d83;
}
.list-group-item-gray-400 {
  color: #7d8085;
  background-color: #f6f8fb;
}
.list-group-item-gray-400.list-group-item-action:focus,
.list-group-item-gray-400.list-group-item-action:hover {
  color: #7d8085;
  background-color: #e4eaf3;
}
.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #7d8085;
  border-color: #7d8085;
}
.list-group-item-gray-500 {
  color: #62666a;
  background-color: #e8eaed;
}
.list-group-item-gray-500.list-group-item-action:focus,
.list-group-item-gray-500.list-group-item-action:hover {
  color: #62666a;
  background-color: #dadde2;
}
.list-group-item-gray-500.list-group-item-action.active {
  color: #fff;
  background-color: #62666a;
  border-color: #62666a;
}
.list-group-item-gray-600 {
  color: #4e5868;
  background-color: #dde3eb;
}
.list-group-item-gray-600.list-group-item-action:focus,
.list-group-item-gray-600.list-group-item-action:hover {
  color: #4e5868;
  background-color: #cdd6e2;
}
.list-group-item-gray-600.list-group-item-action.active {
  color: #fff;
  background-color: #4e5868;
  border-color: #4e5868;
}
.list-group-item-gray-700 {
  color: #3d475a;
  background-color: #d4d9e4;
}
.list-group-item-gray-700.list-group-item-action:focus,
.list-group-item-gray-700.list-group-item-action:hover {
  color: #3d475a;
  background-color: #c4cbda;
}
.list-group-item-gray-700.list-group-item-action.active {
  color: #fff;
  background-color: #3d475a;
  border-color: #3d475a;
}
.list-group-item-gray-800 {
  color: #323d53;
  background-color: #ced4e0;
}
.list-group-item-gray-800.list-group-item-action:focus,
.list-group-item-gray-800.list-group-item-action:hover {
  color: #323d53;
  background-color: #bec6d6;
}
.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #323d53;
  border-color: #323d53;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(17, 17, 17, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #869ab8;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 17, 17, 0.2);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(17, 17, 17, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #111;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.16;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.modal-header .close {
  padding: 1.25rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.1;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.5);
  }
  .modal-sm {
    max-width: 380px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #11ab7c;
}
.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #11ab7c;
}
.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #11ab7c;
}
.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #11ab7c;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #11ab7c;
  border-radius: 0.5rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #11ab7c;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 2rem 0 rgba(17, 17, 17, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 0.75rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
  margin-bottom: 0.75rem;
}
.bs-popover-auto[x-placement^='top'] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc((0.75rem + 1px) * -1);
}
.bs-popover-auto[x-placement^='top'] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.75rem 0.75rem 0;
  border-top-color: transparent;
}
.bs-popover-auto[x-placement^='top'] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.75rem 0.75rem 0;
  border-top-color: #11ab7c;
}
.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  margin-left: 0.75rem;
}
.bs-popover-auto[x-placement^='right'] > .arrow,
.bs-popover-right > .arrow {
  left: calc((0.75rem + 1px) * -1);
  width: 0.75rem;
  height: 1.5rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='right'] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.75rem 0.75rem 0.75rem 0;
  border-right-color: transparent;
}
.bs-popover-auto[x-placement^='right'] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.75rem 0.75rem 0.75rem 0;
  border-right-color: #11ab7c;
}
.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0.75rem;
}
.bs-popover-auto[x-placement^='bottom'] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc((0.75rem + 1px) * -1);
}
.bs-popover-auto[x-placement^='bottom'] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.75rem 0.75rem 0.75rem;
  border-bottom-color: transparent;
}
.bs-popover-auto[x-placement^='bottom'] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.75rem 0.75rem 0.75rem;
  border-bottom-color: #11ab7c;
}
.bs-popover-auto[x-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1.5rem;
  margin-left: -0.75rem;
  content: '';
  border-bottom: 1px solid #11ab7c;
}
.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
  margin-right: 0.75rem;
}
.bs-popover-auto[x-placement^='left'] > .arrow,
.bs-popover-left > .arrow {
  right: calc((0.75rem + 1px) * -1);
  width: 0.75rem;
  height: 1.5rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='left'] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.75rem 0 0.75rem 0.75rem;
  border-left-color: transparent;
}
.bs-popover-auto[x-placement^='left'] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.75rem 0 0.75rem 0.75rem;
  border-left-color: #11ab7c;
}
.popover-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #fff;
  background-color: #11ab7c;
  border-bottom: 1px solid #0f946b;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.75rem 0.75rem;
  color: #fff;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #11ab7c !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0c7d5a !important;
}
.bg-secondary {
  background-color: #005ad4 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #0044a1 !important;
}
.bg-success {
  background-color: #00bf9a !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #008c71 !important;
}
.bg-info {
  background-color: #1e90ff !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #0077ea !important;
}
.bg-warning {
  background-color: #f5b759 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f2a229 !important;
}
.bg-danger {
  background-color: #fa5252 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #f92020 !important;
}
.bg-light {
  background-color: #dde1ed !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #bcc4db !important;
}
.bg-dark {
  background-color: #2a354f !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #181f2e !important;
}
.bg-tertiary {
  background-color: #de6926 !important;
}
a.bg-tertiary:focus,
a.bg-tertiary:hover,
button.bg-tertiary:focus,
button.bg-tertiary:hover {
  background-color: #b5541c !important;
}
.bg-orange {
  background-color: #ff7f00 !important;
}
a.bg-orange:focus,
a.bg-orange:hover,
button.bg-orange:focus,
button.bg-orange:hover {
  background-color: #c60 !important;
}
.bg-white {
  background-color: #fff !important;
}
a.bg-white:focus,
a.bg-white:hover,
button.bg-white:focus,
button.bg-white:hover {
  background-color: #e6e6e6 !important;
}
.bg-gray {
  background-color: #66799e !important;
}
a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #516180 !important;
}
.bg-neutral {
  background-color: #fff !important;
}
a.bg-neutral:focus,
a.bg-neutral:hover,
button.bg-neutral:focus,
button.bg-neutral:hover {
  background-color: #e6e6e6 !important;
}
.bg-soft {
  background-color: #f3f7fa !important;
}
a.bg-soft:focus,
a.bg-soft:hover,
button.bg-soft:focus,
button.bg-soft:hover {
  background-color: #cfdfeb !important;
}
.bg-black {
  background-color: #3e4555 !important;
}
a.bg-black:focus,
a.bg-black:hover,
button.bg-black:focus,
button.bg-black:hover {
  background-color: #282d38 !important;
}
.bg-purple {
  background-color: #8965e0 !important;
}
a.bg-purple:focus,
a.bg-purple:hover,
button.bg-purple:focus,
button.bg-purple:hover {
  background-color: #683bd7 !important;
}
.bg-gray-100 {
  background-color: #f3f7fa !important;
}
a.bg-gray-100:focus,
a.bg-gray-100:hover,
button.bg-gray-100:focus,
button.bg-gray-100:hover {
  background-color: #cfdfeb !important;
}
.bg-gray-200 {
  background-color: #e9ecef !important;
}
a.bg-gray-200:focus,
a.bg-gray-200:hover,
button.bg-gray-200:focus,
button.bg-gray-200:hover {
  background-color: #cbd3da !important;
}
.bg-gray-300 {
  background-color: #dde1ed !important;
}
a.bg-gray-300:focus,
a.bg-gray-300:hover,
button.bg-gray-300:focus,
button.bg-gray-300:hover {
  background-color: #bcc4db !important;
}
.bg-gray-400 {
  background-color: #e0e6f0 !important;
}
a.bg-gray-400:focus,
a.bg-gray-400:hover,
button.bg-gray-400:focus,
button.bg-gray-400:hover {
  background-color: #becadf !important;
}
.bg-gray-500 {
  background-color: #adb5bd !important;
}
a.bg-gray-500:focus,
a.bg-gray-500:hover,
button.bg-gray-500:focus,
button.bg-gray-500:hover {
  background-color: #919ca6 !important;
}
.bg-gray-600 {
  background-color: #869ab8 !important;
}
a.bg-gray-600:focus,
a.bg-gray-600:hover,
button.bg-gray-600:focus,
button.bg-gray-600:hover {
  background-color: #667fa5 !important;
}
.bg-gray-700 {
  background-color: #66799e !important;
}
a.bg-gray-700:focus,
a.bg-gray-700:hover,
button.bg-gray-700:focus,
button.bg-gray-700:hover {
  background-color: #516180 !important;
}
.bg-gray-800 {
  background-color: #506690 !important;
}
a.bg-gray-800:focus,
a.bg-gray-800:hover,
button.bg-gray-800:focus,
button.bg-gray-800:hover {
  background-color: #3e4f6f !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 0.0625rem solid #e9ecef !important;
}
.border-top {
  border-top: 0.0625rem solid #e9ecef !important;
}
.border-right {
  border-right: 0.0625rem solid #e9ecef !important;
}
.border-bottom {
  border-bottom: 0.0625rem solid #e9ecef !important;
}
.border-left {
  border-left: 0.0625rem solid #e9ecef !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #11ab7c !important;
}
.border-secondary {
  border-color: #005ad4 !important;
}
.border-success {
  border-color: #00bf9a !important;
}
.border-info {
  border-color: #1e90ff !important;
}
.border-warning {
  border-color: #f5b759 !important;
}
.border-danger {
  border-color: #fa5252 !important;
}
.border-light {
  border-color: #dde1ed !important;
}
.border-dark {
  border-color: #2a354f !important;
}
.border-tertiary {
  border-color: #de6926 !important;
}
.border-orange {
  border-color: #ff7f00 !important;
}
.border-white {
  border-color: #fff !important;
}
.border-gray {
  border-color: #66799e !important;
}
.border-neutral {
  border-color: #fff !important;
}
.border-soft {
  border-color: #f3f7fa !important;
}
.border-black {
  border-color: #3e4555 !important;
}
.border-purple {
  border-color: #8965e0 !important;
}
.border-gray-100 {
  border-color: #f3f7fa !important;
}
.border-gray-200 {
  border-color: #e9ecef !important;
}
.border-gray-300 {
  border-color: #dde1ed !important;
}
.border-gray-400 {
  border-color: #e0e6f0 !important;
}
.border-gray-500 {
  border-color: #adb5bd !important;
}
.border-gray-600 {
  border-color: #869ab8 !important;
}
.border-gray-700 {
  border-color: #66799e !important;
}
.border-gray-800 {
  border-color: #506690 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.125rem !important;
}
.rounded {
  border-radius: 0.5rem !important;
}
.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.rounded-left {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 830px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.headroom--pinned,
.headroom--unpinned,
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(42, 53, 79, 0.12) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(42, 53, 79, 0.125) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.7rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.7rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.7rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.7rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.7rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.m-6 {
  margin: 5rem !important;
}
.mt-6,
.my-6 {
  margin-top: 5rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}
.m-7 {
  margin: 7rem !important;
}
.mt-7,
.my-7 {
  margin-top: 7rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 7rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 7rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 7rem !important;
}
.m-8 {
  margin: 10rem !important;
}
.mt-8,
.my-8 {
  margin-top: 10rem !important;
}
.mr-8,
.mx-8 {
  margin-right: 10rem !important;
}
.mb-8,
.my-8 {
  margin-bottom: 10rem !important;
}
.ml-8,
.mx-8 {
  margin-left: 10rem !important;
}
.m-9 {
  margin: 12rem !important;
}
.mt-9,
.my-9 {
  margin-top: 12rem !important;
}
.mr-9,
.mx-9 {
  margin-right: 12rem !important;
}
.mb-9,
.my-9 {
  margin-bottom: 12rem !important;
}
.ml-9,
.mx-9 {
  margin-left: 12rem !important;
}
.m-10 {
  margin: 14rem !important;
}
.mt-10,
.my-10 {
  margin-top: 14rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 14rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 14rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 14rem !important;
}
.m-11 {
  margin: 18rem !important;
}
.mt-11,
.my-11 {
  margin-top: 18rem !important;
}
.mr-11,
.mx-11 {
  margin-right: 18rem !important;
}
.mb-11,
.my-11 {
  margin-bottom: 18rem !important;
}
.ml-11,
.mx-11 {
  margin-left: 18rem !important;
}
.m-12 {
  margin: 22rem !important;
}
.mt-12,
.my-12 {
  margin-top: 22rem !important;
}
.mr-12,
.mx-12 {
  margin-right: 22rem !important;
}
.mb-12,
.my-12 {
  margin-bottom: 22rem !important;
}
.ml-12,
.mx-12 {
  margin-left: 22rem !important;
}
.m-sm {
  margin: 1rem !important;
}
.mt-sm,
.my-sm {
  margin-top: 1rem !important;
}
.mr-sm,
.mx-sm {
  margin-right: 1rem !important;
}
.mb-sm,
.my-sm {
  margin-bottom: 1rem !important;
}
.ml-sm,
.mx-sm {
  margin-left: 1rem !important;
}
.m-md {
  margin: 2rem !important;
}
.mt-md,
.my-md {
  margin-top: 2rem !important;
}
.mr-md,
.mx-md {
  margin-right: 2rem !important;
}
.mb-md,
.my-md {
  margin-bottom: 2rem !important;
}
.ml-md,
.mx-md {
  margin-left: 2rem !important;
}
.m-lg {
  margin: 4rem !important;
}
.mt-lg,
.my-lg {
  margin-top: 4rem !important;
}
.mr-lg,
.mx-lg {
  margin-right: 4rem !important;
}
.mb-lg,
.my-lg {
  margin-bottom: 4rem !important;
}
.ml-lg,
.mx-lg {
  margin-left: 4rem !important;
}
.m-xl {
  margin: 8rem !important;
}
.mt-xl,
.my-xl {
  margin-top: 8rem !important;
}
.mr-xl,
.mx-xl {
  margin-right: 8rem !important;
}
.mb-xl,
.my-xl {
  margin-bottom: 8rem !important;
}
.ml-xl,
.mx-xl {
  margin-left: 8rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.7rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.7rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.7rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.7rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.7rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.p-6 {
  padding: 5rem !important;
}
.pt-6,
.py-6 {
  padding-top: 5rem !important;
}
.pr-6,
.px-6 {
  padding-right: 5rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}
.pl-6,
.px-6 {
  padding-left: 5rem !important;
}
.p-7 {
  padding: 7rem !important;
}
.pt-7,
.py-7 {
  padding-top: 7rem !important;
}
.pr-7,
.px-7 {
  padding-right: 7rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 7rem !important;
}
.pl-7,
.px-7 {
  padding-left: 7rem !important;
}
.p-8 {
  padding: 10rem !important;
}
.pt-8,
.py-8 {
  padding-top: 10rem !important;
}
.pr-8,
.px-8 {
  padding-right: 10rem !important;
}
.pb-8,
.py-8 {
  padding-bottom: 10rem !important;
}
.pl-8,
.px-8 {
  padding-left: 10rem !important;
}
.p-9 {
  padding: 12rem !important;
}
.pt-9,
.py-9 {
  padding-top: 12rem !important;
}
.pr-9,
.px-9 {
  padding-right: 12rem !important;
}
.pb-9,
.py-9 {
  padding-bottom: 12rem !important;
}
.pl-9,
.px-9 {
  padding-left: 12rem !important;
}
.p-10 {
  padding: 14rem !important;
}
.pt-10,
.py-10 {
  padding-top: 14rem !important;
}
.pr-10,
.px-10 {
  padding-right: 14rem !important;
}
.pb-10,
.py-10 {
  padding-bottom: 14rem !important;
}
.pl-10,
.px-10 {
  padding-left: 14rem !important;
}
.p-11 {
  padding: 18rem !important;
}
.pt-11,
.py-11 {
  padding-top: 18rem !important;
}
.pr-11,
.px-11 {
  padding-right: 18rem !important;
}
.pb-11,
.py-11 {
  padding-bottom: 18rem !important;
}
.pl-11,
.px-11 {
  padding-left: 18rem !important;
}
.p-12 {
  padding: 22rem !important;
}
.pt-12,
.py-12 {
  padding-top: 22rem !important;
}
.pr-12,
.px-12 {
  padding-right: 22rem !important;
}
.pb-12,
.py-12 {
  padding-bottom: 22rem !important;
}
.pl-12,
.px-12 {
  padding-left: 22rem !important;
}
.p-sm {
  padding: 1rem !important;
}
.pt-sm,
.py-sm {
  padding-top: 1rem !important;
}
.pr-sm,
.px-sm {
  padding-right: 1rem !important;
}
.pb-sm,
.py-sm {
  padding-bottom: 1rem !important;
}
.pl-sm,
.px-sm {
  padding-left: 1rem !important;
}
.p-md {
  padding: 2rem !important;
}
.pt-md,
.py-md {
  padding-top: 2rem !important;
}
.pr-md,
.px-md {
  padding-right: 2rem !important;
}
.pb-md,
.py-md {
  padding-bottom: 2rem !important;
}
.pl-md,
.px-md {
  padding-left: 2rem !important;
}
.p-lg {
  padding: 4rem !important;
}
.pt-lg,
.py-lg {
  padding-top: 4rem !important;
}
.pr-lg,
.px-lg {
  padding-right: 4rem !important;
}
.pb-lg,
.py-lg {
  padding-bottom: 4rem !important;
}
.pl-lg,
.px-lg {
  padding-left: 4rem !important;
}
.p-xl {
  padding: 8rem !important;
}
.pt-xl,
.py-xl {
  padding-top: 8rem !important;
}
.pr-xl,
.px-xl {
  padding-right: 8rem !important;
}
.pb-xl,
.py-xl {
  padding-bottom: 8rem !important;
}
.pl-xl,
.px-xl {
  padding-left: 8rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.7rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.7rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.7rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.7rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.7rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-n6 {
  margin: -5rem !important;
}
.mt-n6,
.my-n6 {
  margin-top: -5rem !important;
}
.mr-n6,
.mx-n6 {
  margin-right: -5rem !important;
}
.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important;
}
.ml-n6,
.mx-n6 {
  margin-left: -5rem !important;
}
.m-n7 {
  margin: -7rem !important;
}
.mt-n7,
.my-n7 {
  margin-top: -7rem !important;
}
.mr-n7,
.mx-n7 {
  margin-right: -7rem !important;
}
.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important;
}
.ml-n7,
.mx-n7 {
  margin-left: -7rem !important;
}
.m-n8 {
  margin: -10rem !important;
}
.mt-n8,
.my-n8 {
  margin-top: -10rem !important;
}
.mr-n8,
.mx-n8 {
  margin-right: -10rem !important;
}
.mb-n8,
.my-n8 {
  margin-bottom: -10rem !important;
}
.ml-n8,
.mx-n8 {
  margin-left: -10rem !important;
}
.m-n9 {
  margin: -12rem !important;
}
.mt-n9,
.my-n9 {
  margin-top: -12rem !important;
}
.mr-n9,
.mx-n9 {
  margin-right: -12rem !important;
}
.mb-n9,
.my-n9 {
  margin-bottom: -12rem !important;
}
.ml-n9,
.mx-n9 {
  margin-left: -12rem !important;
}
.m-n10 {
  margin: -14rem !important;
}
.mt-n10,
.my-n10 {
  margin-top: -14rem !important;
}
.mr-n10,
.mx-n10 {
  margin-right: -14rem !important;
}
.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important;
}
.ml-n10,
.mx-n10 {
  margin-left: -14rem !important;
}
.m-n11 {
  margin: -18rem !important;
}
.mt-n11,
.my-n11 {
  margin-top: -18rem !important;
}
.mr-n11,
.mx-n11 {
  margin-right: -18rem !important;
}
.mb-n11,
.my-n11 {
  margin-bottom: -18rem !important;
}
.ml-n11,
.mx-n11 {
  margin-left: -18rem !important;
}
.m-n12 {
  margin: -22rem !important;
}
.mt-n12,
.my-n12 {
  margin-top: -22rem !important;
}
.mr-n12,
.mx-n12 {
  margin-right: -22rem !important;
}
.mb-n12,
.my-n12 {
  margin-bottom: -22rem !important;
}
.ml-n12,
.mx-n12 {
  margin-left: -22rem !important;
}
.m-nsm {
  margin: -1rem !important;
}
.mt-nsm,
.my-nsm {
  margin-top: -1rem !important;
}
.mr-nsm,
.mx-nsm {
  margin-right: -1rem !important;
}
.mb-nsm,
.my-nsm {
  margin-bottom: -1rem !important;
}
.ml-nsm,
.mx-nsm {
  margin-left: -1rem !important;
}
.m-nmd {
  margin: -2rem !important;
}
.mt-nmd,
.my-nmd {
  margin-top: -2rem !important;
}
.mr-nmd,
.mx-nmd {
  margin-right: -2rem !important;
}
.mb-nmd,
.my-nmd {
  margin-bottom: -2rem !important;
}
.ml-nmd,
.mx-nmd {
  margin-left: -2rem !important;
}
.m-nlg {
  margin: -4rem !important;
}
.mt-nlg,
.my-nlg {
  margin-top: -4rem !important;
}
.mr-nlg,
.mx-nlg {
  margin-right: -4rem !important;
}
.mb-nlg,
.my-nlg {
  margin-bottom: -4rem !important;
}
.ml-nlg,
.mx-nlg {
  margin-left: -4rem !important;
}
.m-nxl {
  margin: -8rem !important;
}
.mt-nxl,
.my-nxl {
  margin-top: -8rem !important;
}
.mr-nxl,
.mx-nxl {
  margin-right: -8rem !important;
}
.mb-nxl,
.my-nxl {
  margin-bottom: -8rem !important;
}
.ml-nxl,
.mx-nxl {
  margin-left: -8rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.7rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.7rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.7rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.7rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.7rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }
  .m-sm-7 {
    margin: 7rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important;
  }
  .m-sm-8 {
    margin: 10rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 10rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 10rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 10rem !important;
  }
  .m-sm-9 {
    margin: 12rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 12rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 12rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 12rem !important;
  }
  .m-sm-10 {
    margin: 14rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 14rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 14rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 14rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 14rem !important;
  }
  .m-sm-11 {
    margin: 18rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 18rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 18rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 18rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 18rem !important;
  }
  .m-sm-12 {
    margin: 22rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 22rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 22rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 22rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 22rem !important;
  }
  .m-sm-sm {
    margin: 1rem !important;
  }
  .mt-sm-sm,
  .my-sm-sm {
    margin-top: 1rem !important;
  }
  .mr-sm-sm,
  .mx-sm-sm {
    margin-right: 1rem !important;
  }
  .mb-sm-sm,
  .my-sm-sm {
    margin-bottom: 1rem !important;
  }
  .ml-sm-sm,
  .mx-sm-sm {
    margin-left: 1rem !important;
  }
  .m-sm-md {
    margin: 2rem !important;
  }
  .mt-sm-md,
  .my-sm-md {
    margin-top: 2rem !important;
  }
  .mr-sm-md,
  .mx-sm-md {
    margin-right: 2rem !important;
  }
  .mb-sm-md,
  .my-sm-md {
    margin-bottom: 2rem !important;
  }
  .ml-sm-md,
  .mx-sm-md {
    margin-left: 2rem !important;
  }
  .m-sm-lg {
    margin: 4rem !important;
  }
  .mt-sm-lg,
  .my-sm-lg {
    margin-top: 4rem !important;
  }
  .mr-sm-lg,
  .mx-sm-lg {
    margin-right: 4rem !important;
  }
  .mb-sm-lg,
  .my-sm-lg {
    margin-bottom: 4rem !important;
  }
  .ml-sm-lg,
  .mx-sm-lg {
    margin-left: 4rem !important;
  }
  .m-sm-xl {
    margin: 8rem !important;
  }
  .mt-sm-xl,
  .my-sm-xl {
    margin-top: 8rem !important;
  }
  .mr-sm-xl,
  .mx-sm-xl {
    margin-right: 8rem !important;
  }
  .mb-sm-xl,
  .my-sm-xl {
    margin-bottom: 8rem !important;
  }
  .ml-sm-xl,
  .mx-sm-xl {
    margin-left: 8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.7rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.7rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.7rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.7rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.7rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }
  .p-sm-7 {
    padding: 7rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important;
  }
  .p-sm-8 {
    padding: 10rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 10rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 10rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 10rem !important;
  }
  .p-sm-9 {
    padding: 12rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 12rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 12rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 12rem !important;
  }
  .p-sm-10 {
    padding: 14rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 14rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 14rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 14rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 14rem !important;
  }
  .p-sm-11 {
    padding: 18rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 18rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 18rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 18rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 18rem !important;
  }
  .p-sm-12 {
    padding: 22rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 22rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 22rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 22rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 22rem !important;
  }
  .p-sm-sm {
    padding: 1rem !important;
  }
  .pt-sm-sm,
  .py-sm-sm {
    padding-top: 1rem !important;
  }
  .pr-sm-sm,
  .px-sm-sm {
    padding-right: 1rem !important;
  }
  .pb-sm-sm,
  .py-sm-sm {
    padding-bottom: 1rem !important;
  }
  .pl-sm-sm,
  .px-sm-sm {
    padding-left: 1rem !important;
  }
  .p-sm-md {
    padding: 2rem !important;
  }
  .pt-sm-md,
  .py-sm-md {
    padding-top: 2rem !important;
  }
  .pr-sm-md,
  .px-sm-md {
    padding-right: 2rem !important;
  }
  .pb-sm-md,
  .py-sm-md {
    padding-bottom: 2rem !important;
  }
  .pl-sm-md,
  .px-sm-md {
    padding-left: 2rem !important;
  }
  .p-sm-lg {
    padding: 4rem !important;
  }
  .pt-sm-lg,
  .py-sm-lg {
    padding-top: 4rem !important;
  }
  .pr-sm-lg,
  .px-sm-lg {
    padding-right: 4rem !important;
  }
  .pb-sm-lg,
  .py-sm-lg {
    padding-bottom: 4rem !important;
  }
  .pl-sm-lg,
  .px-sm-lg {
    padding-left: 4rem !important;
  }
  .p-sm-xl {
    padding: 8rem !important;
  }
  .pt-sm-xl,
  .py-sm-xl {
    padding-top: 8rem !important;
  }
  .pr-sm-xl,
  .px-sm-xl {
    padding-right: 8rem !important;
  }
  .pb-sm-xl,
  .py-sm-xl {
    padding-bottom: 8rem !important;
  }
  .pl-sm-xl,
  .px-sm-xl {
    padding-left: 8rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.7rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.7rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.7rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.7rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.7rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important;
  }
  .m-sm-n7 {
    margin: -7rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important;
  }
  .m-sm-n8 {
    margin: -10rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -10rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -10rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -10rem !important;
  }
  .m-sm-n9 {
    margin: -12rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -12rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -12rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -12rem !important;
  }
  .m-sm-n10 {
    margin: -14rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -14rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -14rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -14rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -14rem !important;
  }
  .m-sm-n11 {
    margin: -18rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -18rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -18rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -18rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -18rem !important;
  }
  .m-sm-n12 {
    margin: -22rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -22rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -22rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -22rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -22rem !important;
  }
  .m-sm-nsm {
    margin: -1rem !important;
  }
  .mt-sm-nsm,
  .my-sm-nsm {
    margin-top: -1rem !important;
  }
  .mr-sm-nsm,
  .mx-sm-nsm {
    margin-right: -1rem !important;
  }
  .mb-sm-nsm,
  .my-sm-nsm {
    margin-bottom: -1rem !important;
  }
  .ml-sm-nsm,
  .mx-sm-nsm {
    margin-left: -1rem !important;
  }
  .m-sm-nmd {
    margin: -2rem !important;
  }
  .mt-sm-nmd,
  .my-sm-nmd {
    margin-top: -2rem !important;
  }
  .mr-sm-nmd,
  .mx-sm-nmd {
    margin-right: -2rem !important;
  }
  .mb-sm-nmd,
  .my-sm-nmd {
    margin-bottom: -2rem !important;
  }
  .ml-sm-nmd,
  .mx-sm-nmd {
    margin-left: -2rem !important;
  }
  .m-sm-nlg {
    margin: -4rem !important;
  }
  .mt-sm-nlg,
  .my-sm-nlg {
    margin-top: -4rem !important;
  }
  .mr-sm-nlg,
  .mx-sm-nlg {
    margin-right: -4rem !important;
  }
  .mb-sm-nlg,
  .my-sm-nlg {
    margin-bottom: -4rem !important;
  }
  .ml-sm-nlg,
  .mx-sm-nlg {
    margin-left: -4rem !important;
  }
  .m-sm-nxl {
    margin: -8rem !important;
  }
  .mt-sm-nxl,
  .my-sm-nxl {
    margin-top: -8rem !important;
  }
  .mr-sm-nxl,
  .mx-sm-nxl {
    margin-right: -8rem !important;
  }
  .mb-sm-nxl,
  .my-sm-nxl {
    margin-bottom: -8rem !important;
  }
  .ml-sm-nxl,
  .mx-sm-nxl {
    margin-left: -8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.7rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.7rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.7rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.7rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.7rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }
  .m-md-7 {
    margin: 7rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important;
  }
  .m-md-8 {
    margin: 10rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 10rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 10rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 10rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 10rem !important;
  }
  .m-md-9 {
    margin: 12rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 12rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 12rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 12rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 12rem !important;
  }
  .m-md-10 {
    margin: 14rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 14rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 14rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 14rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 14rem !important;
  }
  .m-md-11 {
    margin: 18rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 18rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 18rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 18rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 18rem !important;
  }
  .m-md-12 {
    margin: 22rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 22rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 22rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 22rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 22rem !important;
  }
  .m-md-sm {
    margin: 1rem !important;
  }
  .mt-md-sm,
  .my-md-sm {
    margin-top: 1rem !important;
  }
  .mr-md-sm,
  .mx-md-sm {
    margin-right: 1rem !important;
  }
  .mb-md-sm,
  .my-md-sm {
    margin-bottom: 1rem !important;
  }
  .ml-md-sm,
  .mx-md-sm {
    margin-left: 1rem !important;
  }
  .m-md-md {
    margin: 2rem !important;
  }
  .mt-md-md,
  .my-md-md {
    margin-top: 2rem !important;
  }
  .mr-md-md,
  .mx-md-md {
    margin-right: 2rem !important;
  }
  .mb-md-md,
  .my-md-md {
    margin-bottom: 2rem !important;
  }
  .ml-md-md,
  .mx-md-md {
    margin-left: 2rem !important;
  }
  .m-md-lg {
    margin: 4rem !important;
  }
  .mt-md-lg,
  .my-md-lg {
    margin-top: 4rem !important;
  }
  .mr-md-lg,
  .mx-md-lg {
    margin-right: 4rem !important;
  }
  .mb-md-lg,
  .my-md-lg {
    margin-bottom: 4rem !important;
  }
  .ml-md-lg,
  .mx-md-lg {
    margin-left: 4rem !important;
  }
  .m-md-xl {
    margin: 8rem !important;
  }
  .mt-md-xl,
  .my-md-xl {
    margin-top: 8rem !important;
  }
  .mr-md-xl,
  .mx-md-xl {
    margin-right: 8rem !important;
  }
  .mb-md-xl,
  .my-md-xl {
    margin-bottom: 8rem !important;
  }
  .ml-md-xl,
  .mx-md-xl {
    margin-left: 8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.7rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.7rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.7rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.7rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.7rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }
  .p-md-7 {
    padding: 7rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important;
  }
  .p-md-8 {
    padding: 10rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 10rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 10rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 10rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 10rem !important;
  }
  .p-md-9 {
    padding: 12rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 12rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 12rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 12rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 12rem !important;
  }
  .p-md-10 {
    padding: 14rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 14rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 14rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 14rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 14rem !important;
  }
  .p-md-11 {
    padding: 18rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 18rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 18rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 18rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 18rem !important;
  }
  .p-md-12 {
    padding: 22rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 22rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 22rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 22rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 22rem !important;
  }
  .p-md-sm {
    padding: 1rem !important;
  }
  .pt-md-sm,
  .py-md-sm {
    padding-top: 1rem !important;
  }
  .pr-md-sm,
  .px-md-sm {
    padding-right: 1rem !important;
  }
  .pb-md-sm,
  .py-md-sm {
    padding-bottom: 1rem !important;
  }
  .pl-md-sm,
  .px-md-sm {
    padding-left: 1rem !important;
  }
  .p-md-md {
    padding: 2rem !important;
  }
  .pt-md-md,
  .py-md-md {
    padding-top: 2rem !important;
  }
  .pr-md-md,
  .px-md-md {
    padding-right: 2rem !important;
  }
  .pb-md-md,
  .py-md-md {
    padding-bottom: 2rem !important;
  }
  .pl-md-md,
  .px-md-md {
    padding-left: 2rem !important;
  }
  .p-md-lg {
    padding: 4rem !important;
  }
  .pt-md-lg,
  .py-md-lg {
    padding-top: 4rem !important;
  }
  .pr-md-lg,
  .px-md-lg {
    padding-right: 4rem !important;
  }
  .pb-md-lg,
  .py-md-lg {
    padding-bottom: 4rem !important;
  }
  .pl-md-lg,
  .px-md-lg {
    padding-left: 4rem !important;
  }
  .p-md-xl {
    padding: 8rem !important;
  }
  .pt-md-xl,
  .py-md-xl {
    padding-top: 8rem !important;
  }
  .pr-md-xl,
  .px-md-xl {
    padding-right: 8rem !important;
  }
  .pb-md-xl,
  .py-md-xl {
    padding-bottom: 8rem !important;
  }
  .pl-md-xl,
  .px-md-xl {
    padding-left: 8rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.7rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.7rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.7rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.7rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.7rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important;
  }
  .m-md-n7 {
    margin: -7rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important;
  }
  .m-md-n8 {
    margin: -10rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -10rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -10rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -10rem !important;
  }
  .m-md-n9 {
    margin: -12rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -12rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -12rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -12rem !important;
  }
  .m-md-n10 {
    margin: -14rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -14rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -14rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -14rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -14rem !important;
  }
  .m-md-n11 {
    margin: -18rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -18rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -18rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -18rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -18rem !important;
  }
  .m-md-n12 {
    margin: -22rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -22rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -22rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -22rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -22rem !important;
  }
  .m-md-nsm {
    margin: -1rem !important;
  }
  .mt-md-nsm,
  .my-md-nsm {
    margin-top: -1rem !important;
  }
  .mr-md-nsm,
  .mx-md-nsm {
    margin-right: -1rem !important;
  }
  .mb-md-nsm,
  .my-md-nsm {
    margin-bottom: -1rem !important;
  }
  .ml-md-nsm,
  .mx-md-nsm {
    margin-left: -1rem !important;
  }
  .m-md-nmd {
    margin: -2rem !important;
  }
  .mt-md-nmd,
  .my-md-nmd {
    margin-top: -2rem !important;
  }
  .mr-md-nmd,
  .mx-md-nmd {
    margin-right: -2rem !important;
  }
  .mb-md-nmd,
  .my-md-nmd {
    margin-bottom: -2rem !important;
  }
  .ml-md-nmd,
  .mx-md-nmd {
    margin-left: -2rem !important;
  }
  .m-md-nlg {
    margin: -4rem !important;
  }
  .mt-md-nlg,
  .my-md-nlg {
    margin-top: -4rem !important;
  }
  .mr-md-nlg,
  .mx-md-nlg {
    margin-right: -4rem !important;
  }
  .mb-md-nlg,
  .my-md-nlg {
    margin-bottom: -4rem !important;
  }
  .ml-md-nlg,
  .mx-md-nlg {
    margin-left: -4rem !important;
  }
  .m-md-nxl {
    margin: -8rem !important;
  }
  .mt-md-nxl,
  .my-md-nxl {
    margin-top: -8rem !important;
  }
  .mr-md-nxl,
  .mx-md-nxl {
    margin-right: -8rem !important;
  }
  .mb-md-nxl,
  .my-md-nxl {
    margin-bottom: -8rem !important;
  }
  .ml-md-nxl,
  .mx-md-nxl {
    margin-left: -8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.7rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.7rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.7rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.7rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.7rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }
  .m-lg-7 {
    margin: 7rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important;
  }
  .m-lg-8 {
    margin: 10rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 10rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 10rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 10rem !important;
  }
  .m-lg-9 {
    margin: 12rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 12rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 12rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 12rem !important;
  }
  .m-lg-10 {
    margin: 14rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 14rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 14rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 14rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 14rem !important;
  }
  .m-lg-11 {
    margin: 18rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 18rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 18rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 18rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 18rem !important;
  }
  .m-lg-12 {
    margin: 22rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 22rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 22rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 22rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 22rem !important;
  }
  .m-lg-sm {
    margin: 1rem !important;
  }
  .mt-lg-sm,
  .my-lg-sm {
    margin-top: 1rem !important;
  }
  .mr-lg-sm,
  .mx-lg-sm {
    margin-right: 1rem !important;
  }
  .mb-lg-sm,
  .my-lg-sm {
    margin-bottom: 1rem !important;
  }
  .ml-lg-sm,
  .mx-lg-sm {
    margin-left: 1rem !important;
  }
  .m-lg-md {
    margin: 2rem !important;
  }
  .mt-lg-md,
  .my-lg-md {
    margin-top: 2rem !important;
  }
  .mr-lg-md,
  .mx-lg-md {
    margin-right: 2rem !important;
  }
  .mb-lg-md,
  .my-lg-md {
    margin-bottom: 2rem !important;
  }
  .ml-lg-md,
  .mx-lg-md {
    margin-left: 2rem !important;
  }
  .m-lg-lg {
    margin: 4rem !important;
  }
  .mt-lg-lg,
  .my-lg-lg {
    margin-top: 4rem !important;
  }
  .mr-lg-lg,
  .mx-lg-lg {
    margin-right: 4rem !important;
  }
  .mb-lg-lg,
  .my-lg-lg {
    margin-bottom: 4rem !important;
  }
  .ml-lg-lg,
  .mx-lg-lg {
    margin-left: 4rem !important;
  }
  .m-lg-xl {
    margin: 8rem !important;
  }
  .mt-lg-xl,
  .my-lg-xl {
    margin-top: 8rem !important;
  }
  .mr-lg-xl,
  .mx-lg-xl {
    margin-right: 8rem !important;
  }
  .mb-lg-xl,
  .my-lg-xl {
    margin-bottom: 8rem !important;
  }
  .ml-lg-xl,
  .mx-lg-xl {
    margin-left: 8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.7rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.7rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.7rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.7rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.7rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }
  .p-lg-7 {
    padding: 7rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important;
  }
  .p-lg-8 {
    padding: 10rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 10rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 10rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 10rem !important;
  }
  .p-lg-9 {
    padding: 12rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 12rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 12rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 12rem !important;
  }
  .p-lg-10 {
    padding: 14rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 14rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 14rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 14rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 14rem !important;
  }
  .p-lg-11 {
    padding: 18rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 18rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 18rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 18rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 18rem !important;
  }
  .p-lg-12 {
    padding: 22rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 22rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 22rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 22rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 22rem !important;
  }
  .p-lg-sm {
    padding: 1rem !important;
  }
  .pt-lg-sm,
  .py-lg-sm {
    padding-top: 1rem !important;
  }
  .pr-lg-sm,
  .px-lg-sm {
    padding-right: 1rem !important;
  }
  .pb-lg-sm,
  .py-lg-sm {
    padding-bottom: 1rem !important;
  }
  .pl-lg-sm,
  .px-lg-sm {
    padding-left: 1rem !important;
  }
  .p-lg-md {
    padding: 2rem !important;
  }
  .pt-lg-md,
  .py-lg-md {
    padding-top: 2rem !important;
  }
  .pr-lg-md,
  .px-lg-md {
    padding-right: 2rem !important;
  }
  .pb-lg-md,
  .py-lg-md {
    padding-bottom: 2rem !important;
  }
  .pl-lg-md,
  .px-lg-md {
    padding-left: 2rem !important;
  }
  .p-lg-lg {
    padding: 4rem !important;
  }
  .pt-lg-lg,
  .py-lg-lg {
    padding-top: 4rem !important;
  }
  .pr-lg-lg,
  .px-lg-lg {
    padding-right: 4rem !important;
  }
  .pb-lg-lg,
  .py-lg-lg {
    padding-bottom: 4rem !important;
  }
  .pl-lg-lg,
  .px-lg-lg {
    padding-left: 4rem !important;
  }
  .p-lg-xl {
    padding: 8rem !important;
  }
  .pt-lg-xl,
  .py-lg-xl {
    padding-top: 8rem !important;
  }
  .pr-lg-xl,
  .px-lg-xl {
    padding-right: 8rem !important;
  }
  .pb-lg-xl,
  .py-lg-xl {
    padding-bottom: 8rem !important;
  }
  .pl-lg-xl,
  .px-lg-xl {
    padding-left: 8rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.7rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.7rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.7rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.7rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.7rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important;
  }
  .m-lg-n7 {
    margin: -7rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important;
  }
  .m-lg-n8 {
    margin: -10rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -10rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -10rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -10rem !important;
  }
  .m-lg-n9 {
    margin: -12rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -12rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -12rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -12rem !important;
  }
  .m-lg-n10 {
    margin: -14rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -14rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -14rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -14rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -14rem !important;
  }
  .m-lg-n11 {
    margin: -18rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -18rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -18rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -18rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -18rem !important;
  }
  .m-lg-n12 {
    margin: -22rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -22rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -22rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -22rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -22rem !important;
  }
  .m-lg-nsm {
    margin: -1rem !important;
  }
  .mt-lg-nsm,
  .my-lg-nsm {
    margin-top: -1rem !important;
  }
  .mr-lg-nsm,
  .mx-lg-nsm {
    margin-right: -1rem !important;
  }
  .mb-lg-nsm,
  .my-lg-nsm {
    margin-bottom: -1rem !important;
  }
  .ml-lg-nsm,
  .mx-lg-nsm {
    margin-left: -1rem !important;
  }
  .m-lg-nmd {
    margin: -2rem !important;
  }
  .mt-lg-nmd,
  .my-lg-nmd {
    margin-top: -2rem !important;
  }
  .mr-lg-nmd,
  .mx-lg-nmd {
    margin-right: -2rem !important;
  }
  .mb-lg-nmd,
  .my-lg-nmd {
    margin-bottom: -2rem !important;
  }
  .ml-lg-nmd,
  .mx-lg-nmd {
    margin-left: -2rem !important;
  }
  .m-lg-nlg {
    margin: -4rem !important;
  }
  .mt-lg-nlg,
  .my-lg-nlg {
    margin-top: -4rem !important;
  }
  .mr-lg-nlg,
  .mx-lg-nlg {
    margin-right: -4rem !important;
  }
  .mb-lg-nlg,
  .my-lg-nlg {
    margin-bottom: -4rem !important;
  }
  .ml-lg-nlg,
  .mx-lg-nlg {
    margin-left: -4rem !important;
  }
  .m-lg-nxl {
    margin: -8rem !important;
  }
  .mt-lg-nxl,
  .my-lg-nxl {
    margin-top: -8rem !important;
  }
  .mr-lg-nxl,
  .mx-lg-nxl {
    margin-right: -8rem !important;
  }
  .mb-lg-nxl,
  .my-lg-nxl {
    margin-bottom: -8rem !important;
  }
  .ml-lg-nxl,
  .mx-lg-nxl {
    margin-left: -8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.7rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.7rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.7rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.7rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.7rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }
  .m-xl-7 {
    margin: 7rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important;
  }
  .m-xl-8 {
    margin: 10rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 10rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 10rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 10rem !important;
  }
  .m-xl-9 {
    margin: 12rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 12rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 12rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 12rem !important;
  }
  .m-xl-10 {
    margin: 14rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 14rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 14rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 14rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 14rem !important;
  }
  .m-xl-11 {
    margin: 18rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 18rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 18rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 18rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 18rem !important;
  }
  .m-xl-12 {
    margin: 22rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 22rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 22rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 22rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 22rem !important;
  }
  .m-xl-sm {
    margin: 1rem !important;
  }
  .mt-xl-sm,
  .my-xl-sm {
    margin-top: 1rem !important;
  }
  .mr-xl-sm,
  .mx-xl-sm {
    margin-right: 1rem !important;
  }
  .mb-xl-sm,
  .my-xl-sm {
    margin-bottom: 1rem !important;
  }
  .ml-xl-sm,
  .mx-xl-sm {
    margin-left: 1rem !important;
  }
  .m-xl-md {
    margin: 2rem !important;
  }
  .mt-xl-md,
  .my-xl-md {
    margin-top: 2rem !important;
  }
  .mr-xl-md,
  .mx-xl-md {
    margin-right: 2rem !important;
  }
  .mb-xl-md,
  .my-xl-md {
    margin-bottom: 2rem !important;
  }
  .ml-xl-md,
  .mx-xl-md {
    margin-left: 2rem !important;
  }
  .m-xl-lg {
    margin: 4rem !important;
  }
  .mt-xl-lg,
  .my-xl-lg {
    margin-top: 4rem !important;
  }
  .mr-xl-lg,
  .mx-xl-lg {
    margin-right: 4rem !important;
  }
  .mb-xl-lg,
  .my-xl-lg {
    margin-bottom: 4rem !important;
  }
  .ml-xl-lg,
  .mx-xl-lg {
    margin-left: 4rem !important;
  }
  .m-xl-xl {
    margin: 8rem !important;
  }
  .mt-xl-xl,
  .my-xl-xl {
    margin-top: 8rem !important;
  }
  .mr-xl-xl,
  .mx-xl-xl {
    margin-right: 8rem !important;
  }
  .mb-xl-xl,
  .my-xl-xl {
    margin-bottom: 8rem !important;
  }
  .ml-xl-xl,
  .mx-xl-xl {
    margin-left: 8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.7rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.7rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.7rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.7rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.7rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }
  .p-xl-7 {
    padding: 7rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important;
  }
  .p-xl-8 {
    padding: 10rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 10rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 10rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 10rem !important;
  }
  .p-xl-9 {
    padding: 12rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 12rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 12rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 12rem !important;
  }
  .p-xl-10 {
    padding: 14rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 14rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 14rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 14rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 14rem !important;
  }
  .p-xl-11 {
    padding: 18rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 18rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 18rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 18rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 18rem !important;
  }
  .p-xl-12 {
    padding: 22rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 22rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 22rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 22rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 22rem !important;
  }
  .p-xl-sm {
    padding: 1rem !important;
  }
  .pt-xl-sm,
  .py-xl-sm {
    padding-top: 1rem !important;
  }
  .pr-xl-sm,
  .px-xl-sm {
    padding-right: 1rem !important;
  }
  .pb-xl-sm,
  .py-xl-sm {
    padding-bottom: 1rem !important;
  }
  .pl-xl-sm,
  .px-xl-sm {
    padding-left: 1rem !important;
  }
  .p-xl-md {
    padding: 2rem !important;
  }
  .pt-xl-md,
  .py-xl-md {
    padding-top: 2rem !important;
  }
  .pr-xl-md,
  .px-xl-md {
    padding-right: 2rem !important;
  }
  .pb-xl-md,
  .py-xl-md {
    padding-bottom: 2rem !important;
  }
  .pl-xl-md,
  .px-xl-md {
    padding-left: 2rem !important;
  }
  .p-xl-lg {
    padding: 4rem !important;
  }
  .pt-xl-lg,
  .py-xl-lg {
    padding-top: 4rem !important;
  }
  .pr-xl-lg,
  .px-xl-lg {
    padding-right: 4rem !important;
  }
  .pb-xl-lg,
  .py-xl-lg {
    padding-bottom: 4rem !important;
  }
  .pl-xl-lg,
  .px-xl-lg {
    padding-left: 4rem !important;
  }
  .p-xl-xl {
    padding: 8rem !important;
  }
  .pt-xl-xl,
  .py-xl-xl {
    padding-top: 8rem !important;
  }
  .pr-xl-xl,
  .px-xl-xl {
    padding-right: 8rem !important;
  }
  .pb-xl-xl,
  .py-xl-xl {
    padding-bottom: 8rem !important;
  }
  .pl-xl-xl,
  .px-xl-xl {
    padding-left: 8rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.7rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.7rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.7rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.7rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.7rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important;
  }
  .m-xl-n7 {
    margin: -7rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important;
  }
  .m-xl-n8 {
    margin: -10rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -10rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -10rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -10rem !important;
  }
  .m-xl-n9 {
    margin: -12rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -12rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -12rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -12rem !important;
  }
  .m-xl-n10 {
    margin: -14rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -14rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -14rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -14rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -14rem !important;
  }
  .m-xl-n11 {
    margin: -18rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -18rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -18rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -18rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -18rem !important;
  }
  .m-xl-n12 {
    margin: -22rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -22rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -22rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -22rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -22rem !important;
  }
  .m-xl-nsm {
    margin: -1rem !important;
  }
  .mt-xl-nsm,
  .my-xl-nsm {
    margin-top: -1rem !important;
  }
  .mr-xl-nsm,
  .mx-xl-nsm {
    margin-right: -1rem !important;
  }
  .mb-xl-nsm,
  .my-xl-nsm {
    margin-bottom: -1rem !important;
  }
  .ml-xl-nsm,
  .mx-xl-nsm {
    margin-left: -1rem !important;
  }
  .m-xl-nmd {
    margin: -2rem !important;
  }
  .mt-xl-nmd,
  .my-xl-nmd {
    margin-top: -2rem !important;
  }
  .mr-xl-nmd,
  .mx-xl-nmd {
    margin-right: -2rem !important;
  }
  .mb-xl-nmd,
  .my-xl-nmd {
    margin-bottom: -2rem !important;
  }
  .ml-xl-nmd,
  .mx-xl-nmd {
    margin-left: -2rem !important;
  }
  .m-xl-nlg {
    margin: -4rem !important;
  }
  .mt-xl-nlg,
  .my-xl-nlg {
    margin-top: -4rem !important;
  }
  .mr-xl-nlg,
  .mx-xl-nlg {
    margin-right: -4rem !important;
  }
  .mb-xl-nlg,
  .my-xl-nlg {
    margin-bottom: -4rem !important;
  }
  .ml-xl-nlg,
  .mx-xl-nlg {
    margin-left: -4rem !important;
  }
  .m-xl-nxl {
    margin: -8rem !important;
  }
  .mt-xl-nxl,
  .my-xl-nxl {
    margin-top: -8rem !important;
  }
  .mr-xl-nxl,
  .mx-xl-nxl {
    margin-right: -8rem !important;
  }
  .mb-xl-nxl,
  .my-xl-nxl {
    margin-bottom: -8rem !important;
  }
  .ml-xl-nxl,
  .mx-xl-nxl {
    margin-left: -8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 600 !important;
}
.font-weight-bolder {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-primary {
  color: #11ab7c !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0a654a !important;
}
.text-secondary {
  color: #005ad4 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #003a88 !important;
}
.text-success {
  color: #00bf9a !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #00735c !important;
}
.text-info {
  color: #1e90ff !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #006ad1 !important;
}
.text-warning {
  color: #f5b759 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #f19811 !important;
}
.text-danger {
  color: #fa5252 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #f80808 !important;
}
.text-light {
  color: #dde1ed !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #abb5d3 !important;
}
.text-dark {
  color: #2a354f !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #0f131d !important;
}
.text-tertiary {
  color: #de6926 !important;
}
a.text-tertiary:focus,
a.text-tertiary:hover {
  color: #9f4918 !important;
}
.text-orange {
  color: #ff7f00 !important;
}
a.text-orange:focus,
a.text-orange:hover {
  color: #b35900 !important;
}
.text-white {
  color: #fff !important;
}
a.text-white:focus,
a.text-white:hover {
  color: #d9d9d9 !important;
}
.text-gray {
  color: #66799e !important;
}
a.text-gray:focus,
a.text-gray:hover {
  color: #475570 !important;
}
.text-neutral {
  color: #fff !important;
}
a.text-neutral:focus,
a.text-neutral:hover {
  color: #d9d9d9 !important;
}
.text-soft {
  color: #f3f7fa !important;
}
a.text-soft:focus,
a.text-soft:hover {
  color: #bdd3e4 !important;
}
.text-black {
  color: #3e4555 !important;
}
a.text-black:focus,
a.text-black:hover {
  color: #1e2129 !important;
}
.text-purple {
  color: #8965e0 !important;
}
a.text-purple:focus,
a.text-purple:hover {
  color: #5a2acf !important;
}
.text-gray-100 {
  color: #f3f7fa !important;
}
a.text-gray-100:focus,
a.text-gray-100:hover {
  color: #bdd3e4 !important;
}
.text-gray-200 {
  color: #e9ecef !important;
}
a.text-gray-200:focus,
a.text-gray-200:hover {
  color: #bdc6cf !important;
}
.text-gray-300 {
  color: #dde1ed !important;
}
a.text-gray-300:focus,
a.text-gray-300:hover {
  color: #abb5d3 !important;
}
.text-gray-400 {
  color: #e0e6f0 !important;
}
a.text-gray-400:focus,
a.text-gray-400:hover {
  color: #acbcd7 !important;
}
.text-gray-500 {
  color: #adb5bd !important;
}
a.text-gray-500:focus,
a.text-gray-500:hover {
  color: #838f9b !important;
}
.text-gray-600 {
  color: #869ab8 !important;
}
a.text-gray-600:focus,
a.text-gray-600:hover {
  color: #597298 !important;
}
.text-gray-700 {
  color: #66799e !important;
}
a.text-gray-700:focus,
a.text-gray-700:hover {
  color: #475570 !important;
}
.text-gray-800 {
  color: #506690 !important;
}
a.text-gray-800:focus,
a.text-gray-800:hover {
  color: #35435f !important;
}
.text-body {
  color: #506690 !important;
}
.text-muted {
  color: #869ab8 !important;
}
.text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 0.0625rem solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.0625rem solid #111;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dde1ed !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #e9ecef;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e9ecef;
  }
}
.datepicker {
  border-radius: 4px;
  direction: ltr;
}
.datepicker-inline {
  width: 220px;
}
.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 20px 22px;
  box-shadow: 0.2rem 0.2rem 0 rgba(233, 236, 239, 0.5);
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #fff;
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker table tr td {
  border-radius: 4px;
}
.datepicker table tr th {
  border-radius: 4px;
  font-weight: 700;
}
.datepicker table tr td,
.datepicker table tr th {
  transition: all 0.2s ease;
  width: 36px;
  height: 36px;
  border: none;
  text-align: center;
  font-size: 0.875rem;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #adb5bd;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #fff;
  cursor: pointer;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #dde1ed;
  cursor: default;
}
.datepicker table tr td.highlighted {
  border-radius: 0;
}
.datepicker table tr td.highlighted.focused {
  background: #005ad4;
}
.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #005ad4;
  color: #e0e6f0;
}
.datepicker table tr td.today {
  background: #bbd8ff;
}
.datepicker table tr td.today.focused {
  background: #fff;
}
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #fff;
  color: #869ab8;
}
.datepicker table tr td.range {
  background: #005ad4;
  color: #fff;
  border-radius: 0;
}
.datepicker table tr td.range.focused {
  background: #0049ab;
}
.datepicker table tr td.range.day.disabled:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #0044a1;
  color: #0871ff;
}
.datepicker table tr td.range.highlighted.focused {
  background: #cbd3da;
}
.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e9ecef;
  color: #dde1ed;
}
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #5e72e4;
  color: #fff;
}
.datepicker table tr td.day.range-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.datepicker table tr td.day.range-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.datepicker table tr td.day.range-start.range-end {
  border-radius: 4px;
}
.datepicker table tr td.day.range:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted,
.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:hover {
  background: #005ad4;
  color: #fff;
}
.datepicker table tr td.active,
.datepicker table tr td.active.highlighted,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:hover {
  background: #005ad4;
  color: #fff;
  box-shadow: none;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #e9ecef;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #dde1ed;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #869ab8;
}
.datepicker .datepicker-switch {
  width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #e9ecef;
}
.datepicker .next.disabled,
.datepicker .prev.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 0.0625rem solid #f3f7fa;
  display: inline-block;
  padding: 0.5rem 0.5rem;
  color: #fff;
  vertical-align: middle;
  border-radius: 0.5rem;
  max-width: 100%;
  cursor: text;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: 0;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #adb5bd;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #adb5bd;
}
.bootstrap-tagsinput .badge {
  padding: 0.5rem 0.5rem;
  margin-right: 0.125rem;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
}
.bootstrap-tagsinput [data-role='remove'] {
  margin-left: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}
.bootstrap-tagsinput [data-role='remove']:after {
  content: 'x';
  padding: 0 2px;
}
.bootstrap-tagsinput [data-role='remove']:hover {
  color: #fff;
}
.headroom {
  will-change: transform;
  background-color: inherit;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .headroom {
    transition: none;
  }
}
.headroom--pinned {
  transform: translateY(0);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
.headroom--not-top {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.headroom--not-top.navbar-theme-primary {
  background-color: #11ab7c;
}
.headroom--not-top.navbar-theme-primary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-primary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-primary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-primary .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-secondary {
  background-color: #005ad4;
}
.headroom--not-top.navbar-theme-secondary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-secondary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-secondary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-secondary .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-success {
  background-color: #00bf9a;
}
.headroom--not-top.navbar-theme-success .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-success .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-success .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-success .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-info {
  background-color: #1e90ff;
}
.headroom--not-top.navbar-theme-info .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-info .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-info .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-info .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-warning {
  background-color: #f5b759;
}
.headroom--not-top.navbar-theme-warning .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-warning .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-warning .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-warning .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-danger {
  background-color: #fa5252;
}
.headroom--not-top.navbar-theme-danger .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-danger .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-danger .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-danger .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-light {
  background-color: #dde1ed;
}
.headroom--not-top.navbar-theme-light .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-light .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-light .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-light .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-dark {
  background-color: #2a354f;
}
.headroom--not-top.navbar-theme-dark .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-dark .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-dark .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-dark .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-tertiary {
  background-color: #de6926;
}
.headroom--not-top.navbar-theme-tertiary .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-tertiary .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-tertiary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-tertiary .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-orange {
  background-color: #ff7f00;
}
.headroom--not-top.navbar-theme-orange .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-orange .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-orange .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-orange .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-white {
  background-color: #fff;
}
.headroom--not-top.navbar-theme-white .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-white .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-white .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-white .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray {
  background-color: #66799e;
}
.headroom--not-top.navbar-theme-gray .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-neutral {
  background-color: #fff;
}
.headroom--not-top.navbar-theme-neutral .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-neutral .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-neutral .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-neutral .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-soft {
  background-color: #f3f7fa;
}
.headroom--not-top.navbar-theme-soft .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-soft .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-soft .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-soft .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-black {
  background-color: #3e4555;
}
.headroom--not-top.navbar-theme-black .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-black .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-black .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-black .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-purple {
  background-color: #8965e0;
}
.headroom--not-top.navbar-theme-purple .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-purple .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-purple .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-purple .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-100 {
  background-color: #f3f7fa;
}
.headroom--not-top.navbar-theme-gray-100 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-100 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-100 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-100 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-200 {
  background-color: #e9ecef;
}
.headroom--not-top.navbar-theme-gray-200 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-200 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-200 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-200 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-300 {
  background-color: #dde1ed;
}
.headroom--not-top.navbar-theme-gray-300 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-300 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-300 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-300 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-400 {
  background-color: #e0e6f0;
}
.headroom--not-top.navbar-theme-gray-400 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-400 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-400 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-400 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-500 {
  background-color: #adb5bd;
}
.headroom--not-top.navbar-theme-gray-500 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-500 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-500 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-500 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-600 {
  background-color: #869ab8;
}
.headroom--not-top.navbar-theme-gray-600 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-600 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-600 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-600 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-700 {
  background-color: #66799e;
}
.headroom--not-top.navbar-theme-gray-700 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-700 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-700 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-700 .nav-link:hover {
  color: #fff;
}
.headroom--not-top.navbar-theme-gray-800 {
  background-color: #506690;
}
.headroom--not-top.navbar-theme-gray-800 .navbar-brand-light {
  display: none;
}
.headroom--not-top.navbar-theme-gray-800 .navbar-brand-dark {
  display: block;
}
.headroom--not-top.navbar-theme-gray-800 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.headroom--not-top.navbar-theme-gray-800 .nav-link:hover {
  color: #fff;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  touch-action: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 5px;
}
.noUi-horizontal .noUi-handle {
  left: -17px;
  top: -10px;
}
.noUi-vertical {
  width: 5px;
  height: 340px;
}
.noUi-vertical .noUi-handle {
  width: 18px;
  height: 34px;
  left: -6px;
  top: -17px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #11ab7c;
}
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #f3f7fa;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #f3f7fa,
    0 3px 6px -3px #e9ecef;
  outline: 0;
}
.noUi-handle:hover {
  cursor: grab;
  cursor: -moz-grab;
}
.noUi-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
}
.noUi-active {
  outline: 0;
}
[disabled] .noUi-connect {
  background: #e0e6f0;
}
[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
  cursor: not-allowed;
}
.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #e0e6f0;
  font-size: 0.75rem;
}
.noUi-value {
  margin-top: 5px;
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #e0e6f0;
  font-size: 0.75rem;
}
.noUi-marker {
  position: absolute;
  background: #e0e6f0;
}
.noUi-marker-sub {
  background: #e0e6f0;
}
.noUi-marker-large {
  background: #e0e6f0;
}
.noUi-pips-horizontal {
  padding: 12px 0;
  height: auto;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 12px;
}
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  font-size: 0.75rem;
  display: block;
  position: absolute;
  background: #fff;
  color: #869ab8;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  padding: 5px 10px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 30px;
}
.noUi-vertical .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-target {
  background: #f3f7fa;
  border-radius: 5px;
  border: 0;
  box-shadow: inset 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  margin: 15px 0;
  cursor: pointer;
}
.noUi-horizontal {
  height: 5px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -10px;
}
.noUi-vertical {
  width: 5px;
}
.noUi-connect {
  background: #11ab7c;
  box-shadow: none;
}
.noUi-handle {
  position: absolute;
  border: 1px solid #e0e6f0;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  background: #fff;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.noUi-vertical .noUi-handle {
  border: 1px solid #dde1ed;
  border-radius: 3px;
  background: #f3f7fa;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #f3f7fa,
    0 3px 6px -3px #e9ecef;
  outline: 0;
}
.noUi-vertical .noUi-handle:hover {
  cursor: grab;
  cursor: -moz-grab;
}
.noUi-vertical .noUi-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
}
.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
  transform: scale(1.2);
}
.input-slider--cyan .noUi-connect {
  background: #2bffc6;
}
.input-slider--cyan.noUi-horizontal .noUi-handle,
.input-slider--cyan.noUi-vertical .noUi-handle {
  background-color: #2bffc6;
}
.input-slider--red .noUi-connect {
  background: #fa5252;
}
.input-slider--red.noUi-horizontal .noUi-handle,
.input-slider--red.noUi-vertical .noUi-handle {
  background-color: #fa5252;
}
.input-slider--green .noUi-connect {
  background: #00bf9a;
}
.input-slider--green.noUi-horizontal .noUi-handle,
.input-slider--green.noUi-vertical .noUi-handle {
  background-color: #00bf9a;
}
.input-slider--yellow .noUi-connect {
  background: #f5b759;
}
.input-slider--yellow.noUi-horizontal .noUi-handle,
.input-slider--yellow.noUi-vertical .noUi-handle {
  background-color: #f5b759;
}
.input-slider--pink .noUi-connect {
  background: #f3a4b5;
}
.input-slider--pink.noUi-horizontal .noUi-handle,
.input-slider--pink.noUi-vertical .noUi-handle {
  background-color: #f3a4b5;
}
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #f3f7fa;
}
[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed;
}
.range-slider-value {
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(42, 53, 79, 0.7);
  color: #fff;
  border-radius: 10px;
  padding: 0.4em 0.8em 0.3em 0.85em;
}
.range-slider-wrapper .upper-info {
  font-weight: 400;
  margin-bottom: 5px;
}
.input-slider-value-output {
  background: #2a354f;
  color: #fff;
  padding: 4px 8px;
  position: relative;
  top: 12px;
  font-size: 11px;
  border-radius: 2px;
}
.input-slider-value-output:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #2a354f;
  border-bottom-color: #333;
  border-width: 4px;
  margin-left: -4px;
}
.input-slider-value-output.left:after {
  left: 10px;
  right: auto;
}
.input-slider-value-output.right:after {
  right: 10px;
  left: auto;
}
.submit-wizard form.slideform-form {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.submit-wizard form.slideform-form .slideform-track {
  overflow: hidden;
  flex: 1;
}
.submit-wizard form.slideform-form .slideform-wrapper {
  transition: all 0.5s ease;
}
.submit-wizard form.slideform-form .slideform-slide {
  display: flex;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: auto;
  box-sizing: border-box;
}
.submit-wizard form.slideform-form .slideform-slide.active {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .submit-wizard form.slideform-form .slideform-slide {
    display: block;
  }
}
.submit-wizard form.slideform-form .slideform-group {
  padding: 30px 15px;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .submit-wizard form.slideform-form .slideform-group {
    margin: auto;
  }
}
.submit-wizard form.slideform-form .slideform-btn {
  font-weight: 600;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #11ab7c;
  border: 0.0625rem solid transparent;
  padding: 0.55rem 0.95rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .submit-wizard form.slideform-form .slideform-btn {
    transition: none;
  }
}
.submit-wizard form.slideform-form .slideform-btn:disabled {
  background: #f3f7fa;
  border: 1px solid #f3f7fa;
  color: #f3f7fa;
}
.submit-wizard form.slideform-form input[type='email'],
.submit-wizard form.slideform-form input[type='number'],
.submit-wizard form.slideform-form input[type='tel'],
.submit-wizard form.slideform-form input[type='text'],
.submit-wizard form.slideform-form textarea {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #f3f7fa;
  outline: 0;
  border-radius: none;
  background: #f3f7fa;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.submit-wizard form.slideform-form input[type='email']:focus,
.submit-wizard form.slideform-form input[type='number']:focus,
.submit-wizard form.slideform-form input[type='tel']:focus,
.submit-wizard form.slideform-form input[type='text']:focus,
.submit-wizard form.slideform-form textarea:focus {
  border-bottom: 1px solid #37ebb4;
}
.submit-wizard form.slideform-form input[type='email'],
.submit-wizard form.slideform-form input[type='number'],
.submit-wizard form.slideform-form input[type='tel'],
.submit-wizard form.slideform-form input[type='text'] {
  height: 50px;
}
.submit-wizard form.slideform-form input[type='email'].small,
.submit-wizard form.slideform-form input[type='number'].small,
.submit-wizard form.slideform-form input[type='tel'].small,
.submit-wizard form.slideform-form input[type='text'].small {
  height: 40px;
  padding: 10px 15px;
  font-size: 16px;
}
.submit-wizard form.slideform-form .options-list label {
  display: block;
  margin: 0;
  padding: 10px 0;
}
.submit-wizard form.slideform-form .options-list input[type='checkbox'],
.submit-wizard form.slideform-form .options-list input[type='radio'] {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  width: auto;
  height: auto;
}
.submit-wizard
  form.slideform-form
  .options-list
  input[type='checkbox']:checked
  + span:after,
.submit-wizard
  form.slideform-form
  .options-list
  input[type='radio']:checked
  + span:after {
  opacity: 1;
  transform: scale(1);
}
.submit-wizard form.slideform-form .options-list input[type='checkbox'] + span,
.submit-wizard form.slideform-form .options-list input[type='radio'] + span {
  display: inline-block;
  position: relative;
  font-weight: 600;
}
.submit-wizard
  form.slideform-form
  .options-list
  input[type='checkbox']
  + span:before,
.submit-wizard
  form.slideform-form
  .options-list
  input[type='radio']
  + span:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  background: #f3f7fa;
  border: 1px solid #f3f7fa;
}
.submit-wizard
  form.slideform-form
  .options-list
  input[type='checkbox']
  + span:after,
.submit-wizard
  form.slideform-form
  .options-list
  input[type='radio']
  + span:after {
  display: inline-block;
  font-size: 22px;
  color: #11ab7c;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  text-align: center;
  line-height: 30px;
  opacity: 0;
  transform: scale(3);
  transition: all 0.3s ease;
}
.submit-wizard
  form.slideform-form
  .options-list
  input[type='radio']
  + span:before {
  border-radius: 50%;
}
.submit-wizard
  form.slideform-form
  .options-list
  input[type='radio']
  + span:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #11ab7c;
  border-radius: 50%;
  top: 7px;
  left: 7px;
}
.submit-wizard form.slideform-form .options-buttons label {
  margin: 0 5px 15px 0;
  display: inline-block;
  text-transform: none;
}
.submit-wizard form.slideform-form .options-buttons input[type='checkbox'],
.submit-wizard form.slideform-form .options-buttons input[type='radio'] {
  position: absolute;
  left: -9999px;
}
.submit-wizard
  form.slideform-form
  .options-buttons
  input[type='checkbox']:checked
  + span,
.submit-wizard
  form.slideform-form
  .options-buttons
  input[type='radio']:checked
  + span {
  background: #11ab7c;
  border: 1px solid #11ab7c;
  color: #fff;
}
.submit-wizard
  form.slideform-form
  .options-buttons
  input[type='checkbox']
  + span,
.submit-wizard form.slideform-form .options-buttons input[type='radio'] + span {
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  background: #f3f7fa;
  border: 1px solid #f3f7fa;
  padding: 5px 20px;
  width: auto;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  font-weight: 600;
}
.submit-wizard form.slideform-form label.error {
  color: #fa5252;
  display: block;
  font-size: 14px;
  margin-top: 5px;
  text-transform: none;
}
.submit-wizard form.slideform-form .slideform-condition {
  display: none;
}
.submit-wizard form.slideform-form .slideform-footer {
  background: #fff;
  padding: 0 15px;
  border-top: 1px solid #f3f7fa;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}
.submit-wizard form.slideform-form .slideform-footer .slideform-btn {
  display: inline-block;
  font-size: 1rem;
}
.submit-wizard form.slideform-form .slideform-progress-bar {
  height: 7px;
  border-radius: 1px;
  max-width: 400px;
  background: #f3f7fa;
  display: inline-block;
  overflow: hidden;
  line-height: 0;
  flex: 1;
  margin-right: 20px;
}
.submit-wizard form.slideform-form .slideform-progress-bar span {
  display: inline-block;
  width: 0%;
  height: 100%;
  background: #11ab7c;
  transition: all 0.5s ease;
}
.submit-wizard form.slideform-form .wizard-brand img {
  width: 70px;
}
.ct-label {
  fill: #66799e;
  color: #66799e;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 600;
}
.ct-chart-bar .ct-label,
.ct-chart-line .ct-label {
  display: block;
  display: flex;
}
.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
  dominant-baseline: central;
}
.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}
.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}
.ct-grid {
  stroke: #e9ecef;
  stroke-width: 2px;
  stroke-dasharray: 2px;
}
.ct-grid-background {
  fill: none;
}
.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}
.ct-line {
  fill: none;
  stroke-width: 4px;
}
.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}
.ct-bar {
  fill: none;
  stroke-width: 10px;
}
.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #fa5252;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
  fill: #fa5252;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #11ab7c;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie {
  fill: #11ab7c;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #f5b759;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie {
  fill: #f5b759;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #005ad4;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie {
  fill: #005ad4;
}
.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
  stroke: #1e90ff;
}
.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie {
  fill: #1e90ff;
}
.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
  stroke: #59922b;
}
.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie {
  fill: #59922b;
}
.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3;
}
.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie {
  fill: #0544d3;
}
.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392;
}
.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie {
  fill: #6b0392;
}
.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f;
}
.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie {
  fill: #f05b4f;
}
.ct-series-j .ct-bar,
.ct-series-j .ct-line,
.ct-series-j .ct-point,
.ct-series-j .ct-slice-donut {
  stroke: #dda458;
}
.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie {
  fill: #dda458;
}
.ct-series-k .ct-bar,
.ct-series-k .ct-line,
.ct-series-k .ct-point,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d;
}
.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie {
  fill: #eacf7d;
}
.ct-series-l .ct-bar,
.ct-series-l .ct-line,
.ct-series-l .ct-point,
.ct-series-l .ct-slice-donut {
  stroke: #86797d;
}
.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie {
  fill: #86797d;
}
.ct-series-m .ct-bar,
.ct-series-m .ct-line,
.ct-series-m .ct-point,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326;
}
.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie {
  fill: #b2c326;
}
.ct-series-n .ct-bar,
.ct-series-n .ct-line,
.ct-series-n .ct-point,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}
.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie {
  fill: #6188e2;
}
.ct-series-o .ct-bar,
.ct-series-o .ct-line,
.ct-series-o .ct-point,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}
.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie {
  fill: #a748ca;
}
.ct-square {
  display: block;
  position: relative;
  width: 100%;
}
.ct-square:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 100%;
}
.ct-square:after {
  content: '';
  display: table;
  clear: both;
}
.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}
.ct-minor-second:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 88.88889%;
}
.ct-major-second:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 83.33333%;
}
.ct-minor-third:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 80%;
}
.ct-major-third:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 75%;
}
.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 66.66667%;
}
.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}
.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}
.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
}
.ct-golden-section:after {
  content: '';
  display: table;
  clear: both;
}
.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 60%;
}
.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}
.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 53.33333%;
}
.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 50%;
}
.ct-octave:after {
  content: '';
  display: table;
  clear: both;
}
.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-tenth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 40%;
}
.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-eleventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}
.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-twelfth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 33.33333%;
}
.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both;
}
.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-double-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 25%;
}
.ct-double-octave:after {
  content: '';
  display: table;
  clear: both;
}
.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
iframe {
  border: 0;
}
figcaption,
figure,
main {
  display: block;
  margin: 0;
}
main {
  overflow: hidden;
}
img {
  max-width: 100%;
}
strong {
  font-weight: 600;
}
button:focus {
  outline: 0;
}
.bg-blue {
  background-color: #5e72e4 !important;
}
a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
  background-color: #324cdd !important;
}
.bg-indigo {
  background-color: #5603ad !important;
}
a.bg-indigo:focus,
a.bg-indigo:hover,
button.bg-indigo:focus,
button.bg-indigo:hover {
  background-color: #3d027b !important;
}
.bg-purple {
  background-color: #8965e0 !important;
}
a.bg-purple:focus,
a.bg-purple:hover,
button.bg-purple:focus,
button.bg-purple:hover {
  background-color: #683bd7 !important;
}
.bg-pink {
  background-color: #f3a4b5 !important;
}
a.bg-pink:focus,
a.bg-pink:hover,
button.bg-pink:focus,
button.bg-pink:hover {
  background-color: #ed7790 !important;
}
.bg-red {
  background-color: #fa5252 !important;
}
a.bg-red:focus,
a.bg-red:hover,
button.bg-red:focus,
button.bg-red:hover {
  background-color: #f92020 !important;
}
.bg-orange {
  background-color: #ff7f00 !important;
}
a.bg-orange:focus,
a.bg-orange:hover,
button.bg-orange:focus,
button.bg-orange:hover {
  background-color: #c60 !important;
}
.bg-yellow {
  background-color: #f5b759 !important;
}
a.bg-yellow:focus,
a.bg-yellow:hover,
button.bg-yellow:focus,
button.bg-yellow:hover {
  background-color: #f2a229 !important;
}
.bg-green {
  background-color: #00bf9a !important;
}
a.bg-green:focus,
a.bg-green:hover,
button.bg-green:focus,
button.bg-green:hover {
  background-color: #008c71 !important;
}
.bg-teal {
  background-color: #1e90ff !important;
}
a.bg-teal:focus,
a.bg-teal:hover,
button.bg-teal:focus,
button.bg-teal:hover {
  background-color: #0077ea !important;
}
.bg-cyan {
  background-color: #2bffc6 !important;
}
a.bg-cyan:focus,
a.bg-cyan:hover,
button.bg-cyan:focus,
button.bg-cyan:hover {
  background-color: #00f7b5 !important;
}
.bg-white {
  background-color: #fff !important;
}
a.bg-white:focus,
a.bg-white:hover,
button.bg-white:focus,
button.bg-white:hover {
  background-color: #e6e6e6 !important;
}
.bg-gray {
  background-color: #869ab8 !important;
}
a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #667fa5 !important;
}
.bg-gray-dark {
  background-color: #506690 !important;
}
a.bg-gray-dark:focus,
a.bg-gray-dark:hover,
button.bg-gray-dark:focus,
button.bg-gray-dark:hover {
  background-color: #3e4f6f !important;
}
.bg-gradient-primary {
  background: linear-gradient(87deg, #11ab7c 0, #08b480 100%) !important;
}
.bg-gradient-secondary {
  background: linear-gradient(87deg, #005ad4 0, #005ad4 100%) !important;
}
.bg-gradient-success {
  background: linear-gradient(87deg, #00bf9a 0, #00bf9a 100%) !important;
}
.bg-gradient-info {
  background: linear-gradient(87deg, #1e90ff 0, #1e90ff 100%) !important;
}
.bg-gradient-warning {
  background: linear-gradient(87deg, #f5b759 0, #feb950 100%) !important;
}
.bg-gradient-danger {
  background: linear-gradient(87deg, #fa5252 0, #ff4d4d 100%) !important;
}
.bg-gradient-light {
  background: linear-gradient(87deg, #dde1ed 0, #dae0f0 100%) !important;
}
.bg-gradient-dark {
  background: linear-gradient(87deg, #2a354f 0, #243355 100%) !important;
}
.bg-gradient-tertiary {
  background: linear-gradient(87deg, #de6926 0, #eb661a 100%) !important;
}
.bg-gradient-orange {
  background: linear-gradient(87deg, #ff7f00 0, #ff7f00 100%) !important;
}
.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, #fff 100%) !important;
}
.bg-gradient-gray {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}
.bg-gradient-neutral {
  background: linear-gradient(87deg, #fff 0, #fff 100%) !important;
}
.bg-gradient-soft {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}
.bg-gradient-black {
  background: linear-gradient(87deg, #3e4555 0, #37425c 100%) !important;
}
.bg-gradient-purple {
  background: linear-gradient(87deg, #8965e0 0, #855ce9 100%) !important;
}
.bg-gradient-gray-100 {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}
.bg-gradient-gray-200 {
  background: linear-gradient(87deg, #e9ecef 0, #e7ecf1 100%) !important;
}
.bg-gradient-gray-300 {
  background: linear-gradient(87deg, #dde1ed 0, #dae0f0 100%) !important;
}
.bg-gradient-gray-400 {
  background: linear-gradient(87deg, #e0e6f0 0, #dee5f2 100%) !important;
}
.bg-gradient-gray-500 {
  background: linear-gradient(87deg, #adb5bd 0, #a6b5c4 100%) !important;
}
.bg-gradient-gray-600 {
  background: linear-gradient(87deg, #869ab8 0, #7c98c2 100%) !important;
}
.bg-gradient-gray-700 {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}
.bg-gradient-gray-800 {
  background: linear-gradient(87deg, #506690 0, #45639b 100%) !important;
}
.bg-gradient-primary {
  background: linear-gradient(87deg, #11ab7c 0, #08b480 100%) !important;
}
.bg-gradient-secondary {
  background: linear-gradient(87deg, #005ad4 0, #005ad4 100%) !important;
}
.bg-gradient-success {
  background: linear-gradient(87deg, #00bf9a 0, #00bf9a 100%) !important;
}
.bg-gradient-info {
  background: linear-gradient(87deg, #1e90ff 0, #1e90ff 100%) !important;
}
.bg-gradient-warning {
  background: linear-gradient(87deg, #f5b759 0, #feb950 100%) !important;
}
.bg-gradient-danger {
  background: linear-gradient(87deg, #fa5252 0, #ff4d4d 100%) !important;
}
.bg-gradient-light {
  background: linear-gradient(87deg, #dde1ed 0, #dae0f0 100%) !important;
}
.bg-gradient-dark {
  background: linear-gradient(87deg, #2a354f 0, #243355 100%) !important;
}
.bg-gradient-tertiary {
  background: linear-gradient(87deg, #de6926 0, #eb661a 100%) !important;
}
.bg-gradient-orange {
  background: linear-gradient(87deg, #ff7f00 0, #ff7f00 100%) !important;
}
.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, #fff 100%) !important;
}
.bg-gradient-gray {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}
.bg-gradient-neutral {
  background: linear-gradient(87deg, #fff 0, #fff 100%) !important;
}
.bg-gradient-soft {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}
.bg-gradient-black {
  background: linear-gradient(87deg, #3e4555 0, #37425c 100%) !important;
}
.bg-gradient-purple {
  background: linear-gradient(87deg, #8965e0 0, #855ce9 100%) !important;
}
.bg-gradient-gray-100 {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}
.bg-gradient-gray-200 {
  background: linear-gradient(87deg, #e9ecef 0, #e7ecf1 100%) !important;
}
.bg-gradient-gray-300 {
  background: linear-gradient(87deg, #dde1ed 0, #dae0f0 100%) !important;
}
.bg-gradient-gray-400 {
  background: linear-gradient(87deg, #e0e6f0 0, #dee5f2 100%) !important;
}
.bg-gradient-gray-500 {
  background: linear-gradient(87deg, #adb5bd 0, #a6b5c4 100%) !important;
}
.bg-gradient-gray-600 {
  background: linear-gradient(87deg, #869ab8 0, #7c98c2 100%) !important;
}
.bg-gradient-gray-700 {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}
.bg-gradient-gray-800 {
  background: linear-gradient(87deg, #506690 0, #45639b 100%) !important;
}
.overlay-primary:before {
  position: absolute;
  content: '';
  background: #11ab7c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-secondary:before {
  position: absolute;
  content: '';
  background: #005ad4;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-success:before {
  position: absolute;
  content: '';
  background: #00bf9a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-info:before {
  position: absolute;
  content: '';
  background: #1e90ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-warning:before {
  position: absolute;
  content: '';
  background: #f5b759;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-danger:before {
  position: absolute;
  content: '';
  background: #fa5252;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-light:before {
  position: absolute;
  content: '';
  background: #dde1ed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-dark:before {
  position: absolute;
  content: '';
  background: #2a354f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-tertiary:before {
  position: absolute;
  content: '';
  background: #de6926;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-orange:before {
  position: absolute;
  content: '';
  background: #ff7f00;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-white:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray:before {
  position: absolute;
  content: '';
  background: #66799e;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-neutral:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-soft:before {
  position: absolute;
  content: '';
  background: #f3f7fa;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-black:before {
  position: absolute;
  content: '';
  background: #3e4555;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-purple:before {
  position: absolute;
  content: '';
  background: #8965e0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-100:before {
  position: absolute;
  content: '';
  background: #f3f7fa;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-200:before {
  position: absolute;
  content: '';
  background: #e9ecef;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-300:before {
  position: absolute;
  content: '';
  background: #dde1ed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-400:before {
  position: absolute;
  content: '';
  background: #e0e6f0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-500:before {
  position: absolute;
  content: '';
  background: #adb5bd;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-600:before {
  position: absolute;
  content: '';
  background: #869ab8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-700:before {
  position: absolute;
  content: '';
  background: #66799e;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.overlay-gray-800:before {
  position: absolute;
  content: '';
  background: #506690;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 0;
  border-radius: inherit;
}
.section-primary {
  background-color: #11ab7c !important;
}
a.section-primary:focus,
a.section-primary:hover,
button.section-primary:focus,
button.section-primary:hover {
  background-color: #0c7d5a !important;
}
.section-secondary {
  background-color: #005ad4 !important;
}
a.section-secondary:focus,
a.section-secondary:hover,
button.section-secondary:focus,
button.section-secondary:hover {
  background-color: #0044a1 !important;
}
.section-success {
  background-color: #00bf9a !important;
}
a.section-success:focus,
a.section-success:hover,
button.section-success:focus,
button.section-success:hover {
  background-color: #008c71 !important;
}
.section-info {
  background-color: #1e90ff !important;
}
a.section-info:focus,
a.section-info:hover,
button.section-info:focus,
button.section-info:hover {
  background-color: #0077ea !important;
}
.section-warning {
  background-color: #f5b759 !important;
}
a.section-warning:focus,
a.section-warning:hover,
button.section-warning:focus,
button.section-warning:hover {
  background-color: #f2a229 !important;
}
.section-danger {
  background-color: #fa5252 !important;
}
a.section-danger:focus,
a.section-danger:hover,
button.section-danger:focus,
button.section-danger:hover {
  background-color: #f92020 !important;
}
.section-light {
  background-color: #dde1ed !important;
}
a.section-light:focus,
a.section-light:hover,
button.section-light:focus,
button.section-light:hover {
  background-color: #bcc4db !important;
}
.section-dark {
  background-color: #2a354f !important;
}
a.section-dark:focus,
a.section-dark:hover,
button.section-dark:focus,
button.section-dark:hover {
  background-color: #181f2e !important;
}
.section-tertiary {
  background-color: #de6926 !important;
}
a.section-tertiary:focus,
a.section-tertiary:hover,
button.section-tertiary:focus,
button.section-tertiary:hover {
  background-color: #b5541c !important;
}
.section-orange {
  background-color: #ff7f00 !important;
}
a.section-orange:focus,
a.section-orange:hover,
button.section-orange:focus,
button.section-orange:hover {
  background-color: #c60 !important;
}
.section-white {
  background-color: #fff !important;
}
a.section-white:focus,
a.section-white:hover,
button.section-white:focus,
button.section-white:hover {
  background-color: #e6e6e6 !important;
}
.section-gray {
  background-color: #66799e !important;
}
a.section-gray:focus,
a.section-gray:hover,
button.section-gray:focus,
button.section-gray:hover {
  background-color: #516180 !important;
}
.section-neutral {
  background-color: #fff !important;
}
a.section-neutral:focus,
a.section-neutral:hover,
button.section-neutral:focus,
button.section-neutral:hover {
  background-color: #e6e6e6 !important;
}
.section-soft {
  background-color: #f3f7fa !important;
}
a.section-soft:focus,
a.section-soft:hover,
button.section-soft:focus,
button.section-soft:hover {
  background-color: #cfdfeb !important;
}
.section-black {
  background-color: #3e4555 !important;
}
a.section-black:focus,
a.section-black:hover,
button.section-black:focus,
button.section-black:hover {
  background-color: #282d38 !important;
}
.section-purple {
  background-color: #8965e0 !important;
}
a.section-purple:focus,
a.section-purple:hover,
button.section-purple:focus,
button.section-purple:hover {
  background-color: #683bd7 !important;
}
.section-gray-100 {
  background-color: #f3f7fa !important;
}
a.section-gray-100:focus,
a.section-gray-100:hover,
button.section-gray-100:focus,
button.section-gray-100:hover {
  background-color: #cfdfeb !important;
}
.section-gray-200 {
  background-color: #e9ecef !important;
}
a.section-gray-200:focus,
a.section-gray-200:hover,
button.section-gray-200:focus,
button.section-gray-200:hover {
  background-color: #cbd3da !important;
}
.section-gray-300 {
  background-color: #dde1ed !important;
}
a.section-gray-300:focus,
a.section-gray-300:hover,
button.section-gray-300:focus,
button.section-gray-300:hover {
  background-color: #bcc4db !important;
}
.section-gray-400 {
  background-color: #e0e6f0 !important;
}
a.section-gray-400:focus,
a.section-gray-400:hover,
button.section-gray-400:focus,
button.section-gray-400:hover {
  background-color: #becadf !important;
}
.section-gray-500 {
  background-color: #adb5bd !important;
}
a.section-gray-500:focus,
a.section-gray-500:hover,
button.section-gray-500:focus,
button.section-gray-500:hover {
  background-color: #919ca6 !important;
}
.section-gray-600 {
  background-color: #869ab8 !important;
}
a.section-gray-600:focus,
a.section-gray-600:hover,
button.section-gray-600:focus,
button.section-gray-600:hover {
  background-color: #667fa5 !important;
}
.section-gray-700 {
  background-color: #66799e !important;
}
a.section-gray-700:focus,
a.section-gray-700:hover,
button.section-gray-700:focus,
button.section-gray-700:hover {
  background-color: #516180 !important;
}
.section-gray-800 {
  background-color: #506690 !important;
}
a.section-gray-800:focus,
a.section-gray-800:hover,
button.section-gray-800:focus,
button.section-gray-800:hover {
  background-color: #3e4f6f !important;
}
.bg-image {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.form-bg-image {
  background-repeat: no-repeat;
  background-position: top center;
}
@media (max-width: 1199.98px) {
  .form-bg-image {
    background-image: none !important;
  }
}
.bg-img-holder {
  position: absolute;
  height: 100%;
  min-height: 20rem;
  background-repeat: no-repeat;
  z-index: -1;
}
.floating-xs {
  animation: floating-xs 3s ease infinite;
  will-change: transform;
}
.floating-xs:hover {
  animation-play-state: paused;
}
@media (min-width: 576px) {
  .floating-sm {
    animation: floating-sm 3s ease infinite;
    will-change: transform;
  }
  .floating-sm:hover {
    animation-play-state: paused;
  }
}
@media (min-width: 768px) {
  .floating-md {
    animation: floating-md 3s ease infinite;
    will-change: transform;
  }
  .floating-md:hover {
    animation-play-state: paused;
  }
}
@media (min-width: 992px) {
  .floating-lg {
    animation: floating-lg 3s ease infinite;
    will-change: transform;
  }
  .floating-lg:hover {
    animation-play-state: paused;
  }
}
@media (min-width: 1200px) {
  .floating-xl {
    animation: floating-xl 3s ease infinite;
    will-change: transform;
  }
  .floating-xl:hover {
    animation-play-state: paused;
  }
}
@keyframes floating-lg {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes floating-md {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes floating-sm {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
.overflow-visible {
  overflow: visible !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 0.1 !important;
}
.opacity-2 {
  opacity: 0.2 !important;
}
.opacity-3 {
  opacity: 0.3 !important;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.opacity-9 {
  opacity: 0.9 !important;
}
.fill-opacity-0 {
  fill-opacity: 0 !important;
}
.fill-opacity-1 {
  fill-opacity: 0.1 !important;
}
.fill-opacity-2 {
  fill-opacity: 0.2 !important;
}
.fill-opacity-3 {
  fill-opacity: 0.3 !important;
}
.fill-opacity-4 {
  fill-opacity: 0.4 !important;
}
.fill-opacity-5 {
  fill-opacity: 0.5 !important;
}
.fill-opacity-6 {
  fill-opacity: 0.6 !important;
}
.fill-opacity-7 {
  fill-opacity: 0.7 !important;
}
.fill-opacity-8 {
  fill-opacity: 0.8 !important;
}
.fill-opacity-9 {
  fill-opacity: 0.9 !important;
}
.z-0 {
  position: relative;
  z-index: 0 !important;
}
.z-1 {
  position: relative;
  z-index: 1 !important;
}
.z-2 {
  position: relative;
  z-index: 2 !important;
}
.z-3 {
  position: relative;
  z-index: 3 !important;
}
.z-4 {
  position: relative;
  z-index: 4 !important;
}
.z-5 {
  position: relative;
  z-index: 5 !important;
}
.z-6 {
  position: relative;
  z-index: 6 !important;
}
.z-7 {
  position: relative;
  z-index: 7 !important;
}
.z-8 {
  position: relative;
  z-index: 8 !important;
}
.z-9 {
  position: relative;
  z-index: 9 !important;
}
.bw-md {
  border-width: 0.125rem !important;
}
.bw-lg {
  border-width: 0.25rem !important;
}
.bw-xl {
  border-width: 0.375rem !important;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.top-1 {
  top: 0.25rem;
}
.right-1 {
  right: 0.25rem;
}
.bottom-1 {
  bottom: 0.25rem;
}
.left-1 {
  left: 0.25rem;
}
.top-2 {
  top: 0.7rem;
}
.right-2 {
  right: 0.7rem;
}
.bottom-2 {
  bottom: 0.7rem;
}
.left-2 {
  left: 0.7rem;
}
.top-3 {
  top: 1rem;
}
.right-3 {
  right: 1rem;
}
.bottom-3 {
  bottom: 1rem;
}
.left-3 {
  left: 1rem;
}
.top-4 {
  top: 1.5rem;
}
.right-4 {
  right: 1.5rem;
}
.bottom-4 {
  bottom: 1.5rem;
}
.left-4 {
  left: 1.5rem;
}
.top-5 {
  top: 3rem;
}
.right-5 {
  right: 3rem;
}
.bottom-5 {
  bottom: 3rem;
}
.left-5 {
  left: 3rem;
}
.top-6 {
  top: 5rem;
}
.right-6 {
  right: 5rem;
}
.bottom-6 {
  bottom: 5rem;
}
.left-6 {
  left: 5rem;
}
.top-7 {
  top: 7rem;
}
.right-7 {
  right: 7rem;
}
.bottom-7 {
  bottom: 7rem;
}
.left-7 {
  left: 7rem;
}
.top-8 {
  top: 10rem;
}
.right-8 {
  right: 10rem;
}
.bottom-8 {
  bottom: 10rem;
}
.left-8 {
  left: 10rem;
}
.top-9 {
  top: 12rem;
}
.right-9 {
  right: 12rem;
}
.bottom-9 {
  bottom: 12rem;
}
.left-9 {
  left: 12rem;
}
.top-10 {
  top: 14rem;
}
.right-10 {
  right: 14rem;
}
.bottom-10 {
  bottom: 14rem;
}
.left-10 {
  left: 14rem;
}
.top-11 {
  top: 18rem;
}
.right-11 {
  right: 18rem;
}
.bottom-11 {
  bottom: 18rem;
}
.left-11 {
  left: 18rem;
}
.top-12 {
  top: 22rem;
}
.right-12 {
  right: 22rem;
}
.bottom-12 {
  bottom: 22rem;
}
.left-12 {
  left: 22rem;
}
.top-sm {
  top: 1rem;
}
.right-sm {
  right: 1rem;
}
.bottom-sm {
  bottom: 1rem;
}
.left-sm {
  left: 1rem;
}
.top-md {
  top: 2rem;
}
.right-md {
  right: 2rem;
}
.bottom-md {
  bottom: 2rem;
}
.left-md {
  left: 2rem;
}
.top-lg {
  top: 4rem;
}
.right-lg {
  right: 4rem;
}
.bottom-lg {
  bottom: 4rem;
}
.left-lg {
  left: 4rem;
}
.top-xl {
  top: 8rem;
}
.right-xl {
  right: 8rem;
}
.bottom-xl {
  bottom: 8rem;
}
.left-xl {
  left: 8rem;
}
.center-horizontal-absolute,
.center-vertical-absolute {
  position: absolute;
}
.center-vertical-absolute {
  top: 50%;
  transform: translateY(-50%);
}
.center-horizontal-absolute {
  left: 50%;
  transform: translateX(-50%);
}
.h-100vh {
  height: 100vh !important;
}
.fh-50 {
  height: 50px !important;
}
.fh-100 {
  height: 100px !important;
}
.fh-150 {
  height: 150px !important;
}
.fh-200 {
  height: 200px !important;
}
.fh-250 {
  height: 250px !important;
}
.fh-300 {
  height: 300px !important;
}
.fh-350 {
  height: 350px !important;
}
.fh-400 {
  height: 400px !important;
}
.fh-450 {
  height: 450px !important;
}
.fh-500 {
  height: 500px !important;
}
.fh-550 {
  height: 550px !important;
}
.fh-600 {
  height: 600px !important;
}
.fh-650 {
  height: 650px !important;
}
.fh-700 {
  height: 700px !important;
}
.fh-750 {
  height: 750px !important;
}
.fh-800 {
  height: 800px !important;
}
.fh-850 {
  height: 850px !important;
}
.fh-900 {
  height: 900px !important;
}
.fh-950 {
  height: 950px !important;
}
.fh-1000 {
  height: 1000px !important;
}
.fh-1050 {
  height: 1050px !important;
}
.fh-1100 {
  height: 1100px !important;
}
.fh-1150 {
  height: 1150px !important;
}
.fh-1200 {
  height: 1200px !important;
}
.fh-1250 {
  height: 1250px !important;
}
.fh-1300 {
  height: 1300px !important;
}
.fh-1350 {
  height: 1350px !important;
}
.fh-1400 {
  height: 1400px !important;
}
.fh-1450 {
  height: 1450px !important;
}
.fh-1500 {
  height: 1500px !important;
}
.fh-1550 {
  height: 1550px !important;
}
.fh-1600 {
  height: 1600px !important;
}
.fh-1650 {
  height: 1650px !important;
}
.fh-1700 {
  height: 1700px !important;
}
.fh-1750 {
  height: 1750px !important;
}
.fh-1800 {
  height: 1800px !important;
}
.fh-1850 {
  height: 1850px !important;
}
.fh-1900 {
  height: 1900px !important;
}
.fh-1950 {
  height: 1950px !important;
}
.fw-50 {
  width: 50px !important;
}
.fw-100 {
  width: 100px !important;
}
.fw-150 {
  width: 150px !important;
}
.fw-200 {
  width: 200px !important;
}
.fw-250 {
  width: 250px !important;
}
.fw-300 {
  width: 300px !important;
}
.fw-350 {
  width: 350px !important;
}
.fw-400 {
  width: 400px !important;
}
.fw-450 {
  width: 450px !important;
}
.fw-500 {
  width: 500px !important;
}
.fw-550 {
  width: 550px !important;
}
.fw-600 {
  width: 600px !important;
}
.fw-650 {
  width: 650px !important;
}
.fw-700 {
  width: 700px !important;
}
.fw-750 {
  width: 750px !important;
}
.fw-800 {
  width: 800px !important;
}
.fw-850 {
  width: 850px !important;
}
.fw-900 {
  width: 900px !important;
}
.fw-950 {
  width: 950px !important;
}
.fw-1000 {
  width: 1000px !important;
}
.fw-1050 {
  width: 1050px !important;
}
.fw-1100 {
  width: 1100px !important;
}
.fw-1150 {
  width: 1150px !important;
}
.fw-1200 {
  width: 1200px !important;
}
.fw-1250 {
  width: 1250px !important;
}
.fw-1300 {
  width: 1300px !important;
}
.fw-1350 {
  width: 1350px !important;
}
.fw-1400 {
  width: 1400px !important;
}
.fw-1450 {
  width: 1450px !important;
}
.fw-1500 {
  width: 1500px !important;
}
.fw-1550 {
  width: 1550px !important;
}
.fw-1600 {
  width: 1600px !important;
}
.fw-1650 {
  width: 1650px !important;
}
.fw-1700 {
  width: 1700px !important;
}
.fw-1750 {
  width: 1750px !important;
}
.fw-1800 {
  width: 1800px !important;
}
.fw-1850 {
  width: 1850px !important;
}
.fw-1900 {
  width: 1900px !important;
}
.fw-1950 {
  width: 1950px !important;
}
.fmh-50 {
  min-height: 50px !important;
}
.fmh-100 {
  min-height: 100px !important;
}
.fmh-150 {
  min-height: 150px !important;
}
.fmh-200 {
  min-height: 200px !important;
}
.fmh-250 {
  min-height: 250px !important;
}
.fmh-300 {
  min-height: 300px !important;
}
.fmh-350 {
  min-height: 350px !important;
}
.fmh-400 {
  min-height: 400px !important;
}
.fmh-450 {
  min-height: 450px !important;
}
.fmh-500 {
  min-height: 500px !important;
}
.fmh-550 {
  min-height: 550px !important;
}
.fmh-600 {
  min-height: 600px !important;
}
.fmh-650 {
  min-height: 650px !important;
}
.fmh-700 {
  min-height: 700px !important;
}
.fmh-750 {
  min-height: 750px !important;
}
.fmh-800 {
  min-height: 800px !important;
}
.fmh-850 {
  min-height: 850px !important;
}
.fmh-900 {
  min-height: 900px !important;
}
.fmh-950 {
  min-height: 950px !important;
}
.fmh-1000 {
  min-height: 1000px !important;
}
.fmh-1050 {
  min-height: 1050px !important;
}
.fmh-1100 {
  min-height: 1100px !important;
}
.fmh-1150 {
  min-height: 1150px !important;
}
.fmh-1200 {
  min-height: 1200px !important;
}
.fmh-1250 {
  min-height: 1250px !important;
}
.fmh-1300 {
  min-height: 1300px !important;
}
.fmh-1350 {
  min-height: 1350px !important;
}
.fmh-1400 {
  min-height: 1400px !important;
}
.fmh-1450 {
  min-height: 1450px !important;
}
.fmh-1500 {
  min-height: 1500px !important;
}
.fmh-1550 {
  min-height: 1550px !important;
}
.fmh-1600 {
  min-height: 1600px !important;
}
.fmh-1650 {
  min-height: 1650px !important;
}
.fmh-1700 {
  min-height: 1700px !important;
}
.fmh-1750 {
  min-height: 1750px !important;
}
.fmh-1800 {
  min-height: 1800px !important;
}
.fmh-1850 {
  min-height: 1850px !important;
}
.fmh-1900 {
  min-height: 1900px !important;
}
.fmh-1950 {
  min-height: 1950px !important;
}
.fmw-50 {
  min-width: 50px !important;
}
.fmw-100 {
  min-width: 100px !important;
}
.fmw-150 {
  min-width: 150px !important;
}
.fmw-200 {
  min-width: 200px !important;
}
.fmw-250 {
  min-width: 250px !important;
}
.fmw-300 {
  min-width: 300px !important;
}
.fmw-350 {
  min-width: 350px !important;
}
.fmw-400 {
  min-width: 400px !important;
}
.fmw-450 {
  min-width: 450px !important;
}
.fmw-500 {
  min-width: 500px !important;
}
.fmw-550 {
  min-width: 550px !important;
}
.fmw-600 {
  min-width: 600px !important;
}
.fmw-650 {
  min-width: 650px !important;
}
.fmw-700 {
  min-width: 700px !important;
}
.fmw-750 {
  min-width: 750px !important;
}
.fmw-800 {
  min-width: 800px !important;
}
.fmw-850 {
  min-width: 850px !important;
}
.fmw-900 {
  min-width: 900px !important;
}
.fmw-950 {
  min-width: 950px !important;
}
.fmw-1000 {
  min-width: 1000px !important;
}
.fmw-1050 {
  min-width: 1050px !important;
}
.fmw-1100 {
  min-width: 1100px !important;
}
.fmw-1150 {
  min-width: 1150px !important;
}
.fmw-1200 {
  min-width: 1200px !important;
}
.fmw-1250 {
  min-width: 1250px !important;
}
.fmw-1300 {
  min-width: 1300px !important;
}
.fmw-1350 {
  min-width: 1350px !important;
}
.fmw-1400 {
  min-width: 1400px !important;
}
.fmw-1450 {
  min-width: 1450px !important;
}
.fmw-1500 {
  min-width: 1500px !important;
}
.fmw-1550 {
  min-width: 1550px !important;
}
.fmw-1600 {
  min-width: 1600px !important;
}
.fmw-1650 {
  min-width: 1650px !important;
}
.fmw-1700 {
  min-width: 1700px !important;
}
.fmw-1750 {
  min-width: 1750px !important;
}
.fmw-1800 {
  min-width: 1800px !important;
}
.fmw-1850 {
  min-width: 1850px !important;
}
.fmw-1900 {
  min-width: 1900px !important;
}
.fmw-1950 {
  min-width: 1950px !important;
}
.fmxh-50 {
  max-height: 50px !important;
}
.fmxh-100 {
  max-height: 100px !important;
}
.fmxh-150 {
  max-height: 150px !important;
}
.fmxh-200 {
  max-height: 200px !important;
}
.fmxh-250 {
  max-height: 250px !important;
}
.fmxh-300 {
  max-height: 300px !important;
}
.fmxh-350 {
  max-height: 350px !important;
}
.fmxh-400 {
  max-height: 400px !important;
}
.fmxh-450 {
  max-height: 450px !important;
}
.fmxh-500 {
  max-height: 500px !important;
}
.fmxh-550 {
  max-height: 550px !important;
}
.fmxh-600 {
  max-height: 600px !important;
}
.fmxh-650 {
  max-height: 650px !important;
}
.fmxh-700 {
  max-height: 700px !important;
}
.fmxh-750 {
  max-height: 750px !important;
}
.fmxh-800 {
  max-height: 800px !important;
}
.fmxh-850 {
  max-height: 850px !important;
}
.fmxh-900 {
  max-height: 900px !important;
}
.fmxh-950 {
  max-height: 950px !important;
}
.fmxh-1000 {
  max-height: 1000px !important;
}
.fmxh-1050 {
  max-height: 1050px !important;
}
.fmxh-1100 {
  max-height: 1100px !important;
}
.fmxh-1150 {
  max-height: 1150px !important;
}
.fmxh-1200 {
  max-height: 1200px !important;
}
.fmxh-1250 {
  max-height: 1250px !important;
}
.fmxh-1300 {
  max-height: 1300px !important;
}
.fmxh-1350 {
  max-height: 1350px !important;
}
.fmxh-1400 {
  max-height: 1400px !important;
}
.fmxh-1450 {
  max-height: 1450px !important;
}
.fmxh-1500 {
  max-height: 1500px !important;
}
.fmxh-1550 {
  max-height: 1550px !important;
}
.fmxh-1600 {
  max-height: 1600px !important;
}
.fmxh-1650 {
  max-height: 1650px !important;
}
.fmxh-1700 {
  max-height: 1700px !important;
}
.fmxh-1750 {
  max-height: 1750px !important;
}
.fmxh-1800 {
  max-height: 1800px !important;
}
.fmxh-1850 {
  max-height: 1850px !important;
}
.fmxh-1900 {
  max-height: 1900px !important;
}
.fmxh-1950 {
  max-height: 1950px !important;
}
.fmxw-50 {
  max-width: 50px !important;
}
.fmxw-100 {
  max-width: 100px !important;
}
.fmxw-150 {
  max-width: 150px !important;
}
.fmxw-200 {
  max-width: 200px !important;
}
.fmxw-250 {
  max-width: 250px !important;
}
.fmxw-300 {
  max-width: 300px !important;
}
.fmxw-350 {
  max-width: 350px !important;
}
.fmxw-400 {
  max-width: 400px !important;
}
.fmxw-450 {
  max-width: 450px !important;
}
.fmxw-500 {
  max-width: 500px !important;
}
.fmxw-550 {
  max-width: 550px !important;
}
.fmxw-600 {
  max-width: 600px !important;
}
.fmxw-650 {
  max-width: 650px !important;
}
.fmxw-700 {
  max-width: 700px !important;
}
.fmxw-750 {
  max-width: 750px !important;
}
.fmxw-800 {
  max-width: 800px !important;
}
.fmxw-850 {
  max-width: 850px !important;
}
.fmxw-900 {
  max-width: 900px !important;
}
.fmxw-950 {
  max-width: 950px !important;
}
.fmxw-1000 {
  max-width: 1000px !important;
}
.fmxw-1050 {
  max-width: 1050px !important;
}
.fmxw-1100 {
  max-width: 1100px !important;
}
.fmxw-1150 {
  max-width: 1150px !important;
}
.fmxw-1200 {
  max-width: 1200px !important;
}
.fmxw-1250 {
  max-width: 1250px !important;
}
.fmxw-1300 {
  max-width: 1300px !important;
}
.fmxw-1350 {
  max-width: 1350px !important;
}
.fmxw-1400 {
  max-width: 1400px !important;
}
.fmxw-1450 {
  max-width: 1450px !important;
}
.fmxw-1500 {
  max-width: 1500px !important;
}
.fmxw-1550 {
  max-width: 1550px !important;
}
.fmxw-1600 {
  max-width: 1600px !important;
}
.fmxw-1650 {
  max-width: 1650px !important;
}
.fmxw-1700 {
  max-width: 1700px !important;
}
.fmxw-1750 {
  max-width: 1750px !important;
}
.fmxw-1800 {
  max-width: 1800px !important;
}
.fmxw-1850 {
  max-width: 1850px !important;
}
.fmxw-1900 {
  max-width: 1900px !important;
}
.fmxw-1950 {
  max-width: 1950px !important;
}
.row.row-grid > [class*='col-'] + [class*='col-'] {
  margin-top: 3rem;
}
.row.row-grid > [class*='col-xs-'] + [class*='col-xs-'] {
  margin-top: 0;
}
@media (min-width: 576px) {
  .row.row-grid > [class*='col-sm-'] + [class*='col-sm-'] {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .row.row-grid > [class*='col-md-'] + [class*='col-md-'] {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .row.row-grid > [class*='col-lg-'] + [class*='col-lg-'] {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .row.row-grid > [class*='col-xl-'] + [class*='col-xl-'] {
    margin-top: 0;
  }
}
.row-grid + .row-grid {
  margin-top: 3rem;
}
@media (min-width: 992px) {
  [class*='mb--'],
  [class*='ml--'],
  [class*='mr--'],
  [class*='mt--'] {
    position: relative;
    z-index: 5;
  }
  .mt--50 {
    margin-top: -50px !important;
  }
  .mr--50 {
    margin-right: -50px !important;
  }
  .mb--50 {
    margin-bottom: -50px !important;
  }
  .ml--50 {
    margin-left: -50px !important;
  }
  .mt--100 {
    margin-top: -100px !important;
  }
  .mr--100 {
    margin-right: -100px !important;
  }
  .mb--100 {
    margin-bottom: -100px !important;
  }
  .ml--100 {
    margin-left: -100px !important;
  }
  .mt--150 {
    margin-top: -150px !important;
  }
  .mr--150 {
    margin-right: -150px !important;
  }
  .mb--150 {
    margin-bottom: -150px !important;
  }
  .ml--150 {
    margin-left: -150px !important;
  }
  .mt--200 {
    margin-top: -200px !important;
  }
  .mr--200 {
    margin-right: -200px !important;
  }
  .mb--200 {
    margin-bottom: -200px !important;
  }
  .ml--200 {
    margin-left: -200px !important;
  }
  .mt--250 {
    margin-top: -250px !important;
  }
  .mr--250 {
    margin-right: -250px !important;
  }
  .mb--250 {
    margin-bottom: -250px !important;
  }
  .ml--250 {
    margin-left: -250px !important;
  }
  .mt--300 {
    margin-top: -300px !important;
  }
  .mr--300 {
    margin-right: -300px !important;
  }
  .mb--300 {
    margin-bottom: -300px !important;
  }
  .ml--300 {
    margin-left: -300px !important;
  }
  .mt--350 {
    margin-top: -350px !important;
  }
  .mr--350 {
    margin-right: -350px !important;
  }
  .mb--350 {
    margin-bottom: -350px !important;
  }
  .ml--350 {
    margin-left: -350px !important;
  }
  .mt--400 {
    margin-top: -400px !important;
  }
  .mr--400 {
    margin-right: -400px !important;
  }
  .mb--400 {
    margin-bottom: -400px !important;
  }
  .ml--400 {
    margin-left: -400px !important;
  }
  .mt--450 {
    margin-top: -450px !important;
  }
  .mr--450 {
    margin-right: -450px !important;
  }
  .mb--450 {
    margin-bottom: -450px !important;
  }
  .ml--450 {
    margin-left: -450px !important;
  }
  .mt--500 {
    margin-top: -500px !important;
  }
  .mr--500 {
    margin-right: -500px !important;
  }
  .mb--500 {
    margin-bottom: -500px !important;
  }
  .ml--500 {
    margin-left: -500px !important;
  }
  .mt--550 {
    margin-top: -550px !important;
  }
  .mr--550 {
    margin-right: -550px !important;
  }
  .mb--550 {
    margin-bottom: -550px !important;
  }
  .ml--550 {
    margin-left: -550px !important;
  }
  .mt--600 {
    margin-top: -600px !important;
  }
  .mr--600 {
    margin-right: -600px !important;
  }
  .mb--600 {
    margin-bottom: -600px !important;
  }
  .ml--600 {
    margin-left: -600px !important;
  }
  .mt-50 {
    margin-top: 50px !important;
  }
  .mr-50 {
    margin-right: 50px !important;
  }
  .mb-50 {
    margin-bottom: 50px !important;
  }
  .ml-50 {
    margin-left: 50px !important;
  }
  .mt-100 {
    margin-top: 100px !important;
  }
  .mr-100 {
    margin-right: 100px !important;
  }
  .mb-100 {
    margin-bottom: 100px !important;
  }
  .ml-100 {
    margin-left: 100px !important;
  }
  .mt-150 {
    margin-top: 150px !important;
  }
  .mr-150 {
    margin-right: 150px !important;
  }
  .mb-150 {
    margin-bottom: 150px !important;
  }
  .ml-150 {
    margin-left: 150px !important;
  }
  .mt-200 {
    margin-top: 200px !important;
  }
  .mr-200 {
    margin-right: 200px !important;
  }
  .mb-200 {
    margin-bottom: 200px !important;
  }
  .ml-200 {
    margin-left: 200px !important;
  }
  .mt-250 {
    margin-top: 250px !important;
  }
  .mr-250 {
    margin-right: 250px !important;
  }
  .mb-250 {
    margin-bottom: 250px !important;
  }
  .ml-250 {
    margin-left: 250px !important;
  }
  .mt-300 {
    margin-top: 300px !important;
  }
  .mr-300 {
    margin-right: 300px !important;
  }
  .mb-300 {
    margin-bottom: 300px !important;
  }
  .ml-300 {
    margin-left: 300px !important;
  }
  .mt-350 {
    margin-top: 350px !important;
  }
  .mr-350 {
    margin-right: 350px !important;
  }
  .mb-350 {
    margin-bottom: 350px !important;
  }
  .ml-350 {
    margin-left: 350px !important;
  }
  .mt-400 {
    margin-top: 400px !important;
  }
  .mr-400 {
    margin-right: 400px !important;
  }
  .mb-400 {
    margin-bottom: 400px !important;
  }
  .ml-400 {
    margin-left: 400px !important;
  }
  .mt-450 {
    margin-top: 450px !important;
  }
  .mr-450 {
    margin-right: 450px !important;
  }
  .mb-450 {
    margin-bottom: 450px !important;
  }
  .ml-450 {
    margin-left: 450px !important;
  }
  .mt-500 {
    margin-top: 500px !important;
  }
  .mr-500 {
    margin-right: 500px !important;
  }
  .mb-500 {
    margin-bottom: 500px !important;
  }
  .ml-500 {
    margin-left: 500px !important;
  }
  .mt-550 {
    margin-top: 550px !important;
  }
  .mr-550 {
    margin-right: 550px !important;
  }
  .mb-550 {
    margin-bottom: 550px !important;
  }
  .ml-550 {
    margin-left: 550px !important;
  }
  .mt-600 {
    margin-top: 600px !important;
  }
  .mr-600 {
    margin-right: 600px !important;
  }
  .mb-600 {
    margin-bottom: 600px !important;
  }
  .ml-600 {
    margin-left: 600px !important;
  }
  .pt-50 {
    padding-top: 50px !important;
  }
  .pr-50 {
    padding-right: 50px !important;
  }
  .pb-50 {
    padding-bottom: 50px !important;
  }
  .pl-50 {
    padding-left: 50px !important;
  }
  .pt-100 {
    padding-top: 100px !important;
  }
  .pr-100 {
    padding-right: 100px !important;
  }
  .pb-100 {
    padding-bottom: 100px !important;
  }
  .pl-100 {
    padding-left: 100px !important;
  }
  .pt-150 {
    padding-top: 150px !important;
  }
  .pr-150 {
    padding-right: 150px !important;
  }
  .pb-150 {
    padding-bottom: 150px !important;
  }
  .pl-150 {
    padding-left: 150px !important;
  }
  .pt-200 {
    padding-top: 200px !important;
  }
  .pr-200 {
    padding-right: 200px !important;
  }
  .pb-200 {
    padding-bottom: 200px !important;
  }
  .pl-200 {
    padding-left: 200px !important;
  }
  .pt-250 {
    padding-top: 250px !important;
  }
  .pr-250 {
    padding-right: 250px !important;
  }
  .pb-250 {
    padding-bottom: 250px !important;
  }
  .pl-250 {
    padding-left: 250px !important;
  }
  .pt-300 {
    padding-top: 300px !important;
  }
  .pr-300 {
    padding-right: 300px !important;
  }
  .pb-300 {
    padding-bottom: 300px !important;
  }
  .pl-300 {
    padding-left: 300px !important;
  }
  .pt-350 {
    padding-top: 350px !important;
  }
  .pr-350 {
    padding-right: 350px !important;
  }
  .pb-350 {
    padding-bottom: 350px !important;
  }
  .pl-350 {
    padding-left: 350px !important;
  }
  .pt-400 {
    padding-top: 400px !important;
  }
  .pr-400 {
    padding-right: 400px !important;
  }
  .pb-400 {
    padding-bottom: 400px !important;
  }
  .pl-400 {
    padding-left: 400px !important;
  }
  .pt-450 {
    padding-top: 450px !important;
  }
  .pr-450 {
    padding-right: 450px !important;
  }
  .pb-450 {
    padding-bottom: 450px !important;
  }
  .pl-450 {
    padding-left: 450px !important;
  }
  .pt-500 {
    padding-top: 500px !important;
  }
  .pr-500 {
    padding-right: 500px !important;
  }
  .pb-500 {
    padding-bottom: 500px !important;
  }
  .pl-500 {
    padding-left: 500px !important;
  }
  .pt-550 {
    padding-top: 550px !important;
  }
  .pr-550 {
    padding-right: 550px !important;
  }
  .pb-550 {
    padding-bottom: 550px !important;
  }
  .pl-550 {
    padding-left: 550px !important;
  }
  .pt-600 {
    padding-top: 600px !important;
  }
  .pr-600 {
    padding-right: 600px !important;
  }
  .pb-600 {
    padding-bottom: 600px !important;
  }
  .pl-600 {
    padding-left: 600px !important;
  }
}
[class*='shadow'] {
  transition: all 0.2s ease;
}
.shadow-pixel {
  box-shadow: 0.2rem 0.2rem 0 rgba(233, 236, 239, 0.5) !important;
}
.shadow-soft {
  box-shadow: 0 0.25rem 1.875rem rgba(42, 53, 79, 0.05) !important;
}
.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(42, 53, 79, 0.12) !important;
}
.shadow-sm-hover:hover {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05) !important;
}
.shadow-lg-hover:hover {
  box-shadow: 0 1rem 3rem rgba(42, 53, 79, 0.125) !important;
}
.shadow-none-hover:hover {
  box-shadow: none !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-through {
  text-decoration: line-through !important;
}
.display-1-xs {
  font-size: 5rem;
}
.display-2-xs {
  font-size: 3.3rem;
}
.display-3-xs {
  font-size: 2.5rem;
}
.display-4-xs {
  font-size: 1.875rem;
}
@media (min-width: 576px) {
  .display-1-sm {
    font-size: 5rem;
  }
  .display-2-sm {
    font-size: 3.3rem;
  }
  .display-3-sm {
    font-size: 2.5rem;
  }
  .display-4-sm {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  .display-1-md {
    font-size: 5rem;
  }
  .display-2-md {
    font-size: 3.3rem;
  }
  .display-3-md {
    font-size: 2.5rem;
  }
  .display-4-md {
    font-size: 1.875rem;
  }
}
@media (min-width: 992px) {
  .display-1-lg {
    font-size: 5rem;
  }
  .display-2-lg {
    font-size: 3.3rem;
  }
  .display-3-lg {
    font-size: 2.5rem;
  }
  .display-4-lg {
    font-size: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .display-1-xl {
    font-size: 5rem;
  }
  .display-2-xl {
    font-size: 3.3rem;
  }
  .display-3-xl {
    font-size: 2.5rem;
  }
  .display-4-xl {
    font-size: 1.875rem;
  }
}
.lh-100 {
  line-height: 1;
}
.lh-110 {
  line-height: 1.1;
}
.lh-120 {
  line-height: 1.2;
}
.lh-130 {
  line-height: 1.3;
}
.lh-140 {
  line-height: 1.4;
}
.lh-150 {
  line-height: 1.5;
}
.lh-160 {
  line-height: 1.6;
}
.lh-170 {
  line-height: 1.7;
}
.lh-180 {
  line-height: 1.8;
}
.lh-190 {
  line-height: 1.9;
}
.lh-200 {
  line-height: 2;
}
.lh-210 {
  line-height: 2.1;
}
.lh-220 {
  line-height: 2.2;
}
.lh-230 {
  line-height: 2.3;
}
.lh-240 {
  line-height: 2.4;
}
.lh-250 {
  line-height: 2.5;
}
.lh-260 {
  line-height: 2.6;
}
.lh-270 {
  line-height: 2.7;
}
.lh-280 {
  line-height: 2.8;
}
.lh-290 {
  line-height: 2.9;
}
.lh-300 {
  line-height: 3;
}
.ls-1 {
  letter-spacing: 0.0625rem;
}
.ls-2 {
  letter-spacing: 0.09375rem;
}
.ls-3 {
  letter-spacing: 0.125rem;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-body {
  color: #506690 !important;
}
.text-black-50 {
  color: rgba(17, 17, 17, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.list-style-none {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 992px) {
  .transform-perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg)
      rotate(2deg);
  }
  .transform-perspective-left {
    transform: scale(1) perspective(900px) rotateY(20deg) rotateX(-2deg)
      rotate(-2deg);
  }
}
.t-none {
  transform: none !important;
}
.animate-down-1,
.animate-left-1,
.animate-right-1,
.animate-up-1 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-1,
.animate-up-1:hover {
  transform: translate(0, -1px);
}
.animate-hover:hover .animate-right-1,
.animate-right-1:hover {
  transform: translate(1px, 0);
}
.animate-down-1:hover,
.animate-hover:hover .animate-down-1 {
  transform: translate(0, 1px);
}
.animate-hover:hover .animate-left-1,
.animate-left-1:hover {
  transform: translate(-1px, 0);
}
.animate-down-2,
.animate-left-2,
.animate-right-2,
.animate-up-2 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-2,
.animate-up-2:hover {
  transform: translate(0, -2px);
}
.animate-hover:hover .animate-right-2,
.animate-right-2:hover {
  transform: translate(2px, 0);
}
.animate-down-2:hover,
.animate-hover:hover .animate-down-2 {
  transform: translate(0, 2px);
}
.animate-hover:hover .animate-left-2,
.animate-left-2:hover {
  transform: translate(-2px, 0);
}
.animate-down-3,
.animate-left-3,
.animate-right-3,
.animate-up-3 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-3,
.animate-up-3:hover {
  transform: translate(0, -3px);
}
.animate-hover:hover .animate-right-3,
.animate-right-3:hover {
  transform: translate(3px, 0);
}
.animate-down-3:hover,
.animate-hover:hover .animate-down-3 {
  transform: translate(0, 3px);
}
.animate-hover:hover .animate-left-3,
.animate-left-3:hover {
  transform: translate(-3px, 0);
}
.animate-down-4,
.animate-left-4,
.animate-right-4,
.animate-up-4 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-4,
.animate-up-4:hover {
  transform: translate(0, -4px);
}
.animate-hover:hover .animate-right-4,
.animate-right-4:hover {
  transform: translate(4px, 0);
}
.animate-down-4:hover,
.animate-hover:hover .animate-down-4 {
  transform: translate(0, 4px);
}
.animate-hover:hover .animate-left-4,
.animate-left-4:hover {
  transform: translate(-4px, 0);
}
.animate-down-5,
.animate-left-5,
.animate-right-5,
.animate-up-5 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-5,
.animate-up-5:hover {
  transform: translate(0, -5px);
}
.animate-hover:hover .animate-right-5,
.animate-right-5:hover {
  transform: translate(5px, 0);
}
.animate-down-5:hover,
.animate-hover:hover .animate-down-5 {
  transform: translate(0, 5px);
}
.animate-hover:hover .animate-left-5,
.animate-left-5:hover {
  transform: translate(-5px, 0);
}
.animate-down-6,
.animate-left-6,
.animate-right-6,
.animate-up-6 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-6,
.animate-up-6:hover {
  transform: translate(0, -6px);
}
.animate-hover:hover .animate-right-6,
.animate-right-6:hover {
  transform: translate(6px, 0);
}
.animate-down-6:hover,
.animate-hover:hover .animate-down-6 {
  transform: translate(0, 6px);
}
.animate-hover:hover .animate-left-6,
.animate-left-6:hover {
  transform: translate(-6px, 0);
}
.animate-down-7,
.animate-left-7,
.animate-right-7,
.animate-up-7 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-7,
.animate-up-7:hover {
  transform: translate(0, -7px);
}
.animate-hover:hover .animate-right-7,
.animate-right-7:hover {
  transform: translate(7px, 0);
}
.animate-down-7:hover,
.animate-hover:hover .animate-down-7 {
  transform: translate(0, 7px);
}
.animate-hover:hover .animate-left-7,
.animate-left-7:hover {
  transform: translate(-7px, 0);
}
.animate-down-8,
.animate-left-8,
.animate-right-8,
.animate-up-8 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-8,
.animate-up-8:hover {
  transform: translate(0, -8px);
}
.animate-hover:hover .animate-right-8,
.animate-right-8:hover {
  transform: translate(8px, 0);
}
.animate-down-8:hover,
.animate-hover:hover .animate-down-8 {
  transform: translate(0, 8px);
}
.animate-hover:hover .animate-left-8,
.animate-left-8:hover {
  transform: translate(-8px, 0);
}
.animate-down-9,
.animate-left-9,
.animate-right-9,
.animate-up-9 {
  transition: all 0.2s ease;
}
.animate-hover:hover .animate-up-9,
.animate-up-9:hover {
  transform: translate(0, -9px);
}
.animate-hover:hover .animate-right-9,
.animate-right-9:hover {
  transform: translate(9px, 0);
}
.animate-down-9:hover,
.animate-hover:hover .animate-down-9 {
  transform: translate(0, 9px);
}
.animate-hover:hover .animate-left-9,
.animate-left-9:hover {
  transform: translate(-9px, 0);
}
@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.45s, transform 0.45s;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform-origin: perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.5s;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide-dropdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
.navbar-main {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
}
.navbar .navbar-nav .nav-link {
  font-size: 1rem;
  text-transform: normal;
  letter-spacing: 0;
  font-weight: 400;
  transition: all 0.2s cubic-bezier(0.34, 1.45, 0.7, 1);
}
.navbar .navbar-nav .nav-link .nav-link-inner-text {
  margin-left: 0.25rem;
}
.navbar .navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem;
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  top: calc(100% + 7px);
}
.navbar .navbar-nav .dropdown .dropdown-item {
  font-weight: 400;
  font-size: 0.85rem;
}
.navbar .navbar-nav .mega-dropdown {
  position: static;
}
.navbar .navbar-nav .mega-dropdown .dropdown-menu {
  width: calc(100% - 35px);
  left: 20px;
  padding: 20px;
  border-radius: 0.2rem;
  overflow: hidden;
}
.navbar .navbar-nav .mega-dropdown .dropdown-item {
  border-radius: 0.2rem;
}
.navbar .navbar-nav .mega-dropdown .inside-bg {
  margin: -20px 0 -20px -20px;
}
.navbar .navbar-nav .mega-dropdown .inside-bg h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
.navbar .navbar-nav .mega-dropdown .inside-bg-right {
  margin: -20px -20px -20px 0;
}
.navbar .navbar-nav .mega-dropdown .bg-img {
  background-size: cover;
  position: relative;
  background-position: center center;
  height: 100%;
  padding: 20px;
}
.navbar .navbar-nav .mega-dropdown h6 {
  margin: 15px 0;
  font-size: 15px;
  font-weight: 600;
}
.navbar .navbar-nav .dropdown-submenu {
  position: relative;
}
.navbar .navbar-nav .dropdown-submenu .dropdown-menu {
  display: none;
  top: 0;
  left: calc(100% - 1px);
  opacity: 0;
}
.navbar .navbar-nav .dropdown-submenu .dropdown-menu.menu-right {
  left: auto;
  right: 100%;
}
.navbar .navbar-nav .dropdown-submenu.show .dropdown-menu {
  display: block;
  opacity: 1;
  pointer-events: all;
}
.navbar .navbar-nav .nav-link-arrow {
  transition: transform 0.2s ease;
}
.navbar-brand {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}
.navbar-brand img {
  height: 40px;
}
.navbar-dark .navbar-brand-light {
  display: none;
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-light .navbar-brand-dark {
  display: none;
}
.navbar-light .navbar-brand {
  color: #506690;
}
.navbar-theme-primary:not(.headroom) {
  background-color: #11ab7c;
}
.navbar-theme-primary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-primary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-primary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-primary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #000;
  background: rgba(12, 125, 90, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #000;
  }
}
.navbar-theme-primary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-primary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #11ab7c;
}
.navbar-theme-primary.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-primary .navbar-nav .dropdown-item.active,
.navbar-theme-primary .navbar-nav .dropdown-item:hover,
.navbar-theme-primary .navbar-nav .list-group-item.active,
.navbar-theme-primary .navbar-nav .list-group-item:hover {
  color: #11ab7c;
  background: rgba(17, 171, 124, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-primary .navbar-nav .dropdown-item,
  .navbar-theme-primary .navbar-nav .dropdown-item.active,
  .navbar-theme-primary .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary .navbar-nav .list-group-item,
  .navbar-theme-primary .navbar-nav .list-group-item.active,
  .navbar-theme-primary .navbar-nav .list-group-item:focus,
  .navbar-theme-primary .navbar-nav .list-group-item:hover,
  .navbar-theme-primary .navbar-nav .nav-link,
  .navbar-theme-primary .navbar-nav .nav-link.active,
  .navbar-theme-primary .navbar-nav .nav-link:focus,
  .navbar-theme-primary .navbar-nav .nav-link:hover,
  .navbar-theme-primary .navbar-nav .show > .nav-link,
  .navbar-theme-primary .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary .navbar-nav .show > .nav-link:hover {
    color: #11ab7c;
    background: 0 0;
  }
  .navbar-theme-primary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary .navbar-nav .list-group-item.disabled,
  .navbar-theme-primary .navbar-nav .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-primary .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-primary .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-primary
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-secondary:not(.headroom) {
  background-color: #005ad4;
}
.navbar-theme-secondary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-secondary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #000308;
  background: rgba(0, 68, 161, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #000308;
  }
}
.navbar-theme-secondary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-secondary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #005ad4;
}
.navbar-theme-secondary.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-secondary .navbar-nav .dropdown-item.active,
.navbar-theme-secondary .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary .navbar-nav .list-group-item.active,
.navbar-theme-secondary .navbar-nav .list-group-item:hover {
  color: #005ad4;
  background: rgba(0, 90, 212, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-secondary .navbar-nav .dropdown-item,
  .navbar-theme-secondary .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary .navbar-nav .list-group-item,
  .navbar-theme-secondary .navbar-nav .list-group-item.active,
  .navbar-theme-secondary .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary .navbar-nav .list-group-item:hover,
  .navbar-theme-secondary .navbar-nav .nav-link,
  .navbar-theme-secondary .navbar-nav .nav-link.active,
  .navbar-theme-secondary .navbar-nav .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .show > .nav-link,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:hover {
    color: #005ad4;
    background: 0 0;
  }
  .navbar-theme-secondary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary .navbar-nav .list-group-item.disabled,
  .navbar-theme-secondary .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-secondary .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-secondary .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-secondary
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-success:not(.headroom) {
  background-color: #00bf9a;
}
.navbar-theme-success.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-success.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-success.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-success.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #000;
  background: rgba(0, 140, 113, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #000;
  }
}
.navbar-theme-success.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-success.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #00bf9a;
}
.navbar-theme-success.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-success .navbar-nav .dropdown-item.active,
.navbar-theme-success .navbar-nav .dropdown-item:hover,
.navbar-theme-success .navbar-nav .list-group-item.active,
.navbar-theme-success .navbar-nav .list-group-item:hover {
  color: #00bf9a;
  background: rgba(0, 191, 154, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-success .navbar-nav .dropdown-item,
  .navbar-theme-success .navbar-nav .dropdown-item.active,
  .navbar-theme-success .navbar-nav .dropdown-item:focus,
  .navbar-theme-success .navbar-nav .dropdown-item:hover,
  .navbar-theme-success .navbar-nav .list-group-item,
  .navbar-theme-success .navbar-nav .list-group-item.active,
  .navbar-theme-success .navbar-nav .list-group-item:focus,
  .navbar-theme-success .navbar-nav .list-group-item:hover,
  .navbar-theme-success .navbar-nav .nav-link,
  .navbar-theme-success .navbar-nav .nav-link.active,
  .navbar-theme-success .navbar-nav .nav-link:focus,
  .navbar-theme-success .navbar-nav .nav-link:hover,
  .navbar-theme-success .navbar-nav .show > .nav-link,
  .navbar-theme-success .navbar-nav .show > .nav-link.active,
  .navbar-theme-success .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success .navbar-nav .show > .nav-link:hover {
    color: #00bf9a;
    background: 0 0;
  }
  .navbar-theme-success .navbar-nav .dropdown-item.disabled,
  .navbar-theme-success .navbar-nav .list-group-item.disabled,
  .navbar-theme-success .navbar-nav .nav-link.disabled,
  .navbar-theme-success .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-success .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-success .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-success
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-info:not(.headroom) {
  background-color: #1e90ff;
}
.navbar-theme-info.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-info.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-info.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #002951;
  background: rgba(0, 119, 234, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #002951;
  }
}
.navbar-theme-info.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-info.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #1e90ff;
}
.navbar-theme-info.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-info .navbar-nav .dropdown-item.active,
.navbar-theme-info .navbar-nav .dropdown-item:hover,
.navbar-theme-info .navbar-nav .list-group-item.active,
.navbar-theme-info .navbar-nav .list-group-item:hover {
  color: #1e90ff;
  background: rgba(30, 144, 255, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-info .navbar-nav .dropdown-item,
  .navbar-theme-info .navbar-nav .dropdown-item.active,
  .navbar-theme-info .navbar-nav .dropdown-item:focus,
  .navbar-theme-info .navbar-nav .dropdown-item:hover,
  .navbar-theme-info .navbar-nav .list-group-item,
  .navbar-theme-info .navbar-nav .list-group-item.active,
  .navbar-theme-info .navbar-nav .list-group-item:focus,
  .navbar-theme-info .navbar-nav .list-group-item:hover,
  .navbar-theme-info .navbar-nav .nav-link,
  .navbar-theme-info .navbar-nav .nav-link.active,
  .navbar-theme-info .navbar-nav .nav-link:focus,
  .navbar-theme-info .navbar-nav .nav-link:hover,
  .navbar-theme-info .navbar-nav .show > .nav-link,
  .navbar-theme-info .navbar-nav .show > .nav-link.active,
  .navbar-theme-info .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info .navbar-nav .show > .nav-link:hover {
    color: #1e90ff;
    background: 0 0;
  }
  .navbar-theme-info .navbar-nav .dropdown-item.disabled,
  .navbar-theme-info .navbar-nav .list-group-item.disabled,
  .navbar-theme-info .navbar-nav .nav-link.disabled,
  .navbar-theme-info .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-info .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-info .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-info .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-warning:not(.headroom) {
  background-color: #f5b759;
}
.navbar-theme-warning.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-warning.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-warning.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-warning.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #7b4d07;
  background: rgba(242, 162, 41, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #7b4d07;
  }
}
.navbar-theme-warning.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-warning.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #f5b759;
}
.navbar-theme-warning.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-warning .navbar-nav .dropdown-item.active,
.navbar-theme-warning .navbar-nav .dropdown-item:hover,
.navbar-theme-warning .navbar-nav .list-group-item.active,
.navbar-theme-warning .navbar-nav .list-group-item:hover {
  color: #f5b759;
  background: rgba(245, 183, 89, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-warning .navbar-nav .dropdown-item,
  .navbar-theme-warning .navbar-nav .dropdown-item.active,
  .navbar-theme-warning .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning .navbar-nav .list-group-item,
  .navbar-theme-warning .navbar-nav .list-group-item.active,
  .navbar-theme-warning .navbar-nav .list-group-item:focus,
  .navbar-theme-warning .navbar-nav .list-group-item:hover,
  .navbar-theme-warning .navbar-nav .nav-link,
  .navbar-theme-warning .navbar-nav .nav-link.active,
  .navbar-theme-warning .navbar-nav .nav-link:focus,
  .navbar-theme-warning .navbar-nav .nav-link:hover,
  .navbar-theme-warning .navbar-nav .show > .nav-link,
  .navbar-theme-warning .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning .navbar-nav .show > .nav-link:hover {
    color: #f5b759;
    background: 0 0;
  }
  .navbar-theme-warning .navbar-nav .dropdown-item.disabled,
  .navbar-theme-warning .navbar-nav .list-group-item.disabled,
  .navbar-theme-warning .navbar-nav .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-warning .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-warning .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-warning
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-danger:not(.headroom) {
  background-color: #fa5252;
}
.navbar-theme-danger.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-danger.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-danger.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-danger.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #7c0404;
  background: rgba(249, 32, 32, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #7c0404;
  }
}
.navbar-theme-danger.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-danger.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #fa5252;
}
.navbar-theme-danger.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-danger .navbar-nav .dropdown-item.active,
.navbar-theme-danger .navbar-nav .dropdown-item:hover,
.navbar-theme-danger .navbar-nav .list-group-item.active,
.navbar-theme-danger .navbar-nav .list-group-item:hover {
  color: #fa5252;
  background: rgba(250, 82, 82, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-danger .navbar-nav .dropdown-item,
  .navbar-theme-danger .navbar-nav .dropdown-item.active,
  .navbar-theme-danger .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger .navbar-nav .list-group-item,
  .navbar-theme-danger .navbar-nav .list-group-item.active,
  .navbar-theme-danger .navbar-nav .list-group-item:focus,
  .navbar-theme-danger .navbar-nav .list-group-item:hover,
  .navbar-theme-danger .navbar-nav .nav-link,
  .navbar-theme-danger .navbar-nav .nav-link.active,
  .navbar-theme-danger .navbar-nav .nav-link:focus,
  .navbar-theme-danger .navbar-nav .nav-link:hover,
  .navbar-theme-danger .navbar-nav .show > .nav-link,
  .navbar-theme-danger .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger .navbar-nav .show > .nav-link:hover {
    color: #fa5252;
    background: 0 0;
  }
  .navbar-theme-danger .navbar-nav .dropdown-item.disabled,
  .navbar-theme-danger .navbar-nav .list-group-item.disabled,
  .navbar-theme-danger .navbar-nav .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-danger .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-danger .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-danger
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-light:not(.headroom) {
  background-color: #dde1ed;
}
.navbar-theme-light.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-light.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-light.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-light.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #586ba6;
  background: rgba(188, 196, 219, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #586ba6;
  }
}
.navbar-theme-light.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-light.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #dde1ed;
}
.navbar-theme-light.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-light .navbar-nav .dropdown-item.active,
.navbar-theme-light .navbar-nav .dropdown-item:hover,
.navbar-theme-light .navbar-nav .list-group-item.active,
.navbar-theme-light .navbar-nav .list-group-item:hover {
  color: #dde1ed;
  background: rgba(221, 225, 237, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-light .navbar-nav .dropdown-item,
  .navbar-theme-light .navbar-nav .dropdown-item.active,
  .navbar-theme-light .navbar-nav .dropdown-item:focus,
  .navbar-theme-light .navbar-nav .dropdown-item:hover,
  .navbar-theme-light .navbar-nav .list-group-item,
  .navbar-theme-light .navbar-nav .list-group-item.active,
  .navbar-theme-light .navbar-nav .list-group-item:focus,
  .navbar-theme-light .navbar-nav .list-group-item:hover,
  .navbar-theme-light .navbar-nav .nav-link,
  .navbar-theme-light .navbar-nav .nav-link.active,
  .navbar-theme-light .navbar-nav .nav-link:focus,
  .navbar-theme-light .navbar-nav .nav-link:hover,
  .navbar-theme-light .navbar-nav .show > .nav-link,
  .navbar-theme-light .navbar-nav .show > .nav-link.active,
  .navbar-theme-light .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light .navbar-nav .show > .nav-link:hover {
    color: #dde1ed;
    background: 0 0;
  }
  .navbar-theme-light .navbar-nav .dropdown-item.disabled,
  .navbar-theme-light .navbar-nav .list-group-item.disabled,
  .navbar-theme-light .navbar-nav .nav-link.disabled,
  .navbar-theme-light .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-light .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-light .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-light .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-dark:not(.headroom) {
  background-color: #2a354f;
}
.navbar-theme-dark.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dark.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-dark.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #000;
  background: rgba(24, 31, 46, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #000;
  }
}
.navbar-theme-dark.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-dark.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #2a354f;
}
.navbar-theme-dark.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-dark .navbar-nav .dropdown-item.active,
.navbar-theme-dark .navbar-nav .dropdown-item:hover,
.navbar-theme-dark .navbar-nav .list-group-item.active,
.navbar-theme-dark .navbar-nav .list-group-item:hover {
  color: #2a354f;
  background: rgba(42, 53, 79, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-dark .navbar-nav .dropdown-item,
  .navbar-theme-dark .navbar-nav .dropdown-item.active,
  .navbar-theme-dark .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark .navbar-nav .list-group-item,
  .navbar-theme-dark .navbar-nav .list-group-item.active,
  .navbar-theme-dark .navbar-nav .list-group-item:focus,
  .navbar-theme-dark .navbar-nav .list-group-item:hover,
  .navbar-theme-dark .navbar-nav .nav-link,
  .navbar-theme-dark .navbar-nav .nav-link.active,
  .navbar-theme-dark .navbar-nav .nav-link:focus,
  .navbar-theme-dark .navbar-nav .nav-link:hover,
  .navbar-theme-dark .navbar-nav .show > .nav-link,
  .navbar-theme-dark .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark .navbar-nav .show > .nav-link:hover {
    color: #2a354f;
    background: 0 0;
  }
  .navbar-theme-dark .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dark .navbar-nav .list-group-item.disabled,
  .navbar-theme-dark .navbar-nav .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-dark .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-dark .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-dark .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-tertiary:not(.headroom) {
  background-color: #de6926;
}
.navbar-theme-tertiary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-tertiary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #311607;
  background: rgba(181, 84, 28, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #311607;
  }
}
.navbar-theme-tertiary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-tertiary.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #de6926;
}
.navbar-theme-tertiary.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-tertiary .navbar-nav .dropdown-item.active,
.navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary .navbar-nav .list-group-item.active,
.navbar-theme-tertiary .navbar-nav .list-group-item:hover {
  color: #de6926;
  background: rgba(222, 105, 38, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-tertiary .navbar-nav .dropdown-item,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary .navbar-nav .list-group-item,
  .navbar-theme-tertiary .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary .navbar-nav .list-group-item:hover,
  .navbar-theme-tertiary .navbar-nav .nav-link,
  .navbar-theme-tertiary .navbar-nav .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:hover {
    color: #de6926;
    background: 0 0;
  }
  .navbar-theme-tertiary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-tertiary .navbar-nav .list-group-item.disabled,
  .navbar-theme-tertiary .navbar-nav .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-tertiary .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-tertiary .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-tertiary
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-orange:not(.headroom) {
  background-color: #ff7f00;
}
.navbar-theme-orange.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-orange.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-orange.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-orange.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #331900;
  background: rgba(204, 102, 0, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-orange.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-orange.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #331900;
  }
}
.navbar-theme-orange.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-orange.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #ff7f00;
}
.navbar-theme-orange.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-orange .navbar-nav .dropdown-item.active,
.navbar-theme-orange .navbar-nav .dropdown-item:hover,
.navbar-theme-orange .navbar-nav .list-group-item.active,
.navbar-theme-orange .navbar-nav .list-group-item:hover {
  color: #ff7f00;
  background: rgba(255, 127, 0, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-orange .navbar-nav .dropdown-item,
  .navbar-theme-orange .navbar-nav .dropdown-item.active,
  .navbar-theme-orange .navbar-nav .dropdown-item:focus,
  .navbar-theme-orange .navbar-nav .dropdown-item:hover,
  .navbar-theme-orange .navbar-nav .list-group-item,
  .navbar-theme-orange .navbar-nav .list-group-item.active,
  .navbar-theme-orange .navbar-nav .list-group-item:focus,
  .navbar-theme-orange .navbar-nav .list-group-item:hover,
  .navbar-theme-orange .navbar-nav .nav-link,
  .navbar-theme-orange .navbar-nav .nav-link.active,
  .navbar-theme-orange .navbar-nav .nav-link:focus,
  .navbar-theme-orange .navbar-nav .nav-link:hover,
  .navbar-theme-orange .navbar-nav .show > .nav-link,
  .navbar-theme-orange .navbar-nav .show > .nav-link.active,
  .navbar-theme-orange .navbar-nav .show > .nav-link:focus,
  .navbar-theme-orange .navbar-nav .show > .nav-link:hover {
    color: #ff7f00;
    background: 0 0;
  }
  .navbar-theme-orange .navbar-nav .dropdown-item.disabled,
  .navbar-theme-orange .navbar-nav .list-group-item.disabled,
  .navbar-theme-orange .navbar-nav .nav-link.disabled,
  .navbar-theme-orange .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-orange .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-orange .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-orange
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-white:not(.headroom) {
  background-color: #fff;
}
.navbar-theme-white.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-white.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-white.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-white.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #999;
  background: rgba(230, 230, 230, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #999;
  }
}
.navbar-theme-white.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-white.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #fff;
}
.navbar-theme-white.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-white .navbar-nav .dropdown-item.active,
.navbar-theme-white .navbar-nav .dropdown-item:hover,
.navbar-theme-white .navbar-nav .list-group-item.active,
.navbar-theme-white .navbar-nav .list-group-item:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-white .navbar-nav .dropdown-item,
  .navbar-theme-white .navbar-nav .dropdown-item.active,
  .navbar-theme-white .navbar-nav .dropdown-item:focus,
  .navbar-theme-white .navbar-nav .dropdown-item:hover,
  .navbar-theme-white .navbar-nav .list-group-item,
  .navbar-theme-white .navbar-nav .list-group-item.active,
  .navbar-theme-white .navbar-nav .list-group-item:focus,
  .navbar-theme-white .navbar-nav .list-group-item:hover,
  .navbar-theme-white .navbar-nav .nav-link,
  .navbar-theme-white .navbar-nav .nav-link.active,
  .navbar-theme-white .navbar-nav .nav-link:focus,
  .navbar-theme-white .navbar-nav .nav-link:hover,
  .navbar-theme-white .navbar-nav .show > .nav-link,
  .navbar-theme-white .navbar-nav .show > .nav-link.active,
  .navbar-theme-white .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white .navbar-nav .show > .nav-link:hover {
    color: #fff;
    background: 0 0;
  }
  .navbar-theme-white .navbar-nav .dropdown-item.disabled,
  .navbar-theme-white .navbar-nav .list-group-item.disabled,
  .navbar-theme-white .navbar-nav .nav-link.disabled,
  .navbar-theme-white .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-white .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-white .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-white .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray:not(.headroom) {
  background-color: #66799e;
}
.navbar-theme-gray.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #161a22;
  background: rgba(81, 97, 128, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #161a22;
  }
}
.navbar-theme-gray.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #66799e;
}
.navbar-theme-gray.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray .navbar-nav .dropdown-item.active,
.navbar-theme-gray .navbar-nav .dropdown-item:hover,
.navbar-theme-gray .navbar-nav .list-group-item.active,
.navbar-theme-gray .navbar-nav .list-group-item:hover {
  color: #66799e;
  background: rgba(102, 121, 158, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray .navbar-nav .dropdown-item,
  .navbar-theme-gray .navbar-nav .dropdown-item.active,
  .navbar-theme-gray .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray .navbar-nav .list-group-item,
  .navbar-theme-gray .navbar-nav .list-group-item.active,
  .navbar-theme-gray .navbar-nav .list-group-item:focus,
  .navbar-theme-gray .navbar-nav .list-group-item:hover,
  .navbar-theme-gray .navbar-nav .nav-link,
  .navbar-theme-gray .navbar-nav .nav-link.active,
  .navbar-theme-gray .navbar-nav .nav-link:focus,
  .navbar-theme-gray .navbar-nav .nav-link:hover,
  .navbar-theme-gray .navbar-nav .show > .nav-link,
  .navbar-theme-gray .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray .navbar-nav .show > .nav-link:hover {
    color: #66799e;
    background: 0 0;
  }
  .navbar-theme-gray .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray .navbar-nav .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-neutral:not(.headroom) {
  background-color: #fff;
}
.navbar-theme-neutral.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-neutral.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-neutral.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-neutral.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #999;
  background: rgba(230, 230, 230, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-neutral.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #999;
  }
}
.navbar-theme-neutral.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-neutral.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #fff;
}
.navbar-theme-neutral.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-neutral .navbar-nav .dropdown-item.active,
.navbar-theme-neutral .navbar-nav .dropdown-item:hover,
.navbar-theme-neutral .navbar-nav .list-group-item.active,
.navbar-theme-neutral .navbar-nav .list-group-item:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-neutral .navbar-nav .dropdown-item,
  .navbar-theme-neutral .navbar-nav .dropdown-item.active,
  .navbar-theme-neutral .navbar-nav .dropdown-item:focus,
  .navbar-theme-neutral .navbar-nav .dropdown-item:hover,
  .navbar-theme-neutral .navbar-nav .list-group-item,
  .navbar-theme-neutral .navbar-nav .list-group-item.active,
  .navbar-theme-neutral .navbar-nav .list-group-item:focus,
  .navbar-theme-neutral .navbar-nav .list-group-item:hover,
  .navbar-theme-neutral .navbar-nav .nav-link,
  .navbar-theme-neutral .navbar-nav .nav-link.active,
  .navbar-theme-neutral .navbar-nav .nav-link:focus,
  .navbar-theme-neutral .navbar-nav .nav-link:hover,
  .navbar-theme-neutral .navbar-nav .show > .nav-link,
  .navbar-theme-neutral .navbar-nav .show > .nav-link.active,
  .navbar-theme-neutral .navbar-nav .show > .nav-link:focus,
  .navbar-theme-neutral .navbar-nav .show > .nav-link:hover {
    color: #fff;
    background: 0 0;
  }
  .navbar-theme-neutral .navbar-nav .dropdown-item.disabled,
  .navbar-theme-neutral .navbar-nav .list-group-item.disabled,
  .navbar-theme-neutral .navbar-nav .nav-link.disabled,
  .navbar-theme-neutral .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-neutral .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-neutral .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-neutral
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-soft:not(.headroom) {
  background-color: #f3f7fa;
}
.navbar-theme-soft.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-soft.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #6397be;
  background: rgba(207, 223, 235, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #6397be;
  }
}
.navbar-theme-soft.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-soft.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #f3f7fa;
}
.navbar-theme-soft.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-soft .navbar-nav .dropdown-item.active,
.navbar-theme-soft .navbar-nav .dropdown-item:hover,
.navbar-theme-soft .navbar-nav .list-group-item.active,
.navbar-theme-soft .navbar-nav .list-group-item:hover {
  color: #f3f7fa;
  background: rgba(243, 247, 250, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-soft .navbar-nav .dropdown-item,
  .navbar-theme-soft .navbar-nav .dropdown-item.active,
  .navbar-theme-soft .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft .navbar-nav .list-group-item,
  .navbar-theme-soft .navbar-nav .list-group-item.active,
  .navbar-theme-soft .navbar-nav .list-group-item:focus,
  .navbar-theme-soft .navbar-nav .list-group-item:hover,
  .navbar-theme-soft .navbar-nav .nav-link,
  .navbar-theme-soft .navbar-nav .nav-link.active,
  .navbar-theme-soft .navbar-nav .nav-link:focus,
  .navbar-theme-soft .navbar-nav .nav-link:hover,
  .navbar-theme-soft .navbar-nav .show > .nav-link,
  .navbar-theme-soft .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft .navbar-nav .show > .nav-link:hover {
    color: #f3f7fa;
    background: 0 0;
  }
  .navbar-theme-soft .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft .navbar-nav .list-group-item.disabled,
  .navbar-theme-soft .navbar-nav .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-soft .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-soft .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-soft .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-black:not(.headroom) {
  background-color: #3e4555;
}
.navbar-theme-black.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-black.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-black.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-black.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #000;
  background: rgba(40, 45, 56, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #000;
  }
}
.navbar-theme-black.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-black.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #3e4555;
}
.navbar-theme-black.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-black .navbar-nav .dropdown-item.active,
.navbar-theme-black .navbar-nav .dropdown-item:hover,
.navbar-theme-black .navbar-nav .list-group-item.active,
.navbar-theme-black .navbar-nav .list-group-item:hover {
  color: #3e4555;
  background: rgba(62, 69, 85, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-black .navbar-nav .dropdown-item,
  .navbar-theme-black .navbar-nav .dropdown-item.active,
  .navbar-theme-black .navbar-nav .dropdown-item:focus,
  .navbar-theme-black .navbar-nav .dropdown-item:hover,
  .navbar-theme-black .navbar-nav .list-group-item,
  .navbar-theme-black .navbar-nav .list-group-item.active,
  .navbar-theme-black .navbar-nav .list-group-item:focus,
  .navbar-theme-black .navbar-nav .list-group-item:hover,
  .navbar-theme-black .navbar-nav .nav-link,
  .navbar-theme-black .navbar-nav .nav-link.active,
  .navbar-theme-black .navbar-nav .nav-link:focus,
  .navbar-theme-black .navbar-nav .nav-link:hover,
  .navbar-theme-black .navbar-nav .show > .nav-link,
  .navbar-theme-black .navbar-nav .show > .nav-link.active,
  .navbar-theme-black .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black .navbar-nav .show > .nav-link:hover {
    color: #3e4555;
    background: 0 0;
  }
  .navbar-theme-black .navbar-nav .dropdown-item.disabled,
  .navbar-theme-black .navbar-nav .list-group-item.disabled,
  .navbar-theme-black .navbar-nav .nav-link.disabled,
  .navbar-theme-black .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-black .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-black .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-black .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-purple:not(.headroom) {
  background-color: #8965e0;
}
.navbar-theme-purple.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-purple.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-purple.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-purple.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #2c1465;
  background: rgba(104, 59, 215, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #2c1465;
  }
}
.navbar-theme-purple.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-purple.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #8965e0;
}
.navbar-theme-purple.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-purple .navbar-nav .dropdown-item.active,
.navbar-theme-purple .navbar-nav .dropdown-item:hover,
.navbar-theme-purple .navbar-nav .list-group-item.active,
.navbar-theme-purple .navbar-nav .list-group-item:hover {
  color: #8965e0;
  background: rgba(137, 101, 224, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-purple .navbar-nav .dropdown-item,
  .navbar-theme-purple .navbar-nav .dropdown-item.active,
  .navbar-theme-purple .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple .navbar-nav .list-group-item,
  .navbar-theme-purple .navbar-nav .list-group-item.active,
  .navbar-theme-purple .navbar-nav .list-group-item:focus,
  .navbar-theme-purple .navbar-nav .list-group-item:hover,
  .navbar-theme-purple .navbar-nav .nav-link,
  .navbar-theme-purple .navbar-nav .nav-link.active,
  .navbar-theme-purple .navbar-nav .nav-link:focus,
  .navbar-theme-purple .navbar-nav .nav-link:hover,
  .navbar-theme-purple .navbar-nav .show > .nav-link,
  .navbar-theme-purple .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple .navbar-nav .show > .nav-link:hover {
    color: #8965e0;
    background: 0 0;
  }
  .navbar-theme-purple .navbar-nav .dropdown-item.disabled,
  .navbar-theme-purple .navbar-nav .list-group-item.disabled,
  .navbar-theme-purple .navbar-nav .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-purple .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-purple .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-purple
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-100:not(.headroom) {
  background-color: #f3f7fa;
}
.navbar-theme-gray-100.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-100.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #6397be;
  background: rgba(207, 223, 235, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #6397be;
  }
}
.navbar-theme-gray-100.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-100.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #f3f7fa;
}
.navbar-theme-gray-100.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100 .navbar-nav .list-group-item.active,
.navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
  color: #f3f7fa;
  background: rgba(243, 247, 250, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-100 .navbar-nav .dropdown-item,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100 .navbar-nav .list-group-item,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-100 .navbar-nav .nav-link,
  .navbar-theme-gray-100 .navbar-nav .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:hover {
    color: #f3f7fa;
    background: 0 0;
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-100 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-100 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-100
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-200:not(.headroom) {
  background-color: #e9ecef;
}
.navbar-theme-gray-200.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-200.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #738699;
  background: rgba(203, 211, 218, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #738699;
  }
}
.navbar-theme-gray-200.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-200.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #e9ecef;
}
.navbar-theme-gray-200.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200 .navbar-nav .list-group-item.active,
.navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
  color: #e9ecef;
  background: rgba(233, 236, 239, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-200 .navbar-nav .dropdown-item,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200 .navbar-nav .list-group-item,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-200 .navbar-nav .nav-link,
  .navbar-theme-gray-200 .navbar-nav .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:hover {
    color: #e9ecef;
    background: 0 0;
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-200 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-200 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-200
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-300:not(.headroom) {
  background-color: #dde1ed;
}
.navbar-theme-gray-300.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-300.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #586ba6;
  background: rgba(188, 196, 219, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #586ba6;
  }
}
.navbar-theme-gray-300.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-300.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #dde1ed;
}
.navbar-theme-gray-300.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300 .navbar-nav .list-group-item.active,
.navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
  color: #dde1ed;
  background: rgba(221, 225, 237, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-300 .navbar-nav .dropdown-item,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300 .navbar-nav .list-group-item,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-300 .navbar-nav .nav-link,
  .navbar-theme-gray-300 .navbar-nav .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:hover {
    color: #dde1ed;
    background: 0 0;
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-300 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-300 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-300
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-400:not(.headroom) {
  background-color: #e0e6f0;
}
.navbar-theme-gray-400.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-400.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #5777ad;
  background: rgba(190, 202, 223, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #5777ad;
  }
}
.navbar-theme-gray-400.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-400.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #e0e6f0;
}
.navbar-theme-gray-400.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400 .navbar-nav .list-group-item.active,
.navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
  color: #e0e6f0;
  background: rgba(224, 230, 240, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-400 .navbar-nav .dropdown-item,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400 .navbar-nav .list-group-item,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-400 .navbar-nav .nav-link,
  .navbar-theme-gray-400 .navbar-nav .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:hover {
    color: #e0e6f0;
    background: 0 0;
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-400 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-400 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-400
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-500:not(.headroom) {
  background-color: #adb5bd;
}
.navbar-theme-gray-500.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-500.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #464f58;
  background: rgba(145, 156, 166, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #464f58;
  }
}
.navbar-theme-gray-500.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-500.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #adb5bd;
}
.navbar-theme-gray-500.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500 .navbar-nav .list-group-item.active,
.navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
  color: #adb5bd;
  background: rgba(173, 181, 189, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-500 .navbar-nav .dropdown-item,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500 .navbar-nav .list-group-item,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-500 .navbar-nav .nav-link,
  .navbar-theme-gray-500 .navbar-nav .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:hover {
    color: #adb5bd;
    background: 0 0;
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-500 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-500 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-500
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-600:not(.headroom) {
  background-color: #869ab8;
}
.navbar-theme-gray-600.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-600.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #2a3648;
  background: rgba(102, 127, 165, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #2a3648;
  }
}
.navbar-theme-gray-600.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-600.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #869ab8;
}
.navbar-theme-gray-600.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600 .navbar-nav .list-group-item.active,
.navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
  color: #869ab8;
  background: rgba(134, 154, 184, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-600 .navbar-nav .dropdown-item,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600 .navbar-nav .list-group-item,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-600 .navbar-nav .nav-link,
  .navbar-theme-gray-600 .navbar-nav .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:hover {
    color: #869ab8;
    background: 0 0;
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-600 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-600 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-600
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-700:not(.headroom) {
  background-color: #66799e;
}
.navbar-theme-gray-700.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-700.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #161a22;
  background: rgba(81, 97, 128, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #161a22;
  }
}
.navbar-theme-gray-700.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-700.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #66799e;
}
.navbar-theme-gray-700.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700 .navbar-nav .list-group-item.active,
.navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
  color: #66799e;
  background: rgba(102, 121, 158, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-700 .navbar-nav .dropdown-item,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700 .navbar-nav .list-group-item,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-700 .navbar-nav .nav-link,
  .navbar-theme-gray-700 .navbar-nav .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:hover {
    color: #66799e;
    background: 0 0;
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-700 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-700 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-700
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-theme-gray-800:not(.headroom) {
  background-color: #506690;
}
.navbar-theme-gray-800.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom)
  .navbar-nav
  .dropdown-item:hover,
.navbar-theme-gray-800.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom)
  .navbar-nav
  .list-group-item:hover {
  color: #07090d;
  background: rgba(62, 79, 111, 0.5);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .dropdown-item:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .list-group-item:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom)
    .navbar-nav
    .show
    > .nav-link:hover {
    color: #07090d;
  }
}
.navbar-theme-gray-800.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-arrow,
.navbar-theme-gray-800.navbar-light:not(.headroom--not-top)
  .nav-link:hover
  .nav-link-inner-text {
  color: #506690;
}
.navbar-theme-gray-800.navbar-light.headroom--not-top .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800 .navbar-nav .list-group-item.active,
.navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
  color: #506690;
  background: rgba(80, 102, 144, 0.1);
}
@media (max-width: 991.98px) {
  .navbar-theme-gray-800 .navbar-nav .dropdown-item,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800 .navbar-nav .list-group-item,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:hover,
  .navbar-theme-gray-800 .navbar-nav .nav-link,
  .navbar-theme-gray-800 .navbar-nav .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:hover {
    color: #506690;
    background: 0 0;
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.disabled,
  .navbar-theme-gray-800 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.disabled {
    color: rgba(80, 102, 144, 0.3);
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown .dropdown-menu {
    padding: 5px 25px;
  }
  .navbar-theme-gray-800 .navbar-nav .dropdown-item {
    font-size: 1rem;
  }
  .navbar-theme-gray-800
    .navbar-nav
    .dropdown:not(.mega-dropdown)
    .dropdown-item {
    padding-left: 0;
  }
}
.navbar-transparent {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
@media (min-width: 992px) {
  .navbar-nav .nav-item [data-toggle='dropdown']::after {
    transition: all 0.2s ease;
  }
  .navbar-nav .nav-item.show [data-toggle='dropdown']::after {
    transform: rotate(180deg);
  }
  .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
  }
  .navbar-nav .nav-link i {
    margin-right: 0.3rem;
    font-size: 0.75rem;
  }
  .navbar-nav .nav-link-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
  .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
    border-radius: 0.2rem;
  }
  .navbar-nav .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }
  .navbar-nav .dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    animation: show-navbar-dropdown 0.25s ease forwards;
  }
  .navbar-nav .dropdown-menu.close {
    display: block;
    animation: hide-navbar-dropdown 0.15s ease backwards;
  }
  .navbar-nav .dropdown-menu {
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease;
  }
  .navbar-nav .dropdown-submenu:hover > .dropdown-menu,
  .navbar-nav .dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translate(0, -7px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-nav .dropdown:hover .nav-link > .nav-link-arrow {
    transform: rotate(180deg);
  }
  .navbar-nav .dropdown-submenu:hover .dropdown-item > .nav-link-arrow {
    transform: rotate(90deg);
  }
  .navbar-nav .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }
  .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-transparent .navbar-brand:focus,
  .navbar-transparent .navbar-brand:hover {
    color: rgba(255, 255, 255, 0.9);
  }
}
.navbar-collapse-header {
  display: none;
}
@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }
  .navbar-collapse {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    overflow-y: auto;
    height: 100vh !important;
    opacity: 0;
  }
  .navbar-collapse .navbar-toggler {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
  }
  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }
  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-collapse .collapse-brand img {
    height: 36px;
  }
  .navbar-collapse .collapse-close {
    text-align: right;
  }
  .dropdown.show .nav-link > .nav-link-arrow {
    transform: rotate(180deg);
  }
  .dropdown-submenu.show .dropdown-item > .nav-link-arrow {
    transform: rotate(90deg);
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.2rem;
    background: #fff;
    animation: show-navbar-collapse 0.2s ease forwards;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}
.section {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.section-lg,
.section-md,
.section-sm {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.section-header {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 4rem;
}
@media (min-width: 768px) {
  .section {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .section-header {
    position: relative;
    padding-top: 7rem;
    padding-bottom: 12rem;
  }
  .section-header.section-sm {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .section-xl {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .section-lg {
    position: relative;
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .section-sm {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media (min-width: 768px) {
  .section-hero {
    height: 100vh;
  }
}
.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 991.98px) {
  .section-profile-cover {
    height: 400px;
  }
}
.components-section > .form-control + .form-control {
  margin-top: 0.5rem;
}
.components-section .badge,
.components-section .btn,
.components-section > .alert + .alert,
.components-section > .nav + .nav,
.components-section > .navbar + .navbar,
.components-section > .progress + .btn,
.components-section > .progress + .progress {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.components-section .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.components-section .btn-group .btn {
  margin: 0;
}
.components-section .alert {
  margin: 0;
}
.components-section .alert + .alert {
  margin-top: 1.25rem;
}
.components-section .badge {
  margin-right: 0.5rem;
}
.components-section .modal-footer .btn {
  margin: 0;
}
.copy-docs {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.2s ease;
}
.copy-docs.copied {
  background: #00bf9a;
}
.copy-docs:hover {
  cursor: pointer;
}
.footer {
  display: flex;
  position: relative;
}
.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.footer ul li {
  display: inline-block;
}
.footer ul li a {
  text-decoration: none;
  position: relative;
  display: block;
  font-weight: 400;
}
.footer ul li .btn {
  margin: 0;
}
.footer ul.links-horizontal:first-child a {
  padding-left: 0;
}
.footer ul.links-horizontal:last-child a {
  padding-right: 0;
}
.footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}
.footer ul.links-vertical li a {
  padding: 5px;
}
.footer ul.links-vertical li a:hover {
  color: inherit !important;
}
.footer ul.icon-box i {
  line-height: 1.7;
}
.footer .social-buttons .btn,
.footer .social-buttons a {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.125rem 0.375rem;
}
.footer .footer-brand {
  font-size: 1.25rem;
  font-weight: 600;
}
.footer .footer-brand img,
.footer .footer-brand svg {
  width: 70px;
}
.footer .footer-brand:focus,
.footer .footer-brand:hover {
  color: #111;
}
.footer .copyright {
  font-size: 0.875rem;
}
.footer .pull-center {
  display: inline-block;
  float: none;
}
#doc-index:not(.collapse.show),
.doc-sidebar {
  display: none;
}
@media (min-width: 992px) {
  #doc-index {
    display: block;
  }
  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
  }
  .doc-sidebar .nav-link[data-toggle='collapse'] > .icon {
    transform: rotateZ(-90deg);
    position: relative;
    right: 0.25rem;
  }
  .doc-sidebar .nav .nav-item {
    font-size: 0.875rem;
  }
}
.accordion .card {
  margin-bottom: 1.5rem;
}
.accordion-panel-header {
  display: flex;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion-panel-header .icon {
  color: #2a354f;
  transition: all 0.2s ease;
}
.accordion-panel-header .icon i {
  font-size: 0.875rem;
}
.accordion-panel-header .icon-title {
  margin-right: 1rem;
}
.accordion-panel-header .icon-title i {
  margin-right: 1rem;
}
.accordion-panel-header .icon-title + .icon {
  height: 1rem;
}
.accordion-panel-header[aria-expanded='true'] > .icon {
  transform: rotateZ(45deg);
}
.accordion-panel-header:hover > *,
.accordion-panel-header[aria-expanded='true'] > * {
  color: #11ab7c !important;
}
.accordion-panel-header:hover > * i,
.accordion-panel-header[aria-expanded='true'] > * i {
  fill: #11ab7c !important;
}
.accordion-panel-header:hover {
  cursor: pointer;
}
.alert {
  padding: 1rem 1.5rem;
  border: 0;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}
.alert.alert-sm {
  padding: 0.5rem 1rem;
}
.alert .alert-inner--icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-right: 1.25rem;
  vertical-align: middle;
}
.alert .alert-inner--icon i {
  position: relative;
  top: 1px;
}
.alert .alert-inner--text {
  display: inline-block;
  color: #fff;
  vertical-align: middle;
}
.alert .alert-inner--text a {
  font-weight: 600;
}
.alert-heading {
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 0.15rem;
}
.alert-dismissible .close {
  top: 50%;
  right: 1.5rem;
  padding: 0;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.alert-dismissible .close:focus,
.alert-dismissible .close:hover {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1 !important;
}
@media (max-width: 575.98px) {
  .alert-dismissible .close {
    top: 1rem;
    right: 0.5rem;
  }
}
.alert-dismissible .close > span:not(.sr-only) {
  font-size: 1.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}
.alert-dismissible .close:focus > span:not(.sr-only),
.alert-dismissible .close:hover > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}
.alert-primary {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.alert-primary hr {
  border-top-color: #0f946b;
}
.alert-primary .alert-link {
  color: #fff;
}
.alert-secondary {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.alert-secondary hr {
  border-top-color: #004fbb;
}
.alert-secondary .alert-link {
  color: #fff;
}
.alert-success {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.alert-success hr {
  border-top-color: #00a685;
}
.alert-success .alert-link {
  color: #fff;
}
.alert-info {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.alert-info hr {
  border-top-color: #0583ff;
}
.alert-info .alert-link {
  color: #fff;
}
.alert-warning {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.alert-warning hr {
  border-top-color: #f4ad41;
}
.alert-warning .alert-link {
  color: #fff;
}
.alert-danger {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.alert-danger hr {
  border-top-color: #f93939;
}
.alert-danger .alert-link {
  color: #fff;
}
.alert-light {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.alert-light hr {
  border-top-color: #ccd2e4;
}
.alert-light .alert-link {
  color: #fff;
}
.alert-dark {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.alert-dark hr {
  border-top-color: #212a3e;
}
.alert-dark .alert-link {
  color: #fff;
}
.alert-tertiary {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.alert-tertiary hr {
  border-top-color: #cc5e1f;
}
.alert-tertiary .alert-link {
  color: #fff;
}
.alert-orange {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.alert-orange hr {
  border-top-color: #e67200;
}
.alert-orange .alert-link {
  color: #fff;
}
.alert-white {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #fff;
}
.alert-gray {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.alert-gray hr {
  border-top-color: #5b6d90;
}
.alert-gray .alert-link {
  color: #fff;
}
.alert-neutral {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.alert-neutral hr {
  border-top-color: #f2f2f2;
}
.alert-neutral .alert-link {
  color: #fff;
}
.alert-soft {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.alert-soft hr {
  border-top-color: #e1ebf3;
}
.alert-soft .alert-link {
  color: #fff;
}
.alert-black {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.alert-black hr {
  border-top-color: #333946;
}
.alert-black .alert-link {
  color: #fff;
}
.alert-purple {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.alert-purple hr {
  border-top-color: #7950dc;
}
.alert-purple .alert-link {
  color: #fff;
}
.alert-gray-100 {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.alert-gray-100 hr {
  border-top-color: #e1ebf3;
}
.alert-gray-100 .alert-link {
  color: #fff;
}
.alert-gray-200 {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.alert-gray-200 hr {
  border-top-color: #dadfe4;
}
.alert-gray-200 .alert-link {
  color: #fff;
}
.alert-gray-300 {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.alert-gray-300 hr {
  border-top-color: #ccd2e4;
}
.alert-gray-300 .alert-link {
  color: #fff;
}
.alert-gray-400 {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.alert-gray-400 hr {
  border-top-color: #cfd8e8;
}
.alert-gray-400 .alert-link {
  color: #fff;
}
.alert-gray-500 {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.alert-gray-500 hr {
  border-top-color: #9fa8b2;
}
.alert-gray-500 .alert-link {
  color: #fff;
}
.alert-gray-600 {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.alert-gray-600 hr {
  border-top-color: #768daf;
}
.alert-gray-600 .alert-link {
  color: #fff;
}
.alert-gray-700 {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.alert-gray-700 hr {
  border-top-color: #5b6d90;
}
.alert-gray-700 .alert-link {
  color: #fff;
}
.alert-gray-800 {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.alert-gray-800 hr {
  border-top-color: #475a80;
}
.alert-gray-800 .alert-link {
  color: #fff;
}
.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}
.avatar-link img {
  width: 4rem;
  height: 4rem;
}
.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
}
.avatar-group .avatar:hover {
  z-index: 3;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.badge {
  font-size: 66%;
  line-height: 1;
  font-weight: 700;
  padding: 0.35rem 0.5rem;
}
.badge a {
  color: #fff;
}
.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
}
.badge-inline {
  margin-right: 0.625rem;
}
.badge-inline + span {
  top: 2px;
  position: relative;
}
.badge-inline + span > a {
  text-decoration: underline;
}
.badge-md {
  padding: 0.4rem 0.55rem;
}
.badge-lg {
  font-size: 1rem;
}
.wi-tags a {
  display: inline-block;
  padding: 0.125rem 0.875rem;
  margin: 0.25rem;
  line-height: 2;
  font-size: 0.875rem;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  border: 1px solid #11ab7c;
  background-color: #fff;
}
.pixel-pro-badge {
  position: relative;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  right: -20px;
  padding: 5px 14px;
  top: -46px;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
@media (max-width: 767.98px) {
  .pixel-pro-badge {
    font-size: 1rem;
    right: -13px;
    padding: 5px 10px;
    top: -23px;
  }
}
.btn {
  position: relative;
  transition: all 0.2s ease;
  letter-spacing: 0.025em;
  font-size: 1rem;
  border-radius: 0.125rem;
}
.btn:hover {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.btn.btn-pill {
  border-radius: 2rem;
}
.btn.btn-circle {
  border-radius: 50%;
}
.btn.creative:hover {
  box-shadow: none;
}
.btn-group .btn,
.input-group .btn {
  margin-right: 0;
  transform: translateY(0);
}
.btn .toggle-arrow {
  transition: all 0.2s ease;
}
.btn[aria-expanded='true'] .toggle-arrow {
  transform: rotate(180deg);
}
.btn-xs,
.btn-xs i {
  font-size: 0.75rem !important;
}
.btn-group-sm > .btn,
.btn-group-sm > .btn i,
.btn-sm,
.btn-sm i {
  font-size: 0.875rem !important;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.85rem 1.75rem;
}
.btn-group-lg > .btn,
.btn-group-lg > .btn i,
.btn-lg,
.btn-lg i {
  font-size: 1rem !important;
}
.creative {
  background: 0 0;
  border: none;
  cursor: pointer;
}
.creative:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid #11ab7c;
  transition: all 0.2s ease;
  transform: translate(1px, 1px);
}
.creative:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 2px solid #11ab7c;
  transition: all 0.2s ease;
  transform: translate(-3px, -3px);
}
.creative:hover:after {
  transform: translate(0);
}
.creative:hover:before {
  transform: translate(0);
}
.creative:not(:last-child) {
  margin-right: 0.5rem;
}
[class*='btn-outline-'] {
  border-width: 2px;
}
.btn-outline-secondary {
  color: #005ad4;
}
.btn-inner-icon i:not(.fa) {
  position: relative;
}
.btn-link {
  font-weight: 600;
  box-shadow: none;
  padding: 0;
}
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  background-color: transparent;
  box-shadow: none;
  transform: none;
}
.btn-icon-label {
  position: relative;
}
.btn-icon-label .btn-inner-icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0;
  text-align: center;
  margin: 0;
  width: 3rem;
}
.btn-icon-label .btn-inner-icon:not(:first-child),
.btn-icon-label .btn-inner-icon:not(:last-child) {
  top: 0;
}
.btn-icon-label .btn-inner-icon:not(:first-child) {
  right: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.btn-icon-label .btn-inner-icon:not(:last-child) {
  left: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.btn-icon-label .btn-inner-icon svg {
  position: relative;
  top: 50%;
  transform: translate(0, -50px);
}
.btn-icon-label .btn-inner-text:not(:first-child) {
  padding-left: 2rem;
}
.btn-icon-label .btn-inner-text:not(:last-child) {
  padding-right: 2rem;
}
.btn-inner-icon i:not(.fa) {
  position: relative;
  top: 1px;
}
.btn-inner-icon img {
  width: 20px;
}
.btn-icon {
  display: inline-block;
}
.btn-icon .btn-inner-icon:first-of-type {
  margin-right: 0.3rem;
}
.btn-icon .btn-inner-icon:last-of-type {
  margin-left: 0.3rem;
}
.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0;
}
.btn-icon-only .btn-inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-icon-onlya {
  line-height: 2.5;
}
.btn-icon-only.btn-xs {
  width: 1.7rem;
  height: 1.7rem;
}
.btn-group-sm > .btn-icon-only.btn,
.btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem;
}
.spinner-border,
.spinner-brow {
  vertical-align: middle;
}
.btn-loading-overlay .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.btn-loading-overlay .btn-inner-text,
.btn-loading-overlay .spinner {
  transition: all 0.2s ease;
}
.btn-loading-overlay .btn-inner-text {
  opacity: 1;
}
.btn-loading-overlay.btn-loading .spinner {
  opacity: 1;
}
.btn-loading-overlay.btn-loading .btn-inner-text {
  opacity: 0;
}
.btn-group .dropdown-arrow,
.btn-vertical .dropdown-arrow {
  transition: all 0.2s ease;
}
.btn-group.show .dropdown-arrow,
.btn-vertical.show .dropdown-arrow {
  transform: rotate(180deg);
}
.btn-group.show.dropright .dropdown-arrow,
.btn-vertical.show.dropright .dropdown-arrow {
  transform: rotate(90deg);
}
.btn-group.show.dropleft .dropdown-arrow,
.btn-vertical.show.dropleft .dropdown-arrow {
  transform: rotate(-90deg);
}
.btn-group > .btn:hover,
.btn-vertical > .btn:hover {
  z-index: 0;
}
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-vertical > .btn.active,
.btn-vertical > .btn:active,
.btn-vertical > .btn:focus {
  z-index: 0;
}
.btn-primary {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-primary:hover {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(17, 171, 124, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #11ab7c;
  border-color: #11ab7c;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-secondary {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(0, 90, 212, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-success {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-success:hover {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(0, 191, 154, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00bf9a;
  border-color: #00bf9a;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-info {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-info:hover {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(30, 144, 255, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-warning {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-warning:hover {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(245, 183, 89, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f5b759;
  border-color: #f5b759;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-danger {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-danger:hover {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(250, 82, 82, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #fa5252;
  border-color: #fa5252;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-light {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-light:hover {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dark {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-dark:hover {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(42, 53, 79, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a354f;
  border-color: #2a354f;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-tertiary {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-tertiary:hover {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-tertiary.focus,
.btn-tertiary:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(222, 105, 38, 0.5);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-tertiary:not(:disabled):not(.disabled).active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.show > .btn-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #de6926;
  border-color: #de6926;
}
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-orange {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-orange:hover {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-orange.focus,
.btn-orange:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(255, 127, 0, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
}
.btn-orange:not(:disabled):not(.disabled).active:focus,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-white {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-white.focus,
.btn-white:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray:hover {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray.focus,
.btn-gray:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray:not(:disabled):not(.disabled).active:focus,
.btn-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-neutral {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-neutral:hover {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-neutral.focus,
.btn-neutral:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
}
.btn-neutral.disabled,
.btn-neutral:disabled {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active,
.show > .btn-neutral.dropdown-toggle {
  color: #506690;
  background-color: #fff;
  border-color: #fff;
}
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-soft {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-soft:hover {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-soft.focus,
.btn-soft:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-soft.disabled,
.btn-soft:disabled {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-soft:not(:disabled):not(.disabled).active,
.btn-soft:not(:disabled):not(.disabled):active,
.show > .btn-soft.dropdown-toggle {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-soft:not(:disabled):not(.disabled).active:focus,
.btn-soft:not(:disabled):not(.disabled):active:focus,
.show > .btn-soft.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-black {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-black:hover {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-black.focus,
.btn-black:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(62, 69, 85, 0.5);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #3e4555;
  border-color: #3e4555;
}
.btn-black:not(:disabled):not(.disabled).active:focus,
.btn-black:not(:disabled):not(.disabled):active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-purple {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-purple:hover {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-purple.focus,
.btn-purple:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(137, 101, 224, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #8965e0;
  border-color: #8965e0;
}
.btn-purple:not(:disabled):not(.disabled).active:focus,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-100 {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-gray-100:hover {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-gray-100.focus,
.btn-gray-100:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(243, 247, 250, 0.5);
}
.btn-gray-100.disabled,
.btn-gray-100:disabled {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-gray-100:not(:disabled):not(.disabled).active,
.btn-gray-100:not(:disabled):not(.disabled):active,
.show > .btn-gray-100.dropdown-toggle {
  color: #506690;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}
.btn-gray-100:not(:disabled):not(.disabled).active:focus,
.btn-gray-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-200 {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-gray-200:hover {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-gray-200.focus,
.btn-gray-200:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(233, 236, 239, 0.5);
}
.btn-gray-200.disabled,
.btn-gray-200:disabled {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-gray-200:not(:disabled):not(.disabled).active,
.btn-gray-200:not(:disabled):not(.disabled):active,
.show > .btn-gray-200.dropdown-toggle {
  color: #506690;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-gray-200:not(:disabled):not(.disabled).active:focus,
.btn-gray-200:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-300 {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-gray-300:hover {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-gray-300.focus,
.btn-gray-300:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(221, 225, 237, 0.5);
}
.btn-gray-300.disabled,
.btn-gray-300:disabled {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-gray-300:not(:disabled):not(.disabled).active,
.btn-gray-300:not(:disabled):not(.disabled):active,
.show > .btn-gray-300.dropdown-toggle {
  color: #506690;
  background-color: #dde1ed;
  border-color: #dde1ed;
}
.btn-gray-300:not(:disabled):not(.disabled).active:focus,
.btn-gray-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-400 {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-gray-400:hover {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-gray-400.focus,
.btn-gray-400:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(224, 230, 240, 0.5);
}
.btn-gray-400.disabled,
.btn-gray-400:disabled {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-gray-400:not(:disabled):not(.disabled).active,
.btn-gray-400:not(:disabled):not(.disabled):active,
.show > .btn-gray-400.dropdown-toggle {
  color: #506690;
  background-color: #e0e6f0;
  border-color: #e0e6f0;
}
.btn-gray-400:not(:disabled):not(.disabled).active:focus,
.btn-gray-400:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-500 {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-gray-500:hover {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-gray-500.focus,
.btn-gray-500:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(173, 181, 189, 0.5);
}
.btn-gray-500.disabled,
.btn-gray-500:disabled {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-gray-500:not(:disabled):not(.disabled).active,
.btn-gray-500:not(:disabled):not(.disabled):active,
.show > .btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}
.btn-gray-500:not(:disabled):not(.disabled).active:focus,
.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-600 {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-gray-600:hover {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-gray-600.focus,
.btn-gray-600:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(134, 154, 184, 0.5);
}
.btn-gray-600.disabled,
.btn-gray-600:disabled {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-gray-600:not(:disabled):not(.disabled).active,
.btn-gray-600:not(:disabled):not(.disabled):active,
.show > .btn-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #869ab8;
  border-color: #869ab8;
}
.btn-gray-600:not(:disabled):not(.disabled).active:focus,
.btn-gray-600:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-700 {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray-700:hover {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray-700.focus,
.btn-gray-700:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(102, 121, 158, 0.5);
}
.btn-gray-700.disabled,
.btn-gray-700:disabled {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray-700:not(:disabled):not(.disabled).active,
.btn-gray-700:not(:disabled):not(.disabled):active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #66799e;
  border-color: #66799e;
}
.btn-gray-700:not(:disabled):not(.disabled).active:focus,
.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray-800 {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-gray-800.focus,
.btn-gray-800:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(80, 102, 144, 0.5);
}
.btn-gray-800.disabled,
.btn-gray-800:disabled {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-gray-800:not(:disabled):not(.disabled).active,
.btn-gray-800:not(:disabled):not(.disabled):active,
.show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #506690;
  border-color: #506690;
}
.btn-gray-800:not(:disabled):not(.disabled).active:focus,
.btn-gray-800:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: none;
}
.creative.btn-primary {
  color: #11ab7c;
  background: 0 0;
}
.creative.btn-primary:after,
.creative.btn-primary:before {
  border: 2px solid #11ab7c;
}
.creative.btn-primary:hover {
  background: 0 0;
  color: #11ab7c;
}
.creative.btn-secondary {
  color: #005ad4;
  background: 0 0;
}
.creative.btn-secondary:after,
.creative.btn-secondary:before {
  border: 2px solid #005ad4;
}
.creative.btn-secondary:hover {
  background: 0 0;
  color: #005ad4;
}
.creative.btn-success {
  color: #00bf9a;
  background: 0 0;
}
.creative.btn-success:after,
.creative.btn-success:before {
  border: 2px solid #00bf9a;
}
.creative.btn-success:hover {
  background: 0 0;
  color: #00bf9a;
}
.creative.btn-info {
  color: #1e90ff;
  background: 0 0;
}
.creative.btn-info:after,
.creative.btn-info:before {
  border: 2px solid #1e90ff;
}
.creative.btn-info:hover {
  background: 0 0;
  color: #1e90ff;
}
.creative.btn-warning {
  color: #f5b759;
  background: 0 0;
}
.creative.btn-warning:after,
.creative.btn-warning:before {
  border: 2px solid #f5b759;
}
.creative.btn-warning:hover {
  background: 0 0;
  color: #f5b759;
}
.creative.btn-danger {
  color: #fa5252;
  background: 0 0;
}
.creative.btn-danger:after,
.creative.btn-danger:before {
  border: 2px solid #fa5252;
}
.creative.btn-danger:hover {
  background: 0 0;
  color: #fa5252;
}
.creative.btn-light {
  color: #dde1ed;
  background: 0 0;
}
.creative.btn-light:after,
.creative.btn-light:before {
  border: 2px solid #dde1ed;
}
.creative.btn-light:hover {
  background: 0 0;
  color: #dde1ed;
}
.creative.btn-dark {
  color: #2a354f;
  background: 0 0;
}
.creative.btn-dark:after,
.creative.btn-dark:before {
  border: 2px solid #2a354f;
}
.creative.btn-dark:hover {
  background: 0 0;
  color: #2a354f;
}
.creative.btn-tertiary {
  color: #de6926;
  background: 0 0;
}
.creative.btn-tertiary:after,
.creative.btn-tertiary:before {
  border: 2px solid #de6926;
}
.creative.btn-tertiary:hover {
  background: 0 0;
  color: #de6926;
}
.creative.btn-orange {
  color: #ff7f00;
  background: 0 0;
}
.creative.btn-orange:after,
.creative.btn-orange:before {
  border: 2px solid #ff7f00;
}
.creative.btn-orange:hover {
  background: 0 0;
  color: #ff7f00;
}
.creative.btn-white {
  color: #fff;
  background: 0 0;
}
.creative.btn-white:after,
.creative.btn-white:before {
  border: 2px solid #fff;
}
.creative.btn-white:hover {
  background: 0 0;
  color: #fff;
}
.creative.btn-gray {
  color: #66799e;
  background: 0 0;
}
.creative.btn-gray:after,
.creative.btn-gray:before {
  border: 2px solid #66799e;
}
.creative.btn-gray:hover {
  background: 0 0;
  color: #66799e;
}
.creative.btn-neutral {
  color: #fff;
  background: 0 0;
}
.creative.btn-neutral:after,
.creative.btn-neutral:before {
  border: 2px solid #fff;
}
.creative.btn-neutral:hover {
  background: 0 0;
  color: #fff;
}
.creative.btn-soft {
  color: #f3f7fa;
  background: 0 0;
}
.creative.btn-soft:after,
.creative.btn-soft:before {
  border: 2px solid #f3f7fa;
}
.creative.btn-soft:hover {
  background: 0 0;
  color: #f3f7fa;
}
.creative.btn-black {
  color: #3e4555;
  background: 0 0;
}
.creative.btn-black:after,
.creative.btn-black:before {
  border: 2px solid #3e4555;
}
.creative.btn-black:hover {
  background: 0 0;
  color: #3e4555;
}
.creative.btn-purple {
  color: #8965e0;
  background: 0 0;
}
.creative.btn-purple:after,
.creative.btn-purple:before {
  border: 2px solid #8965e0;
}
.creative.btn-purple:hover {
  background: 0 0;
  color: #8965e0;
}
.creative.btn-gray-100 {
  color: #f3f7fa;
  background: 0 0;
}
.creative.btn-gray-100:after,
.creative.btn-gray-100:before {
  border: 2px solid #f3f7fa;
}
.creative.btn-gray-100:hover {
  background: 0 0;
  color: #f3f7fa;
}
.creative.btn-gray-200 {
  color: #e9ecef;
  background: 0 0;
}
.creative.btn-gray-200:after,
.creative.btn-gray-200:before {
  border: 2px solid #e9ecef;
}
.creative.btn-gray-200:hover {
  background: 0 0;
  color: #e9ecef;
}
.creative.btn-gray-300 {
  color: #dde1ed;
  background: 0 0;
}
.creative.btn-gray-300:after,
.creative.btn-gray-300:before {
  border: 2px solid #dde1ed;
}
.creative.btn-gray-300:hover {
  background: 0 0;
  color: #dde1ed;
}
.creative.btn-gray-400 {
  color: #e0e6f0;
  background: 0 0;
}
.creative.btn-gray-400:after,
.creative.btn-gray-400:before {
  border: 2px solid #e0e6f0;
}
.creative.btn-gray-400:hover {
  background: 0 0;
  color: #e0e6f0;
}
.creative.btn-gray-500 {
  color: #adb5bd;
  background: 0 0;
}
.creative.btn-gray-500:after,
.creative.btn-gray-500:before {
  border: 2px solid #adb5bd;
}
.creative.btn-gray-500:hover {
  background: 0 0;
  color: #adb5bd;
}
.creative.btn-gray-600 {
  color: #869ab8;
  background: 0 0;
}
.creative.btn-gray-600:after,
.creative.btn-gray-600:before {
  border: 2px solid #869ab8;
}
.creative.btn-gray-600:hover {
  background: 0 0;
  color: #869ab8;
}
.creative.btn-gray-700 {
  color: #66799e;
  background: 0 0;
}
.creative.btn-gray-700:after,
.creative.btn-gray-700:before {
  border: 2px solid #66799e;
}
.creative.btn-gray-700:hover {
  background: 0 0;
  color: #66799e;
}
.creative.btn-gray-800 {
  color: #506690;
  background: 0 0;
}
.creative.btn-gray-800:after,
.creative.btn-gray-800:before {
  border: 2px solid #506690;
}
.creative.btn-gray-800:hover {
  background: 0 0;
  color: #506690;
}
.btn-facebook {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook.focus,
.btn-facebook:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(59, 89, 153, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #3b5999;
  border-color: #3b5999;
}
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-facebook.btn-link {
  color: #3b5999;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-facebook.btn-link.active,
.btn-facebook.btn-link:focus,
.btn-facebook.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #3b5999;
}
.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter.focus,
.btn-twitter:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(29, 161, 242, 0.5);
}
.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-twitter.btn-link {
  color: #1da1f2;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-twitter.btn-link.active,
.btn-twitter.btn-link:focus,
.btn-twitter.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #1da1f2;
}
.btn-google {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google.focus,
.btn-google:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(221, 75, 57, 0.5);
}
.btn-google.disabled,
.btn-google:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-google.btn-link {
  color: #dd4b39;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-google.btn-link.active,
.btn-google.btn-link:focus,
.btn-google.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #dd4b39;
}
.btn-instagram {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram.focus,
.btn-instagram:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(228, 64, 95, 0.5);
}
.btn-instagram.disabled,
.btn-instagram:disabled {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled).active,
.btn-instagram:not(:disabled):not(.disabled):active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #e4405f;
  border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-instagram.btn-link {
  color: #e4405f;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-instagram.btn-link.active,
.btn-instagram.btn-link:focus,
.btn-instagram.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #e4405f;
}
.btn-pinterest {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:hover {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest.focus,
.btn-pinterest:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(189, 8, 28, 0.5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled).active,
.btn-pinterest:not(:disabled):not(.disabled):active,
.show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #bd081c;
  border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-pinterest.btn-link {
  color: #bd081c;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-pinterest.btn-link.active,
.btn-pinterest.btn-link:focus,
.btn-pinterest.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #bd081c;
}
.btn-youtube {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:hover {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube.focus,
.btn-youtube:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(205, 32, 31, 0.5);
}
.btn-youtube.disabled,
.btn-youtube:disabled {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:not(:disabled):not(.disabled).active,
.btn-youtube:not(:disabled):not(.disabled):active,
.show > .btn-youtube.dropdown-toggle {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f;
}
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-youtube.btn-link {
  color: #cd201f;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-youtube.btn-link.active,
.btn-youtube.btn-link:focus,
.btn-youtube.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #cd201f;
}
.btn-slack {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:hover {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack.focus,
.btn-slack:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(58, 175, 133, 0.5);
}
.btn-slack.disabled,
.btn-slack:disabled {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:not(:disabled):not(.disabled).active,
.btn-slack:not(:disabled):not(.disabled):active,
.show > .btn-slack.dropdown-toggle {
  color: #fff;
  background-color: #3aaf85;
  border-color: #3aaf85;
}
.btn-slack:not(:disabled):not(.disabled).active:focus,
.btn-slack:not(:disabled):not(.disabled):active:focus,
.show > .btn-slack.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-slack.btn-link {
  color: #3aaf85;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-slack.btn-link.active,
.btn-slack.btn-link:focus,
.btn-slack.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #3aaf85;
}
.btn-vimeo {
  color: #fff;
  background-color: #4ebbff;
  border-color: #4ebbff;
}
.btn-vimeo:hover {
  color: #fff;
  background-color: #4ebbff;
  border-color: #4ebbff;
}
.btn-vimeo.focus,
.btn-vimeo:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(78, 187, 255, 0.5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: #fff;
  background-color: #4ebbff;
  border-color: #4ebbff;
}
.btn-vimeo:not(:disabled):not(.disabled).active,
.btn-vimeo:not(:disabled):not(.disabled):active,
.show > .btn-vimeo.dropdown-toggle {
  color: #fff;
  background-color: #4ebbff;
  border-color: #4ebbff;
}
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-vimeo.btn-link {
  color: #4ebbff;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-vimeo.btn-link.active,
.btn-vimeo.btn-link:focus,
.btn-vimeo.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #4ebbff;
}
.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble.focus,
.btn-dribbble:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(234, 76, 137, 0.5);
}
.btn-dribbble.disabled,
.btn-dribbble:disabled {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled).active,
.btn-dribbble:not(:disabled):not(.disabled):active,
.show > .btn-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dribbble.btn-link {
  color: #ea4c89;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-dribbble.btn-link.active,
.btn-dribbble.btn-link:focus,
.btn-dribbble.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #ea4c89;
}
.btn-dropbox {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-dropbox:hover {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-dropbox.focus,
.btn-dropbox:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(30, 144, 255, 0.5);
}
.btn-dropbox.disabled,
.btn-dropbox:disabled {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-dropbox:not(:disabled):not(.disabled).active,
.btn-dropbox:not(:disabled):not(.disabled):active,
.show > .btn-dropbox.dropdown-toggle {
  color: #fff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}
.btn-dropbox:not(:disabled):not(.disabled).active:focus,
.btn-dropbox:not(:disabled):not(.disabled):active:focus,
.show > .btn-dropbox.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dropbox.btn-link {
  color: #1e90ff;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-dropbox.btn-link.active,
.btn-dropbox.btn-link:focus,
.btn-dropbox.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #1e90ff;
}
.btn-twitch {
  color: #fff;
  background-color: #4b367c;
  border-color: #4b367c;
}
.btn-twitch:hover {
  color: #fff;
  background-color: #4b367c;
  border-color: #4b367c;
}
.btn-twitch.focus,
.btn-twitch:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(75, 54, 124, 0.5);
}
.btn-twitch.disabled,
.btn-twitch:disabled {
  color: #fff;
  background-color: #4b367c;
  border-color: #4b367c;
}
.btn-twitch:not(:disabled):not(.disabled).active,
.btn-twitch:not(:disabled):not(.disabled):active,
.show > .btn-twitch.dropdown-toggle {
  color: #fff;
  background-color: #4b367c;
  border-color: #4b367c;
}
.btn-twitch:not(:disabled):not(.disabled).active:focus,
.btn-twitch:not(:disabled):not(.disabled):active:focus,
.show > .btn-twitch.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-twitch.btn-link {
  color: #4b367c;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-twitch.btn-link.active,
.btn-twitch.btn-link:focus,
.btn-twitch.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #4b367c;
}
.btn-paypal {
  color: #fff;
  background-color: #ecb32c;
  border-color: #ecb32c;
}
.btn-paypal:hover {
  color: #fff;
  background-color: #ecb32c;
  border-color: #ecb32c;
}
.btn-paypal.focus,
.btn-paypal:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(236, 179, 44, 0.5);
}
.btn-paypal.disabled,
.btn-paypal:disabled {
  color: #fff;
  background-color: #ecb32c;
  border-color: #ecb32c;
}
.btn-paypal:not(:disabled):not(.disabled).active,
.btn-paypal:not(:disabled):not(.disabled):active,
.show > .btn-paypal.dropdown-toggle {
  color: #fff;
  background-color: #ecb32c;
  border-color: #ecb32c;
}
.btn-paypal:not(:disabled):not(.disabled).active:focus,
.btn-paypal:not(:disabled):not(.disabled):active:focus,
.show > .btn-paypal.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-paypal.btn-link {
  color: #ecb32c;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-paypal.btn-link.active,
.btn-paypal.btn-link:focus,
.btn-paypal.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #ecb32c;
}
.btn-behance {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}
.btn-behance:hover {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}
.btn-behance.focus,
.btn-behance:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(0, 87, 255, 0.5);
}
.btn-behance.disabled,
.btn-behance:disabled {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}
.btn-behance:not(:disabled):not(.disabled).active,
.btn-behance:not(:disabled):not(.disabled):active,
.show > .btn-behance.dropdown-toggle {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}
.btn-behance:not(:disabled):not(.disabled).active:focus,
.btn-behance:not(:disabled):not(.disabled):active:focus,
.show > .btn-behance.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-behance.btn-link {
  color: #0057ff;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-behance.btn-link.active,
.btn-behance.btn-link:focus,
.btn-behance.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #0057ff;
}
.btn-reddit {
  color: #fff;
  background-color: #e84422;
  border-color: #e84422;
}
.btn-reddit:hover {
  color: #fff;
  background-color: #e84422;
  border-color: #e84422;
}
.btn-reddit.focus,
.btn-reddit:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(232, 68, 34, 0.5);
}
.btn-reddit.disabled,
.btn-reddit:disabled {
  color: #fff;
  background-color: #e84422;
  border-color: #e84422;
}
.btn-reddit:not(:disabled):not(.disabled).active,
.btn-reddit:not(:disabled):not(.disabled):active,
.show > .btn-reddit.dropdown-toggle {
  color: #fff;
  background-color: #e84422;
  border-color: #e84422;
}
.btn-reddit:not(:disabled):not(.disabled).active:focus,
.btn-reddit:not(:disabled):not(.disabled):active:focus,
.show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-reddit.btn-link {
  color: #e84422;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-reddit.btn-link.active,
.btn-reddit.btn-link:focus,
.btn-reddit.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #e84422;
}
.btn-github {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-github:hover {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-github.focus,
.btn-github:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(34, 34, 34, 0.5);
}
.btn-github.disabled,
.btn-github:disabled {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-github:not(:disabled):not(.disabled).active,
.btn-github:not(:disabled):not(.disabled):active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-github:not(:disabled):not(.disabled).active:focus,
.btn-github:not(:disabled):not(.disabled):active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-github.btn-link {
  color: #222;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-github.btn-link.active,
.btn-github.btn-link:focus,
.btn-github.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #222;
}
.btn-linkedin {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
}
.btn-linkedin:hover {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
}
.btn-linkedin.focus,
.btn-linkedin:focus {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05),
    0 0 0 0.0625rem rgba(14, 118, 168, 0.5);
}
.btn-linkedin.disabled,
.btn-linkedin:disabled {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
}
.btn-linkedin:not(:disabled):not(.disabled).active,
.btn-linkedin:not(:disabled):not(.disabled):active,
.show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
}
.btn-linkedin:not(:disabled):not(.disabled).active:focus,
.btn-linkedin:not(:disabled):not(.disabled):active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-linkedin.btn-link {
  color: #0e76a8;
  background: 0 0;
  box-shadow: none;
  border: 0;
  border-style: none;
}
.btn-linkedin.btn-link.active,
.btn-linkedin.btn-link:focus,
.btn-linkedin.btn-link:hover {
  background-color: transparent !important;
  box-shadow: none;
  border: 0;
  color: #0e76a8;
}
.breadcrumb-item,
.breadcrumb-item a {
  color: #66799e;
  font-weight: 400;
  font-size: 0.75rem;
}
@media (min-width: 576px) {
  .breadcrumb-item,
  .breadcrumb-item a {
    font-size: 1rem;
  }
}
.breadcrumb-item.active {
  font-weight: 400;
}
.breadcrumb-item + .breadcrumb-item:before {
  content: '\f101';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 0.625rem;
  color: #66799e;
}
.breadcrumb-transparent {
  background: 0 0;
  padding: 0;
}
.breadcrumb-primary {
  background: #11ab7c;
}
.breadcrumb-primary .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-primary.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
  color: #11ab7c;
}
.breadcrumb-secondary {
  background: #005ad4;
}
.breadcrumb-secondary .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-secondary.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-secondary.breadcrumb-transparent .breadcrumb-item.active {
  color: #005ad4;
}
.breadcrumb-success {
  background: #00bf9a;
}
.breadcrumb-success .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-success.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-success.breadcrumb-transparent .breadcrumb-item.active {
  color: #00bf9a;
}
.breadcrumb-info {
  background: #1e90ff;
}
.breadcrumb-info .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-info.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-info.breadcrumb-transparent .breadcrumb-item.active {
  color: #1e90ff;
}
.breadcrumb-warning {
  background: #f5b759;
}
.breadcrumb-warning .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-warning.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-warning.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5b759;
}
.breadcrumb-danger {
  background: #fa5252;
}
.breadcrumb-danger .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-danger.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-danger.breadcrumb-transparent .breadcrumb-item.active {
  color: #fa5252;
}
.breadcrumb-light {
  background: #dde1ed;
}
.breadcrumb-light .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-light.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-light.breadcrumb-transparent .breadcrumb-item.active {
  color: #dde1ed;
}
.breadcrumb-dark {
  background: #2a354f;
}
.breadcrumb-dark .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-dark.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-dark.breadcrumb-transparent .breadcrumb-item.active {
  color: #2a354f;
}
.breadcrumb-tertiary {
  background: #de6926;
}
.breadcrumb-tertiary .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-tertiary.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-tertiary.breadcrumb-transparent .breadcrumb-item.active {
  color: #de6926;
}
.breadcrumb-orange {
  background: #ff7f00;
}
.breadcrumb-orange .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-orange.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-orange.breadcrumb-transparent .breadcrumb-item.active {
  color: #ff7f00;
}
.breadcrumb-white {
  background: #fff;
}
.breadcrumb-white .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-white.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-white.breadcrumb-transparent .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray {
  background: #66799e;
}
.breadcrumb-gray .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray.breadcrumb-transparent .breadcrumb-item.active {
  color: #66799e;
}
.breadcrumb-neutral {
  background: #fff;
}
.breadcrumb-neutral .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-neutral.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-neutral.breadcrumb-transparent .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-soft {
  background: #f3f7fa;
}
.breadcrumb-soft .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-soft.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-soft.breadcrumb-transparent .breadcrumb-item.active {
  color: #f3f7fa;
}
.breadcrumb-black {
  background: #3e4555;
}
.breadcrumb-black .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-black.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-black.breadcrumb-transparent .breadcrumb-item.active {
  color: #3e4555;
}
.breadcrumb-purple {
  background: #8965e0;
}
.breadcrumb-purple .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-purple.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-purple.breadcrumb-transparent .breadcrumb-item.active {
  color: #8965e0;
}
.breadcrumb-gray-100 {
  background: #f3f7fa;
}
.breadcrumb-gray-100 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-100.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-100.breadcrumb-transparent .breadcrumb-item.active {
  color: #f3f7fa;
}
.breadcrumb-gray-200 {
  background: #e9ecef;
}
.breadcrumb-gray-200 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-200.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-200.breadcrumb-transparent .breadcrumb-item.active {
  color: #e9ecef;
}
.breadcrumb-gray-300 {
  background: #dde1ed;
}
.breadcrumb-gray-300 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-300.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-300.breadcrumb-transparent .breadcrumb-item.active {
  color: #dde1ed;
}
.breadcrumb-gray-400 {
  background: #e0e6f0;
}
.breadcrumb-gray-400 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-400.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-400.breadcrumb-transparent .breadcrumb-item.active {
  color: #e0e6f0;
}
.breadcrumb-gray-500 {
  background: #adb5bd;
}
.breadcrumb-gray-500 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-500.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-500.breadcrumb-transparent .breadcrumb-item.active {
  color: #adb5bd;
}
.breadcrumb-gray-600 {
  background: #869ab8;
}
.breadcrumb-gray-600 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-600.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-600.breadcrumb-transparent .breadcrumb-item.active {
  color: #869ab8;
}
.breadcrumb-gray-700 {
  background: #66799e;
}
.breadcrumb-gray-700 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-700.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-700.breadcrumb-transparent .breadcrumb-item.active {
  color: #66799e;
}
.breadcrumb-gray-800 {
  background: #506690;
}
.breadcrumb-gray-800 .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-gray-800.breadcrumb-transparent {
  background: 0 0;
}
.breadcrumb-gray-800.breadcrumb-transparent .breadcrumb-item.active {
  color: #506690;
}
.breadcrumb-text-light .breadcrumb-item,
.breadcrumb-text-light .breadcrumb-item a {
  color: #fff;
}
.breadcrumb-text-light .breadcrumb-item:before {
  color: #fff;
}
.blog-card .card.img-card {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.blog-card .card-header {
  background: 0 0;
}
.blog-card .card-body .bottom-content {
  position: absolute;
  bottom: 10px;
}
.blog-card .card-footer {
  background: 0 0;
  padding: 1rem 1rem;
}
.blog-card .avatar-sm-custom img {
  border: 0.125rem solid #005ad4;
  padding: 0.25rem;
  width: 50px;
  height: 50px;
}
.blog-card .card-img-top {
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-card .card-article-wide img {
  height: 100%;
}
.card-img-overlay {
  padding: 0;
}
.card-img-overlay .card-footer,
.card-img-overlay .card-header {
  background: 0 0;
  border-color: #66799e;
}
.card {
  position: relative;
}
.card .card-header {
  background: 0 0;
}
.card.img-card {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.card.card-article-wide img {
  height: 100%;
}
.card .listing-badge {
  bottom: 15px;
  right: 15px;
}
@media (min-width: 768px) {
  .card.card-row .card-body {
    padding: 4rem 4rem;
  }
}
@media (min-width: 768px) {
  .card.card-row .card-meta {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (max-width: 991.98px) {
  .card-group {
    flex-direction: column;
  }
}
.card-meta {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 4rem 4rem;
}
.profile-page .card-profile {
  margin-top: -150px;
}
.profile-page .card-profile .card-profile-image {
  position: relative;
}
.profile-page .card-profile .card-profile-image img {
  max-width: 180px;
  border-radius: 0.5rem;
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  transition: all 0.2s ease;
}
.profile-page .card-profile .card-profile-image img:hover {
  transform: translate(-50%, -33%);
}
.profile-page .card-profile .card-profile-stats {
  padding: 1rem 0;
}
.profile-page .card-profile .card-profile-stats > div {
  text-align: center;
  margin-right: 1rem;
  padding: 0.875rem;
}
.profile-page .card-profile .card-profile-stats > div:last-child {
  margin-right: 0;
}
.profile-page .card-profile .card-profile-stats > div .heading {
  display: block;
  font-size: 1.1rem;
  font-weight: 700;
}
.profile-page .card-profile .card-profile-stats > div .description {
  font-size: 0.875rem;
  color: #adb5bd;
}
.profile-page .card-profile .card-profile-actions {
  padding: 0.875rem;
}
@media (max-width: 575.98px) {
  .profile-page .card-profile .card-profile-actions {
    margin-top: 110px;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .profile-page .card-profile .card-profile-stats {
    margin-top: 30px;
  }
}
.card-footer {
  background-color: transparent;
}
.card .card-blockquote {
  position: relative;
  padding: 2rem;
}
.card .card-blockquote .svg-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: 95px;
  top: -94px;
  left: 0;
}
.card-lift-hover:hover {
  transform: translateY(-20px);
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .card-lift-hover:hover {
    transition: none;
  }
}
.carousel-caption {
  bottom: 50px;
}
.carousel-caption,
.carousel-caption h5 {
  color: #fff;
}
.carousel-indicators {
  padding-bottom: 20px;
}
.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: 0 0;
  border: 2px solid #fff;
}
.carousel-indicators .active {
  opacity: 1;
  background: #fff;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: auto;
  height: auto;
  font-weight: 900;
}
.carousel-control-prev-icon {
  background-image: none;
}
.carousel-control-prev-icon::before {
  content: '\f060';
  font-family: 'Font Awesome 5 Free';
  font-size: 2rem;
}
.carousel-control-next-icon {
  background-image: none;
}
.carousel-control-next-icon:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f061';
  font-size: 2rem;
}
.close {
  transition: all 0.2s ease;
}
.close > span:not(.sr-only) {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  border-radius: 50%;
  font-size: 1.25rem;
  transition: all 0.2s ease;
}
.close:focus,
.close:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: 0;
}
.close:focus span:not(.sr-only),
.close:hover span:not(.sr-only) {
  background-color: transparent;
}
.counter-alternate::after {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  font-size: 2.25rem;
  color: #2a354f;
  font-weight: 900;
}
.custom-control-label:before {
  border: 0.125rem solid #f3f7fa;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}
.custom-control-label span {
  position: relative;
  top: 2px;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-input:active ~ .custom-control-label::before {
  border-color: #11ab7c;
}
.custom-file-label {
  border: 0.0625rem solid #f3f7fa;
  font-size: 1rem;
  color: #adb5bd;
  padding: 0.725rem 0.65rem;
}
.custom-file-label::after {
  height: 100%;
  padding: 0.725rem 0.65rem;
  background-color: #f3f7fa;
  border: 0;
}
.custom-file-input:not(:disabled):hover {
  cursor: pointer;
}
.custom-file-input:not(:disabled):hover ~ .custom-file-label,
.custom-file-input:not(:disabled):hover ~ .custom-file-label:before {
  border-color: #dde1ed;
}
.custom-select {
  font-size: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}
.custom-select .item-sort[class*='col-'],
.custom-select.item-sort:not(.input-group-btn) {
  display: inline-block;
}
.custom-select:hover {
  cursor: pointer;
}
.dropdown-menu {
  min-width: 12rem;
}
.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}
.dropdown-menu .dropdown-header {
  color: #3e4555;
  font-weight: 600;
}
.dropdown-menu .dropdown-item {
  color: #66799e;
  transition: all 0.2s ease;
  font-weight: 300;
}
.dropdown-menu .dropdown-itema:hover {
  color: #3e4555;
}
.show .dropdown-menu {
  animation: show-dropdown 0.2s ease forwards;
}
[data-toggle]:hover {
  cursor: pointer;
}
.dropdown-toggle:after,
.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:after,
.dropup .dropdown-toggle:after {
  display: none;
}
.dropdown-menu-sm {
  min-width: 100px;
  border: 0.3rem;
}
.dropdown-menu-md {
  min-width: 180px;
  border: 0.3rem;
}
.dropdown-menu-lg {
  min-width: 260px;
  border-radius: 0.3rem;
}
.dropdown-menu-xl {
  min-width: 450px;
  border-radius: 0.3rem;
}
label {
  font-size: 0.875rem;
}
.form-group {
  position: relative;
}
.form-control {
  font-size: 1rem;
  border-radius: 0.5rem;
  box-shadow: none;
}
.form-control:focus {
  border-color: #b7c3d2;
  background-color: #fafbfe;
  outline: none;
}
.form-control:focus:placeholder {
  color: #adb5bd;
}
textarea[resize='none'] {
  resize: none !important;
}
textarea[resize='both'] {
  resize: both !important;
}
textarea[resize='vertical'] {
  resize: vertical !important;
}
textarea[resize='horizontal'] {
  resize: horizontal !important;
}
.form-control-muted {
  background-color: #f3f7fa;
  border-color: #f3f7fa;
  box-shadow: none;
}
.form-control-muted:focus {
  background-color: #f3f7fa;
}
.form-control-shadow {
  box-shadow: 0.2rem 0.2rem 0 rgba(233, 236, 239, 0.5);
  border: 0;
  transition: box-shadow 0.15s ease;
}
.form-control-shadow:focus {
  box-shadow: 0.1rem 0.1rem 0 rgba(233, 236, 239, 0.5);
}
.form-control-lg {
  font-size: 1rem;
}
.form-control-xl {
  height: calc(2.25em + 1.75rem + 0.0625rem);
  padding: 0.875rem 1rem;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.shadow-group-danger,
.shadow-group-success {
  box-shadow: 0.2rem 0.2rem 0 rgba(233, 236, 239, 0.5);
}
.has-danger .form-control:-ms-input-placeholder {
  color: #fa5252;
}
.has-danger .form-control::-ms-input-placeholder {
  color: #fa5252;
}
.has-danger .form-control::placeholder {
  color: #fa5252;
}
.has-success .form-control:-ms-input-placeholder {
  color: #00bf9a;
}
.has-success .form-control::-ms-input-placeholder {
  color: #00bf9a;
}
.has-success .form-control::placeholder {
  color: #00bf9a;
}
.form-check {
  margin-top: 0.5rem;
  padding-left: 0;
}
.form-check .form-check-label {
  display: inline-block;
  position: initial;
  padding-left: 2rem;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 0;
  transition: all 0.2s ease;
  color: #869ab8;
}
@media (prefers-reduced-motion: reduce) {
  .form-check .form-check-label {
    transition: none;
  }
}
.form-check .form-check-label:hover {
  cursor: pointer;
}
.radio .form-check-sign {
  padding-left: 28px;
}
.form-check-radio.form-check-inline .form-check-label {
  padding-left: 5px;
  margin-right: 10px;
}
.form-check .form-check-sign::after,
.form-check .form-check-sign::before {
  content: ' ';
  display: inline-block;
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  border: 0.0625rem solid #dde1ed;
  background-color: #fff;
  border-radius: 0.125rem;
  transition: all 0.2s ease;
}
@media (prefers-reduced-motion: reduce) {
  .form-check .form-check-sign::after,
  .form-check .form-check-sign::before {
    transition: none;
  }
}
.form-check .form-check-sign::after:hover,
.form-check .form-check-sign::before:hover {
  cursor: pointer;
}
.form-check input[type='checkbox']:checked + .form-check-sign::before {
  border: none;
}
.form-check .form-check-sign::after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  top: 0;
  text-align: center;
  opacity: 0;
  color: #11ab7c;
  font-weight: 900;
  border: 0;
  background-color: inherit;
}
.form-check.disabled .form-check-label {
  color: #adb5bd;
  cursor: not-allowed;
}
.form-check input[type='checkbox'],
.radio input[type='radio'] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.form-check input[type='checkbox']:checked + .form-check-sign::after {
  background-color: #fff;
  opacity: 1;
  font-size: 1rem;
  margin-top: 0;
  line-height: 1.6;
}
.form-check input[type='checkbox']:disabled + .form-check-sign::after {
  color: rgba(17, 171, 124, 0.7);
}
.form-check input[type='checkbox'] + .form-check-sign::after {
  opacity: 0;
}
.checkbox input[type='checkbox']:disabled + .form-check-sign::after,
.form-control input[type='checkbox']:disabled + .form-check-sign::before {
  cursor: not-allowed;
}
.form-check input[type='checkbox']:disabled + .form-check-sign,
.form-check input[type='radio']:disabled + .form-check-sign {
  pointer-events: none;
}
.form-check-radio .form-check-label {
  padding-top: 3px;
}
.form-check-radio .form-check-sign::after,
.form-check-radio .form-check-sign::before {
  content: ' ';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  padding: 1px;
  transition: opacity 0.3s linear;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-radio .form-check-sign::after,
  .form-check-radio .form-check-sign::before {
    transition: none;
  }
}
.form-check-radio input[type='radio'],
.form-check-radio input[type='radio'] + .form-check-sign:after {
  opacity: 0;
}
.form-check-radio input[type='radio']:checked + .form-check-sign::after {
  width: 8px;
  height: 8px;
  top: 8px;
  left: 5px;
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.form-check-radio input[type='radio']:checked + .form-check-sign::before {
  background-color: #11ab7c;
}
.form-check-radio input[type='radio']:checked + .form-check-sign::after {
  opacity: 1;
}
.form-check-radio input[type='radio']:disabled + .form-check-sign::before {
  color: rgba(17, 171, 124, 0.7);
}
.form-check-radio input[type='radio']:disabled + .form-check-sign::after,
.form-check-radio input[type='radio']:disabled + .form-check-sign::before {
  color: #adb5bd;
}
.round-check .form-check-sign::after,
.round-check .form-check-sign::before {
  border-radius: 50%;
}
.custom-toggle {
  position: relative;
  display: inline-block;
  margin: 0;
}
.custom-toggle.custom-toggle-sm {
  height: 1.3rem;
  width: 2rem;
}
.custom-toggle.custom-toggle-sm .custom-toggle-slider {
  width: 2rem;
}
.custom-toggle.custom-toggle-sm .custom-toggle-slider:before {
  width: 0.8rem;
  height: 0.8rem;
}
.custom-toggle.custom-toggle-sm input:checked + .custom-toggle-slider:before {
  transform: translateX(0.8rem);
}
.custom-toggle.custom-toggle-md {
  height: 1.6rem;
  width: 3rem;
}
.custom-toggle.custom-toggle-md .custom-toggle-slider {
  width: 3rem;
}
.custom-toggle.custom-toggle-md .custom-toggle-slider:before {
  height: 1.1rem;
  width: 1.1rem;
}
.custom-toggle.custom-toggle-md input:checked + .custom-toggle-slider:before {
  transform: translateX(1.5rem);
}
.custom-toggle.custom-toggle-lg {
  height: 1.9rem;
  width: 3.8rem;
}
.custom-toggle.custom-toggle-lg .custom-toggle-slider {
  width: 3.8rem;
}
.custom-toggle.custom-toggle-lg .custom-toggle-slider:before {
  width: 1.4rem;
  height: 1.4rem;
}
.custom-toggle.custom-toggle-lg input:checked + .custom-toggle-slider:before {
  transform: translateX(1.9rem);
}
.custom-toggle input {
  display: none;
}
.custom-toggle input:checked + .custom-toggle-slider {
  background-color: #11ab7c;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #fff;
}
.custom-toggle input:disabled + .custom-toggle-slider {
  background-color: #e9ecef;
}
.custom-toggle input:disabled + .custom-toggle-slider:before {
  background: #fff;
}
.custom-toggle input:disabled + .custom-toggle-slider:hover {
  cursor: not-allowed;
}
.custom-toggle input:disabled:checked + .custom-toggle-slider {
  background-color: rgba(17, 171, 124, 0.7);
}
.custom-toggle input:disabled:checked + .custom-toggle-slider:before {
  background-color: #fff;
}
.custom-toggle-slider {
  position: absolute;
  top: 0;
  left: -2px;
  bottom: 3px;
  border-radius: 0.125rem;
  background-color: #dde1ed;
}
.custom-toggle-slider:before {
  content: '';
  position: absolute;
  border-radius: 0.125rem;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.custom-toggle-slider.rounded-toggle {
  border-radius: 0.8rem;
}
.custom-toggle-slider.rounded-toggle:before {
  border-radius: 50%;
}
.stepwizard-step p {
  margin-top: 10px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 50%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.icon-box {
  padding: 1rem;
  position: relative;
}
.icon-box .icon-box-body {
  position: relative;
}
.icon-box.image-box {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.icon-box.big-parallax {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.icon-box.border-parallax {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-position: fixed;
}
.icon-bordered {
  width: 5rem;
  height: 5rem;
}
.icon-bordered i {
  line-height: 2;
}
.icon {
  text-align: center;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.icon i,
.icon svg {
  font-size: 2rem;
}
.icon.icon-xs i,
.icon.icon-xs svg {
  font-size: 0.875rem;
}
.icon.icon-sm i,
.icon.icon-sm svg {
  font-size: 1.5rem;
}
.icon.icon-lg i,
.icon.icon-lg svg {
  font-size: 3rem;
}
.icon.icon-xl i,
.icon.icon-xl svg {
  font-size: 4rem;
}
.icon .organic-shape {
  margin-left: 0.5rem;
}
.icon.rounded-circle .icon-bordered {
  border-radius: 50%;
}
.icon.organic-radius .icon-bordered {
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
}
.icon-shape {
  width: 4.5rem;
  height: 4.5rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-shape i,
.icon-shape svg {
  font-size: 2rem;
}
.icon-shape.icon-sm {
  width: 3rem;
  height: 3rem;
}
.icon-shape.icon-sm i,
.icon-shape.icon-sm svg {
  font-size: 1.5rem;
}
.icon-shape.icon-lg {
  width: 6rem;
  height: 6rem;
}
.icon-shape.icon-lg i,
.icon-shape.icon-lg svg {
  font-size: 4rem;
}
.image-shape {
  width: 6rem;
  height: 6rem;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-shape i {
  font-size: 2rem;
  z-index: 1;
}
.image-shape.icon-parallax {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  background-repeat: no-repeat;
}
.star {
  margin-right: 0.3rem;
}
.icon-shape-primary {
  color: #11ab7c;
  background-color: rgba(17, 171, 124, 0.2);
}
.icon-primary {
  color: #11ab7c;
}
.icon-shape-secondary {
  color: #005ad4;
  background-color: rgba(0, 90, 212, 0.2);
}
.icon-secondary {
  color: #005ad4;
}
.icon-shape-success {
  color: #00bf9a;
  background-color: rgba(0, 191, 154, 0.2);
}
.icon-success {
  color: #00bf9a;
}
.icon-shape-info {
  color: #1e90ff;
  background-color: rgba(30, 144, 255, 0.2);
}
.icon-info {
  color: #1e90ff;
}
.icon-shape-warning {
  color: #f5b759;
  background-color: rgba(245, 183, 89, 0.2);
}
.icon-warning {
  color: #f5b759;
}
.icon-shape-danger {
  color: #fa5252;
  background-color: rgba(250, 82, 82, 0.2);
}
.icon-danger {
  color: #fa5252;
}
.icon-shape-light {
  color: #dde1ed;
  background-color: rgba(221, 225, 237, 0.2);
}
.icon-light {
  color: #dde1ed;
}
.icon-shape-dark {
  color: #2a354f;
  background-color: rgba(42, 53, 79, 0.2);
}
.icon-dark {
  color: #2a354f;
}
.icon-shape-tertiary {
  color: #de6926;
  background-color: rgba(222, 105, 38, 0.2);
}
.icon-tertiary {
  color: #de6926;
}
.icon-shape-orange {
  color: #ff7f00;
  background-color: rgba(255, 127, 0, 0.2);
}
.icon-orange {
  color: #ff7f00;
}
.icon-shape-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}
.icon-white {
  color: #fff;
}
.icon-shape-gray {
  color: #66799e;
  background-color: rgba(102, 121, 158, 0.2);
}
.icon-gray {
  color: #66799e;
}
.icon-shape-neutral {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}
.icon-neutral {
  color: #fff;
}
.icon-shape-soft {
  color: #f3f7fa;
  background-color: rgba(243, 247, 250, 0.2);
}
.icon-soft {
  color: #f3f7fa;
}
.icon-shape-black {
  color: #3e4555;
  background-color: rgba(62, 69, 85, 0.2);
}
.icon-black {
  color: #3e4555;
}
.icon-shape-purple {
  color: #8965e0;
  background-color: rgba(137, 101, 224, 0.2);
}
.icon-purple {
  color: #8965e0;
}
.icon-shape-gray-100 {
  color: #f3f7fa;
  background-color: rgba(243, 247, 250, 0.2);
}
.icon-gray-100 {
  color: #f3f7fa;
}
.icon-shape-gray-200 {
  color: #e9ecef;
  background-color: rgba(233, 236, 239, 0.2);
}
.icon-gray-200 {
  color: #e9ecef;
}
.icon-shape-gray-300 {
  color: #dde1ed;
  background-color: rgba(221, 225, 237, 0.2);
}
.icon-gray-300 {
  color: #dde1ed;
}
.icon-shape-gray-400 {
  color: #e0e6f0;
  background-color: rgba(224, 230, 240, 0.2);
}
.icon-gray-400 {
  color: #e0e6f0;
}
.icon-shape-gray-500 {
  color: #adb5bd;
  background-color: rgba(173, 181, 189, 0.2);
}
.icon-gray-500 {
  color: #adb5bd;
}
.icon-shape-gray-600 {
  color: #869ab8;
  background-color: rgba(134, 154, 184, 0.2);
}
.icon-gray-600 {
  color: #869ab8;
}
.icon-shape-gray-700 {
  color: #66799e;
  background-color: rgba(102, 121, 158, 0.2);
}
.icon-gray-700 {
  color: #66799e;
}
.icon-shape-gray-800 {
  color: #506690;
  background-color: rgba(80, 102, 144, 0.2);
}
.icon-gray-800 {
  color: #506690;
}
.image-lg {
  height: 12rem;
}
.image-md {
  height: 5rem;
}
.image-sm {
  height: 3rem;
}
.img-thumbnail {
  border-width: 0.125rem;
  box-shadow: none;
}
.full-image {
  height: 100%;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.gallery-feed img {
  width: 20%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
}
.video-poster {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-poster a {
  position: absolute;
}
@media (min-width: 576px) {
  .effect-img-2 {
    position: absolute;
    right: 5rem;
    top: 19%;
    z-index: 2;
    margin: 0;
  }
  .effect-img-1,
  .effect-img-2 {
    margin: 0 0 3rem;
    width: 350px;
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .effect-img-2 {
    right: 0.425rem;
    top: 0;
  }
}
.image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.image-container img {
  display: block;
  width: 100%;
}
.image-label {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #005ad4;
  padding: 1em;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
}
.image-label.is-hidden {
  visibility: hidden;
}
.is-visible .image-label {
  opacity: 1;
  transform: translateY(0);
}
.resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}
.resize-img .image-label {
  right: auto;
  left: 0;
}
.is-visible .resize-img {
  width: 50%;
}
.handle {
  position: absolute;
  height: 44px;
  width: 44px;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border-radius: 50%;
  cursor: move;
  box-shadow: 0 0 0 6px rgba(17, 171, 124, 0.2),
    0 0 10px rgba(17, 171, 124, 0.6), inset 0 1px 0 rgba(255, 255, 255, 0.3);
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0);
}
.handle.draggable {
  background-color: #11ab7c;
}
.is-visible .handle {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}
.input-group {
  transition: all 0.2s ease;
}
.input-group .form-control {
  box-shadow: none;
}
.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0.4rem;
}
.input-group .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}
.input-group .form-control:focus {
  box-shadow: none;
}
.input-group-text {
  font-size: 0.875rem;
  transition: all 0.3s ease-in-out;
}
.input-group-shadow {
  box-shadow: 0.2rem 0.2rem 0 rgba(233, 236, 239, 0.5);
  border: 0;
  transition: box-shadow 0.15s ease;
}
.input-group-shadow .form-control,
.input-group-shadow .input-group-text {
  border: 0;
  box-shadow: none;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: 0.125rem;
}
.input-group-prepend {
  margin-right: -2px;
}
.focused .input-group-text {
  color: #869ab8;
  background-color: #fff;
}
.focused .input-group-shadow {
  box-shadow: 0.1rem 0.1rem 0 rgba(233, 236, 239, 0.5);
}
.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #005ad4;
  border-color: #005ad4;
}
.list-group-item i {
  width: 1rem;
}
.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}
.list-group-content {
  flex: 1;
  min-width: 0;
}
.list-group-content > p {
  color: #adb5bd;
  line-height: 1.5;
  margin: 0.2rem 0 0;
}
.list-group-heading {
  font-size: 1rem;
  color: #506690;
}
.list-group-heading > small {
  float: right;
  color: #adb5bd;
  font-weight: 500;
}
.list-group-flush .list-group-item {
  background-color: transparent;
}
.list-group.simple-list .list-group-item {
  background: 0 0;
  border: none;
  padding: 0.375rem 0.125rem;
}
.list-group.simple-list .list-group-item i {
  vertical-align: middle;
  width: 35px;
  display: inline-block;
}
.news-list .row > [class*='col'] {
  padding: 0 0.25rem;
}
.news-list > li:not(:last-child) {
  margin-bottom: 0.75rem;
}
.news-list img {
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.map {
  height: 400px;
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
.modal.static-example {
  position: relative;
  display: block;
}
.modal-content {
  border: 0;
  border-radius: 0.5rem;
}
.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}
.modal-primary .modal-title {
  color: #fff;
}
.modal-primary .modal-footer,
.modal-primary .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-primary .modal-content {
  background-color: #11ab7c;
  color: #fff;
}
.modal-primary .modal-content .heading {
  color: #fff;
}
.modal-primary .close > span:not(.sr-only) {
  color: #fff;
}
.modal-secondary .modal-title {
  color: #fff;
}
.modal-secondary .modal-footer,
.modal-secondary .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-secondary .modal-content {
  background-color: #005ad4;
  color: #fff;
}
.modal-secondary .modal-content .heading {
  color: #fff;
}
.modal-secondary .close > span:not(.sr-only) {
  color: #fff;
}
.modal-success .modal-title {
  color: #fff;
}
.modal-success .modal-footer,
.modal-success .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-success .modal-content {
  background-color: #00bf9a;
  color: #fff;
}
.modal-success .modal-content .heading {
  color: #fff;
}
.modal-success .close > span:not(.sr-only) {
  color: #fff;
}
.modal-info .modal-title {
  color: #fff;
}
.modal-info .modal-footer,
.modal-info .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-info .modal-content {
  background-color: #1e90ff;
  color: #fff;
}
.modal-info .modal-content .heading {
  color: #fff;
}
.modal-info .close > span:not(.sr-only) {
  color: #fff;
}
.modal-warning .modal-title {
  color: #fff;
}
.modal-warning .modal-footer,
.modal-warning .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-warning .modal-content {
  background-color: #f5b759;
  color: #fff;
}
.modal-warning .modal-content .heading {
  color: #fff;
}
.modal-warning .close > span:not(.sr-only) {
  color: #fff;
}
.modal-danger .modal-title {
  color: #fff;
}
.modal-danger .modal-footer,
.modal-danger .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-danger .modal-content {
  background-color: #fa5252;
  color: #fff;
}
.modal-danger .modal-content .heading {
  color: #fff;
}
.modal-danger .close > span:not(.sr-only) {
  color: #fff;
}
.modal-light .modal-title {
  color: #506690;
}
.modal-light .modal-footer,
.modal-light .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-light .modal-content {
  background-color: #dde1ed;
  color: #506690;
}
.modal-light .modal-content .heading {
  color: #506690;
}
.modal-light .close > span:not(.sr-only) {
  color: #fff;
}
.modal-dark .modal-title {
  color: #fff;
}
.modal-dark .modal-footer,
.modal-dark .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-dark .modal-content {
  background-color: #2a354f;
  color: #fff;
}
.modal-dark .modal-content .heading {
  color: #fff;
}
.modal-dark .close > span:not(.sr-only) {
  color: #fff;
}
.modal-tertiary .modal-title {
  color: #fff;
}
.modal-tertiary .modal-footer,
.modal-tertiary .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-tertiary .modal-content {
  background-color: #de6926;
  color: #fff;
}
.modal-tertiary .modal-content .heading {
  color: #fff;
}
.modal-tertiary .close > span:not(.sr-only) {
  color: #fff;
}
.modal-orange .modal-title {
  color: #fff;
}
.modal-orange .modal-footer,
.modal-orange .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-orange .modal-content {
  background-color: #ff7f00;
  color: #fff;
}
.modal-orange .modal-content .heading {
  color: #fff;
}
.modal-orange .close > span:not(.sr-only) {
  color: #fff;
}
.modal-white .modal-title {
  color: #506690;
}
.modal-white .modal-footer,
.modal-white .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-white .modal-content {
  background-color: #fff;
  color: #506690;
}
.modal-white .modal-content .heading {
  color: #506690;
}
.modal-white .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray .modal-title {
  color: #fff;
}
.modal-gray .modal-footer,
.modal-gray .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-gray .modal-content {
  background-color: #66799e;
  color: #fff;
}
.modal-gray .modal-content .heading {
  color: #fff;
}
.modal-gray .close > span:not(.sr-only) {
  color: #fff;
}
.modal-neutral .modal-title {
  color: #506690;
}
.modal-neutral .modal-footer,
.modal-neutral .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-neutral .modal-content {
  background-color: #fff;
  color: #506690;
}
.modal-neutral .modal-content .heading {
  color: #506690;
}
.modal-neutral .close > span:not(.sr-only) {
  color: #fff;
}
.modal-soft .modal-title {
  color: #506690;
}
.modal-soft .modal-footer,
.modal-soft .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-soft .modal-content {
  background-color: #f3f7fa;
  color: #506690;
}
.modal-soft .modal-content .heading {
  color: #506690;
}
.modal-soft .close > span:not(.sr-only) {
  color: #fff;
}
.modal-black .modal-title {
  color: #fff;
}
.modal-black .modal-footer,
.modal-black .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-black .modal-content {
  background-color: #3e4555;
  color: #fff;
}
.modal-black .modal-content .heading {
  color: #fff;
}
.modal-black .close > span:not(.sr-only) {
  color: #fff;
}
.modal-purple .modal-title {
  color: #fff;
}
.modal-purple .modal-footer,
.modal-purple .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-purple .modal-content {
  background-color: #8965e0;
  color: #fff;
}
.modal-purple .modal-content .heading {
  color: #fff;
}
.modal-purple .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-100 .modal-title {
  color: #506690;
}
.modal-gray-100 .modal-footer,
.modal-gray-100 .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-gray-100 .modal-content {
  background-color: #f3f7fa;
  color: #506690;
}
.modal-gray-100 .modal-content .heading {
  color: #506690;
}
.modal-gray-100 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-200 .modal-title {
  color: #506690;
}
.modal-gray-200 .modal-footer,
.modal-gray-200 .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-gray-200 .modal-content {
  background-color: #e9ecef;
  color: #506690;
}
.modal-gray-200 .modal-content .heading {
  color: #506690;
}
.modal-gray-200 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-300 .modal-title {
  color: #506690;
}
.modal-gray-300 .modal-footer,
.modal-gray-300 .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-gray-300 .modal-content {
  background-color: #dde1ed;
  color: #506690;
}
.modal-gray-300 .modal-content .heading {
  color: #506690;
}
.modal-gray-300 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-400 .modal-title {
  color: #506690;
}
.modal-gray-400 .modal-footer,
.modal-gray-400 .modal-header {
  border-color: rgba(80, 102, 144, 0.075);
}
.modal-gray-400 .modal-content {
  background-color: #e0e6f0;
  color: #506690;
}
.modal-gray-400 .modal-content .heading {
  color: #506690;
}
.modal-gray-400 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-500 .modal-title {
  color: #fff;
}
.modal-gray-500 .modal-footer,
.modal-gray-500 .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-gray-500 .modal-content {
  background-color: #adb5bd;
  color: #fff;
}
.modal-gray-500 .modal-content .heading {
  color: #fff;
}
.modal-gray-500 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-600 .modal-title {
  color: #fff;
}
.modal-gray-600 .modal-footer,
.modal-gray-600 .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-gray-600 .modal-content {
  background-color: #869ab8;
  color: #fff;
}
.modal-gray-600 .modal-content .heading {
  color: #fff;
}
.modal-gray-600 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-700 .modal-title {
  color: #fff;
}
.modal-gray-700 .modal-footer,
.modal-gray-700 .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-gray-700 .modal-content {
  background-color: #66799e;
  color: #fff;
}
.modal-gray-700 .modal-content .heading {
  color: #fff;
}
.modal-gray-700 .close > span:not(.sr-only) {
  color: #fff;
}
.modal-gray-800 .modal-title {
  color: #fff;
}
.modal-gray-800 .modal-footer,
.modal-gray-800 .modal-header {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-gray-800 .modal-content {
  background-color: #506690;
  color: #fff;
}
.modal-gray-800 .modal-content .heading {
  color: #fff;
}
.modal-gray-800 .close > span:not(.sr-only) {
  color: #fff;
}
.nav-link {
  color: #869ab8;
}
.nav-link.active,
.nav-link:hover {
  color: #11ab7c;
}
.nav-link.active img,
.nav-link:hover img {
  opacity: inherit;
  transition: all 0.2s ease;
}
.nav-link i {
  position: relative;
  margin-right: 0.5rem;
}
.nav-link img {
  opacity: 0.5;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
}
.nav-tabs.secondary-tabs {
  border-color: #f3f7fa;
}
.nav-tabs.secondary-tabs .nav-link {
  border-radius: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-tabs.secondary-tabs .nav-link:hover {
  border-color: transparent;
}
.nav-tabs .nav-link {
  padding: 1.25rem 1.625rem;
  border-radius: 0.5rem;
}
.nav-tabs .nav-link:hover {
  border: 0.0625rem solid #f3f7fa;
}
.nav-tabs .nav-link.active {
  background: #f3f7fa;
  padding: 1.25rem 1.625rem;
  border: 0.0625rem solid #f3f7fa;
}
.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}
.nav-pills .nav-link {
  padding: 0.75rem 0.85rem;
  transition: all 0.2s ease;
}
.nav-pills .nav-link.avatar-link {
  border: 0;
}
.nav-pills .nav-link:hover {
  color: #11ab7c;
  border-color: #11ab7c;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #11ab7c;
  background-color: #fff;
  border-color: #11ab7c;
}
.nav-pills .nav-link.active.avatar-link,
.nav-pills .show > .nav-link.avatar-link {
  background: 0 0;
}
.nav-pills.rounded .nav-link {
  border-radius: 30px;
}
.nav-pills.rounded.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
  min-width: 100px;
}
.nav-pills.rounded.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pills.bordered-pill-md .nav-link {
  border: 0.125rem solid #e9ecef;
  font-weight: 600;
}
.nav-pills.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
}
.nav-pills.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
@media (max-width: 575.98px) {
  .nav-pills .nav-item {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0;
  }
}
.nav-pill-rounded .nav-link {
  display: flex;
  text-align: center;
  height: 55px;
  width: 55px;
  padding: 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
}
.nav-pill-rounded .nav-link.avatar-link {
  box-shadow: none;
}
.nav-pill-rounded.vertical-tab .nav-link-icon i,
.nav-pill-rounded.vertical-tab .nav-link-icon svg {
  font-size: 1.5rem;
}
.nav-pill-rounded.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pill-rounded .nav-link-icon i,
.nav-pill-rounded .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
}
.nav-pill-square .nav-link {
  text-align: center;
  min-width: 80px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pill-square.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
  min-width: 100px;
}
.nav-pill-square.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}
.nav-pill-square .nav-link-icon i,
.nav-pill-square .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
  line-height: 50px;
}
.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-wrapper + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .tab-pane pre {
  padding: 0;
  margin: 0;
}
.tab-content > .active {
  display: block;
}
.pixel-tab .col-lg-2 {
  padding-right: 0;
  position: relative;
  z-index: 1;
}
.pixel-tab .col-lg-10 {
  padding-left: 0;
}
.pixel-tab .nav-pills {
  border-right: 0;
}
.pixel-tab .nav-pills .nav-link {
  padding: 30px 25px;
  margin-bottom: 0.625rem;
  box-shadow: none;
  border-right: 0;
}
.pixel-tab .nav-pills .nav-link h2 {
  color: #2a354f;
}
.pixel-tab .nav-pills .nav-link.active {
  background: #fff;
  border-left: 0.25rem solid #2a354f;
  border-radius: 0;
  box-shadow: none;
}
.pixel-tab .nav-pills .nav-link.active h2 {
  color: #2a354f;
}
.pixel-tab .tab-content {
  background-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.pixel-tab .tab-content .pricing-box {
  padding: 50px 70px;
  z-index: 2;
  position: relative;
}
.pixel-tab .tab-content .list-inline {
  margin-top: 20px;
}
.pixel-tab .tab-content .list-inline li {
  padding: 10px 0;
  font-weight: 400;
}
.pixel-tab .tab-content .list-inline li span {
  display: inline-block;
  vertical-align: middle;
}
.pixel-tab .tab-content .list-inline li i {
  font-size: 1.5rem;
  color: #11ab7c;
  vertical-align: middle;
  width: 50px;
  overflow: hidden;
  display: inline-block;
}
.pixel-tab .tab-content .bg-image {
  background-size: cover;
  position: relative;
  display: flex;
}
.pixel-tab .tab-content .bg-image .description-box {
  z-index: 1;
}
@media (min-width: 576px) {
  .pixel-tab .nav-pills .nav-link {
    border: 0.0625rem solid #f3f7fa;
  }
}
@media (max-width: 767.98px) {
  .pixel-tab .col-lg-2 {
    padding-right: 15px;
  }
  .pixel-tab .col-lg-10 {
    padding-left: 15px;
  }
  .pixel-tab .nav-pills {
    border: 0;
  }
  .pixel-tab .nav-pills .nav-link {
    background-color: #f3f7fa;
    border: 0;
    margin-bottom: 0;
  }
  .pixel-tab .nav-pills .nav-link.active {
    border-left: 0;
    border-top: 0.25rem solid #2a354f;
  }
  .pixel-tab .tab-content .pricing-box {
    padding: 20px;
  }
}
.owl-theme .owl-nav {
  margin-top: 2rem;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*='owl-'] {
  display: inline-block;
  margin: 5px;
  padding: 4px 7px;
  background: #e0e6f0;
  cursor: pointer;
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: transparent;
  color: #11ab7c;
  text-decoration: none;
}
.owl-theme .owl-nav [class*='owl-']:focus {
  outline: 0;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 25px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 5px;
  background: 0 0;
  border: 2px solid #11ab7c;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #11ab7c;
}
.owl-theme .owl-dots .owl-dot:focus {
  outline: 0;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  color: #e0e6f0;
}
.owl-carousel .owl-item img.avatar-sm {
  height: 4rem;
  width: 4rem;
}
.owl-carousel .owl-nav button.owl-prev {
  margin-right: 1rem;
}
.owl-carousel .owl-nav button.owl-prev {
  margin-left: 1rem;
}
.about-carousel-wrapper {
  position: relative;
}
.about-carousel-wrapper::before {
  position: absolute;
  content: '';
  background-color: #e0e6f0;
  width: calc(100% - 45px);
  height: 100%;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
}
.about-carousel-wrapper .about-carousel {
  margin-left: 70px;
  top: -60px;
  background-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  padding: 20px;
  margin-top: 80px;
  width: calc(100% - 60px);
}
.about-carousel-wrapper .owl-dots {
  position: absolute;
  bottom: -45px;
  right: 25px;
}
.about-carousel-wrapper .owl-dots .owl-dot span {
  border: 2px solid #11ab7c;
}
.about-carousel-wrapper .owl-dots .owl-dot.active span,
.about-carousel-wrapper .owl-dots .owl-dot:hover span {
  background: #11ab7c;
}
.about-carousel-wrapper .owl-carousel .owl-item > .single-slider > img {
  width: 100%;
}
.testimonial-slider-wrapper .testimonial-carousel {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
  background-color: #fff;
  position: relative;
  z-index: 4;
  padding-left: 45px;
  padding-right: 80px;
}
.testimonial-slider-wrapper .star-lists {
  margin-bottom: 30px;
}
.testimonial-slider-wrapper .star-lists li:not(:last-child) {
  margin-right: 2px;
}
.testimonial-slider-wrapper .star-lists li {
  color: #f5b759;
  font-size: 1rem;
  display: inline-block;
}
.testimonial-slider-wrapper .portfolio-testimonial-person img {
  margin: auto;
  max-width: 350px;
}
.testimonial-slider-wrapper .vertical-dot .owl-dots {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial-slider-wrapper .vertical-dot .owl-dots .owl-dot {
  display: block;
}
.testimonial-slider-wrapper .vertical-dot .owl-dots .owl-dot span {
  border: 2px solid #11ab7c;
}
.testimonial-slider-wrapper .vertical-dot .owl-dots .owl-dot.active span,
.testimonial-slider-wrapper .vertical-dot .owl-dots .owl-dot:hover span {
  background: #11ab7c;
}
.testimonial-slider-wrapper .vertical-dot .owl-dots .owl-dot:not(:last-child) {
  margin-right: 0;
  margin-bottom: 12px;
}
.testimonial-slider-wrapper .vertical-dot .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.page-link {
  border: 0;
}
.circle-pagination .page-link,
.circle-pagination span {
  display: flex;
  border-radius: 50% !important;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0;
}
.page-item.active .page-link {
  box-shadow: 0 1.5rem 4rem rgba(42, 53, 79, 0.05);
}
.page-item .page-link,
.page-item span {
  margin: 0 3px;
  font-size: 0.875rem;
  color: #2a354f;
  font-weight: 600;
}
.pagination-lg .page-item .page-link,
.pagination-lg .page-item span {
  width: 46px;
  height: 46px;
  line-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-sm .page-item .page-link,
.pagination-sm .page-item span {
  display: flex;
  width: 30px;
  height: 30px;
  line-height: 30px;
  align-items: center;
  justify-content: center;
}
.pricing-card .card-header {
  background: 0 0;
  z-index: 2;
}
.pricing-card .card-body {
  z-index: 2;
}
.pricing-card .pricing-value {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 0.375rem;
  border-style: double;
  margin: 0 auto;
}
.pricing-card .pricing-value span {
  line-height: 140px;
}
.pricing-card .list-group-item {
  background: 0 0;
  border: none;
  padding: 0.375rem 0.125rem;
}
.pricing-card .list-group-item i {
  display: inline-block;
  width: 25px;
}
@media (max-width: 991.98px) {
  .pricing-card {
    margin-top: 0rem;
  }
}
.profile-card .card-image .card-title {
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
}
.profile-card .card-image .dropdown {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.profile-card .profile-cover {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 175px;
}
.profile-card .profile-image {
  width: 10rem;
  min-height: 10rem;
  margin: 0 auto;
  transform: translateY(-3rem);
}
.profile-card .profile-image-small {
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
}
.profile-card .profile-image-small img {
  border: 0.25rem solid #fff;
}
.profile-card .card-link {
  font-size: 0.875rem;
}
.profile-card .card-footer {
  background-color: #fff;
}
.profile-card .social-buttons {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.profile-card .social-buttons li {
  display: inline-block;
}
.profile-card .social-buttons li a.btn {
  padding: 0.25rem 0.375rem;
}
.rotating-card-container .card-rotate {
  background: 0 0;
  box-shadow: none;
}
.rotating-card-container .card-rotate:after {
  display: none;
}
.rotating-card-container .card {
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transform-style: preserve-3d;
  position: relative;
}
.rotating-card-container .card .back,
.rotating-card-container .card .front {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-align-content: center;
  align-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
}
.rotating-card-container .card .back .card-body,
.rotating-card-container .card .front .card-body {
  justify-content: center;
  align-content: center;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
}
.rotating-card-container .card .back .card-body .card-subtitle,
.rotating-card-container .card .front .card-body .card-subtitle {
  color: #11ab7c;
}
.rotating-card-container .card .back .card-body .social-buttons,
.rotating-card-container .card .front .card-body .social-buttons {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.rotating-card-container .card .back .card-body .social-buttons li,
.rotating-card-container .card .front .card-body .social-buttons li {
  display: inline-block;
}
.rotating-card-container .card .back .card-body .social-buttons li a.btn,
.rotating-card-container .card .front .card-body .social-buttons li a.btn {
  padding: 0.25rem 0.375rem;
}
.rotating-card-container .card .front {
  z-index: 2;
  position: relative;
}
.rotating-card-container .card .front .profile-image {
  width: 9rem;
  height: 9rem;
  margin: 0 auto;
}
.rotating-card-container .card .front .profile-image img {
  border-width: 0.125rem;
  border-style: solid;
  padding: 0.25rem;
}
.rotating-card-container .card .back {
  transform: rotateY(180deg);
  z-index: 5;
  text-align: center;
  width: 100%;
  height: 100%;
}
.rotating-card-container .card .back.back-background:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: '';
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 0.5rem;
}
.rotating-card-container .card .back.back-background .card-body {
  position: relative;
  z-index: 2;
}
.rotating-card-container .card .back .card-footer .btn {
  margin: 0;
}
.rotating-card-container .card .back .card-body {
  padding-left: 15px;
  padding-right: 15px;
}
.rotating-card-container:not(.manual-flip):hover .card {
  transform: rotateY(180deg);
}
.rotating-card-container.hover.manual-flip .card {
  transform: rotateY(180deg);
}
.card-profile .rotating-card-container .front {
  text-align: left;
}
.back-background .card-body {
  min-height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.back-background,
.front-background {
  background-position: center center;
  background-size: cover;
}
.profile-card-image {
  text-align: center;
}
.profile-card-image:hover .card:after {
  background: #fff;
}
.profile-card-image:hover .card-body,
.profile-card-image:hover .social-buttons {
  opacity: 1 !important;
  transform: translate(0, -50%);
}
.profile-card-image .card {
  position: relative;
  overflow: hidden;
}
.profile-card-image .card:after {
  display: block;
  background: 0 0;
  position: absolute;
  content: ' ';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
}
.profile-card-image .card .card-body,
.profile-card-image .card .social-buttons {
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  opacity: 0;
  z-index: 2;
  bottom: 0;
}
.profile-card-image .card .card-body {
  position: absolute;
}
.profile-card-image .card .card-body h6 {
  color: #005ad4;
}
.profile-card-image .card .social-buttons {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.profile-card-image .card .social-buttons li {
  display: inline-block;
}
.profile-card-image .card .social-buttons li a.btn {
  padding: 0.25rem 0.375rem;
}
.profile-card-alternative {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
}
.profile-card-alternative img {
  width: 100%;
  display: block;
}
.profile-card-alternative:hover .team-mask {
  opacity: 1;
  transform: scale(1);
  cursor: pointer;
}
.profile-card-alternative:hover .social-buttons,
.profile-card-alternative:hover h5,
.profile-card-alternative:hover p {
  opacity: 1;
  transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
}
.profile-card-alternative.over-hide {
  overflow: hidden;
}
.profile-card-alternative.organic-radius .team-mask {
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
}
.profile-card-alternative.rounded-circle .team-mask {
  border-radius: 50%;
}
.profile-card-alternative h4 {
  width: 100%;
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
}
@media (max-width: 991.98px) {
  .profile-card-alternative h4 {
    font-size: 1.25rem;
  }
}
.profile-card-alternative .card-subtitle {
  position: absolute;
  color: #506690;
  font-size: 0.875rem;
  margin-top: -1rem;
  left: 0;
  width: 100%;
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
}
.profile-card-alternative .social-buttons {
  margin-top: 2.75rem;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  opacity: 0;
}
.profile-card-alternative .social-buttons li {
  display: inline-block;
  position: relative;
}
.profile-card-alternative .social-buttons li a.btn {
  padding: 0.25rem 0.375rem;
}
.team-mask {
  position: absolute;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  left: 0.25rem;
  top: 0.25rem;
  z-index: 1;
  display: block;
  opacity: 0;
  transition: all 0.3s linear;
}
@media (prefers-reduced-motion: reduce) {
  .team-mask {
    transition: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rotating-card-container .card .back,
  .rotating-card-container .card .front {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
  }
  .rotating-card-container .card .back {
    visibility: hidden;
    transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
  }
  .rotating-card-container .card .front {
    z-index: 4;
  }
  .rotating-card-container.manual-flip.hover .card .back,
  .rotating-card-container:not(.manual-flip):hover .card .back {
    z-index: 5;
    visibility: visible;
  }
}
.popover {
  border: 0;
}
.popover-header {
  font-weight: 600;
}
.popover-primary {
  background-color: #11ab7c;
}
.popover-primary .popover-header {
  background-color: #11ab7c;
  color: #fff;
}
.popover-primary .popover-body {
  color: #fff;
}
.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-primary.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-primary.bs-popover-top .arrow::after {
  border-top-color: #11ab7c;
}
.popover-primary.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-primary.bs-popover-right .arrow::after {
  border-right-color: #11ab7c;
}
.popover-primary.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-primary.bs-popover-bottom .arrow::after {
  border-bottom-color: #11ab7c;
}
.popover-primary.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-primary.bs-popover-left .arrow::after {
  border-left-color: #11ab7c;
}
.popover-secondary {
  background-color: #005ad4;
}
.popover-secondary .popover-header {
  background-color: #005ad4;
  color: #fff;
}
.popover-secondary .popover-body {
  color: #fff;
}
.popover-secondary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-secondary.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-secondary.bs-popover-top .arrow::after {
  border-top-color: #005ad4;
}
.popover-secondary.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-secondary.bs-popover-right .arrow::after {
  border-right-color: #005ad4;
}
.popover-secondary.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-secondary.bs-popover-bottom .arrow::after {
  border-bottom-color: #005ad4;
}
.popover-secondary.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-secondary.bs-popover-left .arrow::after {
  border-left-color: #005ad4;
}
.popover-success {
  background-color: #00bf9a;
}
.popover-success .popover-header {
  background-color: #00bf9a;
  color: #fff;
}
.popover-success .popover-body {
  color: #fff;
}
.popover-success .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-success.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-success.bs-popover-top .arrow::after {
  border-top-color: #00bf9a;
}
.popover-success.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-success.bs-popover-right .arrow::after {
  border-right-color: #00bf9a;
}
.popover-success.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-success.bs-popover-bottom .arrow::after {
  border-bottom-color: #00bf9a;
}
.popover-success.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-success.bs-popover-left .arrow::after {
  border-left-color: #00bf9a;
}
.popover-info {
  background-color: #1e90ff;
}
.popover-info .popover-header {
  background-color: #1e90ff;
  color: #fff;
}
.popover-info .popover-body {
  color: #fff;
}
.popover-info .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-info.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-info.bs-popover-top .arrow::after {
  border-top-color: #1e90ff;
}
.popover-info.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-info.bs-popover-right .arrow::after {
  border-right-color: #1e90ff;
}
.popover-info.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-info.bs-popover-bottom .arrow::after {
  border-bottom-color: #1e90ff;
}
.popover-info.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-info.bs-popover-left .arrow::after {
  border-left-color: #1e90ff;
}
.popover-warning {
  background-color: #f5b759;
}
.popover-warning .popover-header {
  background-color: #f5b759;
  color: #fff;
}
.popover-warning .popover-body {
  color: #fff;
}
.popover-warning .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-warning.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-warning.bs-popover-top .arrow::after {
  border-top-color: #f5b759;
}
.popover-warning.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-warning.bs-popover-right .arrow::after {
  border-right-color: #f5b759;
}
.popover-warning.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-warning.bs-popover-bottom .arrow::after {
  border-bottom-color: #f5b759;
}
.popover-warning.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-warning.bs-popover-left .arrow::after {
  border-left-color: #f5b759;
}
.popover-danger {
  background-color: #fa5252;
}
.popover-danger .popover-header {
  background-color: #fa5252;
  color: #fff;
}
.popover-danger .popover-body {
  color: #fff;
}
.popover-danger .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-danger.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-danger.bs-popover-top .arrow::after {
  border-top-color: #fa5252;
}
.popover-danger.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-danger.bs-popover-right .arrow::after {
  border-right-color: #fa5252;
}
.popover-danger.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-danger.bs-popover-bottom .arrow::after {
  border-bottom-color: #fa5252;
}
.popover-danger.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-danger.bs-popover-left .arrow::after {
  border-left-color: #fa5252;
}
.popover-light {
  background-color: #dde1ed;
}
.popover-light .popover-header {
  background-color: #dde1ed;
  color: #506690;
}
.popover-light .popover-body {
  color: #506690;
}
.popover-light .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-light.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-light.bs-popover-top .arrow::after {
  border-top-color: #dde1ed;
}
.popover-light.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-light.bs-popover-right .arrow::after {
  border-right-color: #dde1ed;
}
.popover-light.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-light.bs-popover-bottom .arrow::after {
  border-bottom-color: #dde1ed;
}
.popover-light.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-light.bs-popover-left .arrow::after {
  border-left-color: #dde1ed;
}
.popover-dark {
  background-color: #2a354f;
}
.popover-dark .popover-header {
  background-color: #2a354f;
  color: #fff;
}
.popover-dark .popover-body {
  color: #fff;
}
.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-dark.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-dark.bs-popover-top .arrow::after {
  border-top-color: #2a354f;
}
.popover-dark.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-dark.bs-popover-right .arrow::after {
  border-right-color: #2a354f;
}
.popover-dark.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-dark.bs-popover-bottom .arrow::after {
  border-bottom-color: #2a354f;
}
.popover-dark.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-dark.bs-popover-left .arrow::after {
  border-left-color: #2a354f;
}
.popover-tertiary {
  background-color: #de6926;
}
.popover-tertiary .popover-header {
  background-color: #de6926;
  color: #fff;
}
.popover-tertiary .popover-body {
  color: #fff;
}
.popover-tertiary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-tertiary.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-tertiary.bs-popover-top .arrow::after {
  border-top-color: #de6926;
}
.popover-tertiary.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-tertiary.bs-popover-right .arrow::after {
  border-right-color: #de6926;
}
.popover-tertiary.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-tertiary.bs-popover-bottom .arrow::after {
  border-bottom-color: #de6926;
}
.popover-tertiary.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-tertiary.bs-popover-left .arrow::after {
  border-left-color: #de6926;
}
.popover-orange {
  background-color: #ff7f00;
}
.popover-orange .popover-header {
  background-color: #ff7f00;
  color: #fff;
}
.popover-orange .popover-body {
  color: #fff;
}
.popover-orange .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-orange.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-orange.bs-popover-top .arrow::after {
  border-top-color: #ff7f00;
}
.popover-orange.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-orange.bs-popover-right .arrow::after {
  border-right-color: #ff7f00;
}
.popover-orange.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-orange.bs-popover-bottom .arrow::after {
  border-bottom-color: #ff7f00;
}
.popover-orange.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-orange.bs-popover-left .arrow::after {
  border-left-color: #ff7f00;
}
.popover-white {
  background-color: #fff;
}
.popover-white .popover-header {
  background-color: #fff;
  color: #506690;
}
.popover-white .popover-body {
  color: #506690;
}
.popover-white .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-white.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-white.bs-popover-top .arrow::after {
  border-top-color: #fff;
}
.popover-white.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-white.bs-popover-right .arrow::after {
  border-right-color: #fff;
}
.popover-white.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-white.bs-popover-bottom .arrow::after {
  border-bottom-color: #fff;
}
.popover-white.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-white.bs-popover-left .arrow::after {
  border-left-color: #fff;
}
.popover-gray {
  background-color: #66799e;
}
.popover-gray .popover-header {
  background-color: #66799e;
  color: #fff;
}
.popover-gray .popover-body {
  color: #fff;
}
.popover-gray .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-gray.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray.bs-popover-top .arrow::after {
  border-top-color: #66799e;
}
.popover-gray.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray.bs-popover-right .arrow::after {
  border-right-color: #66799e;
}
.popover-gray.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray.bs-popover-bottom .arrow::after {
  border-bottom-color: #66799e;
}
.popover-gray.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray.bs-popover-left .arrow::after {
  border-left-color: #66799e;
}
.popover-neutral {
  background-color: #fff;
}
.popover-neutral .popover-header {
  background-color: #fff;
  color: #506690;
}
.popover-neutral .popover-body {
  color: #506690;
}
.popover-neutral .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-neutral.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-neutral.bs-popover-top .arrow::after {
  border-top-color: #fff;
}
.popover-neutral.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-neutral.bs-popover-right .arrow::after {
  border-right-color: #fff;
}
.popover-neutral.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-neutral.bs-popover-bottom .arrow::after {
  border-bottom-color: #fff;
}
.popover-neutral.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-neutral.bs-popover-left .arrow::after {
  border-left-color: #fff;
}
.popover-soft {
  background-color: #f3f7fa;
}
.popover-soft .popover-header {
  background-color: #f3f7fa;
  color: #506690;
}
.popover-soft .popover-body {
  color: #506690;
}
.popover-soft .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-soft.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-soft.bs-popover-top .arrow::after {
  border-top-color: #f3f7fa;
}
.popover-soft.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-soft.bs-popover-right .arrow::after {
  border-right-color: #f3f7fa;
}
.popover-soft.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-soft.bs-popover-bottom .arrow::after {
  border-bottom-color: #f3f7fa;
}
.popover-soft.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-soft.bs-popover-left .arrow::after {
  border-left-color: #f3f7fa;
}
.popover-black {
  background-color: #3e4555;
}
.popover-black .popover-header {
  background-color: #3e4555;
  color: #fff;
}
.popover-black .popover-body {
  color: #fff;
}
.popover-black .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-black.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-black.bs-popover-top .arrow::after {
  border-top-color: #3e4555;
}
.popover-black.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-black.bs-popover-right .arrow::after {
  border-right-color: #3e4555;
}
.popover-black.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-black.bs-popover-bottom .arrow::after {
  border-bottom-color: #3e4555;
}
.popover-black.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-black.bs-popover-left .arrow::after {
  border-left-color: #3e4555;
}
.popover-purple {
  background-color: #8965e0;
}
.popover-purple .popover-header {
  background-color: #8965e0;
  color: #fff;
}
.popover-purple .popover-body {
  color: #fff;
}
.popover-purple .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-purple.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-purple.bs-popover-top .arrow::after {
  border-top-color: #8965e0;
}
.popover-purple.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-purple.bs-popover-right .arrow::after {
  border-right-color: #8965e0;
}
.popover-purple.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-purple.bs-popover-bottom .arrow::after {
  border-bottom-color: #8965e0;
}
.popover-purple.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-purple.bs-popover-left .arrow::after {
  border-left-color: #8965e0;
}
.popover-gray-100 {
  background-color: #f3f7fa;
}
.popover-gray-100 .popover-header {
  background-color: #f3f7fa;
  color: #506690;
}
.popover-gray-100 .popover-body {
  color: #506690;
}
.popover-gray-100 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-gray-100.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-100.bs-popover-top .arrow::after {
  border-top-color: #f3f7fa;
}
.popover-gray-100.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-100.bs-popover-right .arrow::after {
  border-right-color: #f3f7fa;
}
.popover-gray-100.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-100.bs-popover-bottom .arrow::after {
  border-bottom-color: #f3f7fa;
}
.popover-gray-100.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-100.bs-popover-left .arrow::after {
  border-left-color: #f3f7fa;
}
.popover-gray-200 {
  background-color: #e9ecef;
}
.popover-gray-200 .popover-header {
  background-color: #e9ecef;
  color: #506690;
}
.popover-gray-200 .popover-body {
  color: #506690;
}
.popover-gray-200 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-gray-200.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-200.bs-popover-top .arrow::after {
  border-top-color: #e9ecef;
}
.popover-gray-200.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-200.bs-popover-right .arrow::after {
  border-right-color: #e9ecef;
}
.popover-gray-200.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-200.bs-popover-bottom .arrow::after {
  border-bottom-color: #e9ecef;
}
.popover-gray-200.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-200.bs-popover-left .arrow::after {
  border-left-color: #e9ecef;
}
.popover-gray-300 {
  background-color: #dde1ed;
}
.popover-gray-300 .popover-header {
  background-color: #dde1ed;
  color: #506690;
}
.popover-gray-300 .popover-body {
  color: #506690;
}
.popover-gray-300 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-gray-300.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-300.bs-popover-top .arrow::after {
  border-top-color: #dde1ed;
}
.popover-gray-300.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-300.bs-popover-right .arrow::after {
  border-right-color: #dde1ed;
}
.popover-gray-300.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-300.bs-popover-bottom .arrow::after {
  border-bottom-color: #dde1ed;
}
.popover-gray-300.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-300.bs-popover-left .arrow::after {
  border-left-color: #dde1ed;
}
.popover-gray-400 {
  background-color: #e0e6f0;
}
.popover-gray-400 .popover-header {
  background-color: #e0e6f0;
  color: #506690;
}
.popover-gray-400 .popover-body {
  color: #506690;
}
.popover-gray-400 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}
.popover-gray-400.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-400.bs-popover-top .arrow::after {
  border-top-color: #e0e6f0;
}
.popover-gray-400.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-400.bs-popover-right .arrow::after {
  border-right-color: #e0e6f0;
}
.popover-gray-400.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-400.bs-popover-bottom .arrow::after {
  border-bottom-color: #e0e6f0;
}
.popover-gray-400.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-400.bs-popover-left .arrow::after {
  border-left-color: #e0e6f0;
}
.popover-gray-500 {
  background-color: #adb5bd;
}
.popover-gray-500 .popover-header {
  background-color: #adb5bd;
  color: #fff;
}
.popover-gray-500 .popover-body {
  color: #fff;
}
.popover-gray-500 .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-gray-500.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-500.bs-popover-top .arrow::after {
  border-top-color: #adb5bd;
}
.popover-gray-500.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-500.bs-popover-right .arrow::after {
  border-right-color: #adb5bd;
}
.popover-gray-500.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-500.bs-popover-bottom .arrow::after {
  border-bottom-color: #adb5bd;
}
.popover-gray-500.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-500.bs-popover-left .arrow::after {
  border-left-color: #adb5bd;
}
.popover-gray-600 {
  background-color: #869ab8;
}
.popover-gray-600 .popover-header {
  background-color: #869ab8;
  color: #fff;
}
.popover-gray-600 .popover-body {
  color: #fff;
}
.popover-gray-600 .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-gray-600.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-600.bs-popover-top .arrow::after {
  border-top-color: #869ab8;
}
.popover-gray-600.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-600.bs-popover-right .arrow::after {
  border-right-color: #869ab8;
}
.popover-gray-600.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-600.bs-popover-bottom .arrow::after {
  border-bottom-color: #869ab8;
}
.popover-gray-600.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-600.bs-popover-left .arrow::after {
  border-left-color: #869ab8;
}
.popover-gray-700 {
  background-color: #66799e;
}
.popover-gray-700 .popover-header {
  background-color: #66799e;
  color: #fff;
}
.popover-gray-700 .popover-body {
  color: #fff;
}
.popover-gray-700 .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-gray-700.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-700.bs-popover-top .arrow::after {
  border-top-color: #66799e;
}
.popover-gray-700.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-700.bs-popover-right .arrow::after {
  border-right-color: #66799e;
}
.popover-gray-700.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-700.bs-popover-bottom .arrow::after {
  border-bottom-color: #66799e;
}
.popover-gray-700.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-700.bs-popover-left .arrow::after {
  border-left-color: #66799e;
}
.popover-gray-800 {
  background-color: #506690;
}
.popover-gray-800 .popover-header {
  background-color: #506690;
  color: #fff;
}
.popover-gray-800 .popover-body {
  color: #fff;
}
.popover-gray-800 .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-gray-800.bs-popover-auto[x-placement^='top'] .arrow::after,
.popover-gray-800.bs-popover-top .arrow::after {
  border-top-color: #506690;
}
.popover-gray-800.bs-popover-auto[x-placement^='right'] .arrow::after,
.popover-gray-800.bs-popover-right .arrow::after {
  border-right-color: #506690;
}
.popover-gray-800.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.popover-gray-800.bs-popover-bottom .arrow::after {
  border-bottom-color: #506690;
}
.popover-gray-800.bs-popover-auto[x-placement^='left'] .arrow::after,
.popover-gray-800.bs-popover-left .arrow::after {
  border-left-color: #506690;
}
.progress-wrapper {
  position: relative;
}
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}
.progress {
  height: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.125rem;
  background-color: #e9ecef;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-xl {
  height: 0.7rem;
}
.progress-lg {
  height: 0.5rem;
}
.progress-sm {
  height: 0.2rem;
}
.progress-info {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 0.5rem;
}
.progress-info .progress-label span {
  display: inline-block;
  color: #11ab7c;
  font-size: 0.875rem;
  font-weight: 600;
}
.progress-info .progress-percentage {
  text-align: right;
}
.progress-info .progress-percentage span {
  display: inline-block;
  color: #adb5bd;
  font-size: 0.75rem;
  font-weight: 600;
}
.info-xl .progress-label span,
.info-xl .progress-percentage span {
  font-size: 0.875rem;
}
.info-xl .progress-percentage {
  text-align: right;
}
.progress-tooltip {
  background: #11ab7c;
  color: #fff;
  font-weight: 600;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: 0.75rem;
  position: relative;
  border-radius: 0.125rem;
}
.progress-tooltip:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 10px;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-top-color: #66799e;
  border-width: 6px;
}
@keyframes animate-positive {
  0% {
    width: 0%;
  }
}
.progress-info .bg-primary::after {
  border-top-color: #11ab7c;
}
.progress-info .bg-secondary::after {
  border-top-color: #005ad4;
}
.progress-info .bg-success::after {
  border-top-color: #00bf9a;
}
.progress-info .bg-info::after {
  border-top-color: #1e90ff;
}
.progress-info .bg-warning::after {
  border-top-color: #f5b759;
}
.progress-info .bg-danger::after {
  border-top-color: #fa5252;
}
.progress-info .bg-light::after {
  border-top-color: #dde1ed;
}
.progress-info .bg-dark::after {
  border-top-color: #2a354f;
}
.progress-info .bg-tertiary::after {
  border-top-color: #de6926;
}
.progress-info .bg-orange::after {
  border-top-color: #ff7f00;
}
.progress-info .bg-white::after {
  border-top-color: #fff;
}
.progress-info .bg-gray::after {
  border-top-color: #66799e;
}
.progress-info .bg-neutral::after {
  border-top-color: #fff;
}
.progress-info .bg-soft::after {
  border-top-color: #f3f7fa;
}
.progress-info .bg-black::after {
  border-top-color: #3e4555;
}
.progress-info .bg-purple::after {
  border-top-color: #8965e0;
}
.progress-info .bg-gray-100::after {
  border-top-color: #f3f7fa;
}
.progress-info .bg-gray-200::after {
  border-top-color: #e9ecef;
}
.progress-info .bg-gray-300::after {
  border-top-color: #dde1ed;
}
.progress-info .bg-gray-400::after {
  border-top-color: #e0e6f0;
}
.progress-info .bg-gray-500::after {
  border-top-color: #adb5bd;
}
.progress-info .bg-gray-600::after {
  border-top-color: #869ab8;
}
.progress-info .bg-gray-700::after {
  border-top-color: #66799e;
}
.progress-info .bg-gray-800::after {
  border-top-color: #506690;
}
.pattern {
  overflow: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
.pattern.top {
  top: 0;
  transform: rotate(180deg);
}
.pattern.bottom {
  bottom: 0;
}
.pattern svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.organic-shape:not(.position-relative) {
  position: absolute;
}
.organic-shape svg {
  background: 0 0 !important;
}
.organic-shape.top {
  top: 0;
}
.organic-shape.left {
  left: 0;
}
.organic-shape.right {
  right: 0;
}
.organic-shape.middle-y {
  top: 50%;
}
.organic-shape.middle-x {
  left: 50%;
}
.organic-shape.bottom {
  bottom: 0;
}
.organic-shape.scale-2 svg {
  transform: scale(2);
}
.organic-shape.scale-3 svg {
  transform: scale(3);
}
.organic-shape.scale-4 svg {
  transform: scale(4);
}
.organic-shape.scale-5 svg {
  transform: scale(5);
}
.organic-shape-primary {
  fill: rgba(17, 171, 124, 0.3);
}
.organic-shape-secondary {
  fill: rgba(0, 90, 212, 0.3);
}
.organic-shape-success {
  fill: rgba(0, 191, 154, 0.3);
}
.organic-shape-info {
  fill: rgba(30, 144, 255, 0.3);
}
.organic-shape-warning {
  fill: rgba(245, 183, 89, 0.3);
}
.organic-shape-danger {
  fill: rgba(250, 82, 82, 0.3);
}
.organic-shape-light {
  fill: rgba(221, 225, 237, 0.3);
}
.organic-shape-dark {
  fill: rgba(42, 53, 79, 0.3);
}
.organic-shape-tertiary {
  fill: rgba(222, 105, 38, 0.3);
}
.organic-shape-orange {
  fill: rgba(255, 127, 0, 0.3);
}
.organic-shape-white {
  fill: rgba(255, 255, 255, 0.3);
}
.organic-shape-gray {
  fill: rgba(102, 121, 158, 0.3);
}
.organic-shape-neutral {
  fill: rgba(255, 255, 255, 0.3);
}
.organic-shape-soft {
  fill: rgba(243, 247, 250, 0.3);
}
.organic-shape-black {
  fill: rgba(62, 69, 85, 0.3);
}
.organic-shape-purple {
  fill: rgba(137, 101, 224, 0.3);
}
.organic-shape-gray-100 {
  fill: rgba(243, 247, 250, 0.3);
}
.organic-shape-gray-200 {
  fill: rgba(233, 236, 239, 0.3);
}
.organic-shape-gray-300 {
  fill: rgba(221, 225, 237, 0.3);
}
.organic-shape-gray-400 {
  fill: rgba(224, 230, 240, 0.3);
}
.organic-shape-gray-500 {
  fill: rgba(173, 181, 189, 0.3);
}
.organic-shape-gray-600 {
  fill: rgba(134, 154, 184, 0.3);
}
.organic-shape-gray-700 {
  fill: rgba(102, 121, 158, 0.3);
}
.organic-shape-gray-800 {
  fill: rgba(80, 102, 144, 0.3);
}
.fill-primary {
  fill: #11ab7c;
}
.stroke-primary {
  stroke: #11ab7c;
}
.fill-secondary {
  fill: #005ad4;
}
.stroke-secondary {
  stroke: #005ad4;
}
.fill-success {
  fill: #00bf9a;
}
.stroke-success {
  stroke: #00bf9a;
}
.fill-info {
  fill: #1e90ff;
}
.stroke-info {
  stroke: #1e90ff;
}
.fill-warning {
  fill: #f5b759;
}
.stroke-warning {
  stroke: #f5b759;
}
.fill-danger {
  fill: #fa5252;
}
.stroke-danger {
  stroke: #fa5252;
}
.fill-light {
  fill: #dde1ed;
}
.stroke-light {
  stroke: #dde1ed;
}
.fill-dark {
  fill: #2a354f;
}
.stroke-dark {
  stroke: #2a354f;
}
.fill-tertiary {
  fill: #de6926;
}
.stroke-tertiary {
  stroke: #de6926;
}
.fill-orange {
  fill: #ff7f00;
}
.stroke-orange {
  stroke: #ff7f00;
}
.fill-white {
  fill: #fff;
}
.stroke-white {
  stroke: #fff;
}
.fill-gray {
  fill: #66799e;
}
.stroke-gray {
  stroke: #66799e;
}
.fill-neutral {
  fill: #fff;
}
.stroke-neutral {
  stroke: #fff;
}
.fill-soft {
  fill: #f3f7fa;
}
.stroke-soft {
  stroke: #f3f7fa;
}
.fill-black {
  fill: #3e4555;
}
.stroke-black {
  stroke: #3e4555;
}
.fill-purple {
  fill: #8965e0;
}
.stroke-purple {
  stroke: #8965e0;
}
.fill-gray-100 {
  fill: #f3f7fa;
}
.stroke-gray-100 {
  stroke: #f3f7fa;
}
.fill-gray-200 {
  fill: #e9ecef;
}
.stroke-gray-200 {
  stroke: #e9ecef;
}
.fill-gray-300 {
  fill: #dde1ed;
}
.stroke-gray-300 {
  stroke: #dde1ed;
}
.fill-gray-400 {
  fill: #e0e6f0;
}
.stroke-gray-400 {
  stroke: #e0e6f0;
}
.fill-gray-500 {
  fill: #adb5bd;
}
.stroke-gray-500 {
  stroke: #adb5bd;
}
.fill-gray-600 {
  fill: #869ab8;
}
.stroke-gray-600 {
  stroke: #869ab8;
}
.fill-gray-700 {
  fill: #66799e;
}
.stroke-gray-700 {
  stroke: #66799e;
}
.fill-gray-800 {
  fill: #506690;
}
.stroke-gray-800 {
  stroke: #506690;
}
.shape-primary {
  background-color: #11ab7c;
}
.shape-primary .step-number {
  background-color: #11ab7c;
}
.shape-secondary {
  background-color: #005ad4;
}
.shape-secondary .step-number {
  background-color: #005ad4;
}
.shape-success {
  background-color: #00bf9a;
}
.shape-success .step-number {
  background-color: #00bf9a;
}
.shape-info {
  background-color: #1e90ff;
}
.shape-info .step-number {
  background-color: #1e90ff;
}
.shape-warning {
  background-color: #f5b759;
}
.shape-warning .step-number {
  background-color: #f5b759;
}
.shape-danger {
  background-color: #fa5252;
}
.shape-danger .step-number {
  background-color: #fa5252;
}
.shape-light {
  background-color: #dde1ed;
}
.shape-light .step-number {
  background-color: #dde1ed;
}
.shape-dark {
  background-color: #2a354f;
}
.shape-dark .step-number {
  background-color: #2a354f;
}
.shape-tertiary {
  background-color: #de6926;
}
.shape-tertiary .step-number {
  background-color: #de6926;
}
.shape-orange {
  background-color: #ff7f00;
}
.shape-orange .step-number {
  background-color: #ff7f00;
}
.shape-white {
  background-color: #fff;
}
.shape-white .step-number {
  background-color: #fff;
}
.shape-gray {
  background-color: #66799e;
}
.shape-gray .step-number {
  background-color: #66799e;
}
.shape-neutral {
  background-color: #fff;
}
.shape-neutral .step-number {
  background-color: #fff;
}
.shape-soft {
  background-color: #f3f7fa;
}
.shape-soft .step-number {
  background-color: #f3f7fa;
}
.shape-black {
  background-color: #3e4555;
}
.shape-black .step-number {
  background-color: #3e4555;
}
.shape-purple {
  background-color: #8965e0;
}
.shape-purple .step-number {
  background-color: #8965e0;
}
.shape-gray-100 {
  background-color: #f3f7fa;
}
.shape-gray-100 .step-number {
  background-color: #f3f7fa;
}
.shape-gray-200 {
  background-color: #e9ecef;
}
.shape-gray-200 .step-number {
  background-color: #e9ecef;
}
.shape-gray-300 {
  background-color: #dde1ed;
}
.shape-gray-300 .step-number {
  background-color: #dde1ed;
}
.shape-gray-400 {
  background-color: #e0e6f0;
}
.shape-gray-400 .step-number {
  background-color: #e0e6f0;
}
.shape-gray-500 {
  background-color: #adb5bd;
}
.shape-gray-500 .step-number {
  background-color: #adb5bd;
}
.shape-gray-600 {
  background-color: #869ab8;
}
.shape-gray-600 .step-number {
  background-color: #869ab8;
}
.shape-gray-700 {
  background-color: #66799e;
}
.shape-gray-700 .step-number {
  background-color: #66799e;
}
.shape-gray-800 {
  background-color: #506690;
}
.shape-gray-800 .step-number {
  background-color: #506690;
}
.organic-radius {
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
}
.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.avatar-md {
  width: 2rem;
  height: 2rem;
}
.avatar-lg {
  width: 4rem;
  height: 4rem;
}
.color-shape {
  width: 7rem;
  height: 7rem;
}
.shape {
  position: absolute;
  pointer-events: none;
  transform: scale(2);
}
.shape.bottom {
  right: 0;
  bottom: 0;
  left: 0;
}
.shape-fluid > * {
  width: 100%;
  height: auto;
}
.step {
  position: relative;
  margin-bottom: 4rem;
  border-radius: 6px;
}
.step .step-shape {
  display: inline-flex;
  width: 7rem;
  height: 7rem;
  text-align: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.step .step-shape i,
.step .step-shape svg {
  font-size: 2rem;
}
.step .step-shape .step-number {
  position: absolute;
  font-weight: 600;
  top: -10px;
  width: 32px;
  height: 32px;
  border-width: 0.125rem;
  border-style: solid;
  line-height: 1.7;
}
.step .step-shape.bordered {
  border-width: 0.125rem;
  border-style: solid;
}
.step .step-shape.bordered .step-number {
  background-color: #fff;
  top: -12px;
}
.step .step-shape.rounded-circle .step-number {
  margin-right: 1.275rem;
}
.step .step-shape.organic-radius .step-number {
  margin-right: 1.25rem;
  border-radius: 63% 37% 30% 70%/50% 45% 55% 50%;
}
.step .icon-lg {
  font-size: 4rem;
}
.step .back-layer {
  display: flex;
  position: absolute;
  font-size: 12rem;
  z-index: 0;
  top: 0;
}
.layer-primary {
  color: rgba(17, 171, 124, 0.15);
}
.layer-secondary {
  color: rgba(0, 90, 212, 0.15);
}
.layer-success {
  color: rgba(0, 191, 154, 0.15);
}
.layer-info {
  color: rgba(30, 144, 255, 0.15);
}
.layer-warning {
  color: rgba(245, 183, 89, 0.15);
}
.layer-danger {
  color: rgba(250, 82, 82, 0.15);
}
.layer-light {
  color: rgba(221, 225, 237, 0.15);
}
.layer-dark {
  color: rgba(42, 53, 79, 0.15);
}
.layer-tertiary {
  color: rgba(222, 105, 38, 0.15);
}
.layer-orange {
  color: rgba(255, 127, 0, 0.15);
}
.layer-white {
  color: rgba(255, 255, 255, 0.15);
}
.layer-gray {
  color: rgba(102, 121, 158, 0.15);
}
.layer-neutral {
  color: rgba(255, 255, 255, 0.15);
}
.layer-soft {
  color: rgba(243, 247, 250, 0.15);
}
.layer-black {
  color: rgba(62, 69, 85, 0.15);
}
.layer-purple {
  color: rgba(137, 101, 224, 0.15);
}
.layer-gray-100 {
  color: rgba(243, 247, 250, 0.15);
}
.layer-gray-200 {
  color: rgba(233, 236, 239, 0.15);
}
.layer-gray-300 {
  color: rgba(221, 225, 237, 0.15);
}
.layer-gray-400 {
  color: rgba(224, 230, 240, 0.15);
}
.layer-gray-500 {
  color: rgba(173, 181, 189, 0.15);
}
.layer-gray-600 {
  color: rgba(134, 154, 184, 0.15);
}
.layer-gray-700 {
  color: rgba(102, 121, 158, 0.15);
}
.layer-gray-800 {
  color: rgba(80, 102, 144, 0.15);
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 2rem;
}
ol li,
p,
ul li {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}
ol,
p,
ul {
  margin-bottom: 1.5rem;
}
.text-action,
a {
  color: #11ab7c;
  font-weight: 400;
  transition: all 0.2s ease;
}
.text-action:hover,
a:hover {
  color: #0c7d5a;
  cursor: pointer;
}
.lead + .btn-wrapper {
  margin-top: 3rem;
}
@media (max-width: 991.98px) {
  .lead {
    font-size: 1rem;
  }
}
.text-italic {
  font-style: italic !important;
}
.description {
  font-size: 0.875rem;
}
.heading {
  letter-spacing: 0.025em;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
}
.heading-title {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
}
.heading-section {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
}
.heading-section img {
  display: block;
  width: 72px;
  height: 72px;
  margin-bottom: 1.5rem;
}
.heading-section.text-center img {
  margin-left: auto;
  margin-right: auto;
}
.display-1,
.display-2,
.display-3,
.display-4 {
  font-weight: 400;
  color: #2a354f;
}
.display-1 span,
.display-2 span,
.display-3 span,
.display-4 span {
  font-weight: 300;
}
@media (max-width: 991.98px) {
  .display-2 {
    font-size: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .display-3 {
    font-size: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .display-4 {
    font-size: 1.5rem;
  }
}
.blockquote {
  font-family: Sanchez, serif;
  font-style: italic;
  font-weight: 400;
}
.blockquote .blockquote-footer {
  font-size: 0.875rem;
  font-weight: 700;
}
.font-xs {
  font-size: 0.75rem;
  font-weight: 300;
}
.font-small {
  font-size: 0.875rem;
  font-weight: 300;
}
.font-base {
  font-size: 1rem;
  font-weight: 300;
}
.font-medium {
  font-size: 1.25rem;
  font-weight: 300;
}
.text-primary,
.text-primary:hover {
  color: #11ab7c !important;
}
.text-primary .display-1,
.text-primary .display-2,
.text-primary .display-3,
.text-primary .display-4,
.text-primary .h1,
.text-primary .h2,
.text-primary .h3,
.text-primary .h4,
.text-primary .h5,
.text-primary .h6,
.text-primary a:not(.btn),
.text-primary h1,
.text-primary h2,
.text-primary h3,
.text-primary h4,
.text-primary h5,
.text-primary h6 {
  color: #11ab7c;
}
.text-primary .text-muted {
  color: rgba(17, 171, 124, 0.7) !important;
}
.text-secondary,
.text-secondary:hover {
  color: #005ad4 !important;
}
.text-secondary .display-1,
.text-secondary .display-2,
.text-secondary .display-3,
.text-secondary .display-4,
.text-secondary .h1,
.text-secondary .h2,
.text-secondary .h3,
.text-secondary .h4,
.text-secondary .h5,
.text-secondary .h6,
.text-secondary a:not(.btn),
.text-secondary h1,
.text-secondary h2,
.text-secondary h3,
.text-secondary h4,
.text-secondary h5,
.text-secondary h6 {
  color: #005ad4;
}
.text-secondary .text-muted {
  color: rgba(0, 90, 212, 0.7) !important;
}
.text-success,
.text-success:hover {
  color: #00bf9a !important;
}
.text-success .display-1,
.text-success .display-2,
.text-success .display-3,
.text-success .display-4,
.text-success .h1,
.text-success .h2,
.text-success .h3,
.text-success .h4,
.text-success .h5,
.text-success .h6,
.text-success a:not(.btn),
.text-success h1,
.text-success h2,
.text-success h3,
.text-success h4,
.text-success h5,
.text-success h6 {
  color: #00bf9a;
}
.text-success .text-muted {
  color: rgba(0, 191, 154, 0.7) !important;
}
.text-info,
.text-info:hover {
  color: #1e90ff !important;
}
.text-info .display-1,
.text-info .display-2,
.text-info .display-3,
.text-info .display-4,
.text-info .h1,
.text-info .h2,
.text-info .h3,
.text-info .h4,
.text-info .h5,
.text-info .h6,
.text-info a:not(.btn),
.text-info h1,
.text-info h2,
.text-info h3,
.text-info h4,
.text-info h5,
.text-info h6 {
  color: #1e90ff;
}
.text-info .text-muted {
  color: rgba(30, 144, 255, 0.7) !important;
}
.text-warning,
.text-warning:hover {
  color: #f5b759 !important;
}
.text-warning .display-1,
.text-warning .display-2,
.text-warning .display-3,
.text-warning .display-4,
.text-warning .h1,
.text-warning .h2,
.text-warning .h3,
.text-warning .h4,
.text-warning .h5,
.text-warning .h6,
.text-warning a:not(.btn),
.text-warning h1,
.text-warning h2,
.text-warning h3,
.text-warning h4,
.text-warning h5,
.text-warning h6 {
  color: #f5b759;
}
.text-warning .text-muted {
  color: rgba(245, 183, 89, 0.7) !important;
}
.text-danger,
.text-danger:hover {
  color: #fa5252 !important;
}
.text-danger .display-1,
.text-danger .display-2,
.text-danger .display-3,
.text-danger .display-4,
.text-danger .h1,
.text-danger .h2,
.text-danger .h3,
.text-danger .h4,
.text-danger .h5,
.text-danger .h6,
.text-danger a:not(.btn),
.text-danger h1,
.text-danger h2,
.text-danger h3,
.text-danger h4,
.text-danger h5,
.text-danger h6 {
  color: #fa5252;
}
.text-danger .text-muted {
  color: rgba(250, 82, 82, 0.7) !important;
}
.text-light,
.text-light:hover {
  color: #dde1ed !important;
}
.text-light .display-1,
.text-light .display-2,
.text-light .display-3,
.text-light .display-4,
.text-light .h1,
.text-light .h2,
.text-light .h3,
.text-light .h4,
.text-light .h5,
.text-light .h6,
.text-light a:not(.btn),
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
  color: #dde1ed;
}
.text-light .text-muted {
  color: rgba(221, 225, 237, 0.7) !important;
}
.text-dark,
.text-dark:hover {
  color: #2a354f !important;
}
.text-dark .display-1,
.text-dark .display-2,
.text-dark .display-3,
.text-dark .display-4,
.text-dark .h1,
.text-dark .h2,
.text-dark .h3,
.text-dark .h4,
.text-dark .h5,
.text-dark .h6,
.text-dark a:not(.btn),
.text-dark h1,
.text-dark h2,
.text-dark h3,
.text-dark h4,
.text-dark h5,
.text-dark h6 {
  color: #2a354f;
}
.text-dark .text-muted {
  color: rgba(42, 53, 79, 0.7) !important;
}
.text-tertiary,
.text-tertiary:hover {
  color: #de6926 !important;
}
.text-tertiary .display-1,
.text-tertiary .display-2,
.text-tertiary .display-3,
.text-tertiary .display-4,
.text-tertiary .h1,
.text-tertiary .h2,
.text-tertiary .h3,
.text-tertiary .h4,
.text-tertiary .h5,
.text-tertiary .h6,
.text-tertiary a:not(.btn),
.text-tertiary h1,
.text-tertiary h2,
.text-tertiary h3,
.text-tertiary h4,
.text-tertiary h5,
.text-tertiary h6 {
  color: #de6926;
}
.text-tertiary .text-muted {
  color: rgba(222, 105, 38, 0.7) !important;
}
.text-orange,
.text-orange:hover {
  color: #ff7f00 !important;
}
.text-orange .display-1,
.text-orange .display-2,
.text-orange .display-3,
.text-orange .display-4,
.text-orange .h1,
.text-orange .h2,
.text-orange .h3,
.text-orange .h4,
.text-orange .h5,
.text-orange .h6,
.text-orange a:not(.btn),
.text-orange h1,
.text-orange h2,
.text-orange h3,
.text-orange h4,
.text-orange h5,
.text-orange h6 {
  color: #ff7f00;
}
.text-orange .text-muted {
  color: rgba(255, 127, 0, 0.7) !important;
}
.text-white,
.text-white:hover {
  color: #fff !important;
}
.text-white .display-1,
.text-white .display-2,
.text-white .display-3,
.text-white .display-4,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6,
.text-white a:not(.btn),
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}
.text-white .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-gray,
.text-gray:hover {
  color: #66799e !important;
}
.text-gray .display-1,
.text-gray .display-2,
.text-gray .display-3,
.text-gray .display-4,
.text-gray .h1,
.text-gray .h2,
.text-gray .h3,
.text-gray .h4,
.text-gray .h5,
.text-gray .h6,
.text-gray a:not(.btn),
.text-gray h1,
.text-gray h2,
.text-gray h3,
.text-gray h4,
.text-gray h5,
.text-gray h6 {
  color: #66799e;
}
.text-gray .text-muted {
  color: rgba(102, 121, 158, 0.7) !important;
}
.text-neutral,
.text-neutral:hover {
  color: #fff !important;
}
.text-neutral .display-1,
.text-neutral .display-2,
.text-neutral .display-3,
.text-neutral .display-4,
.text-neutral .h1,
.text-neutral .h2,
.text-neutral .h3,
.text-neutral .h4,
.text-neutral .h5,
.text-neutral .h6,
.text-neutral a:not(.btn),
.text-neutral h1,
.text-neutral h2,
.text-neutral h3,
.text-neutral h4,
.text-neutral h5,
.text-neutral h6 {
  color: #fff;
}
.text-neutral .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-soft,
.text-soft:hover {
  color: #f3f7fa !important;
}
.text-soft .display-1,
.text-soft .display-2,
.text-soft .display-3,
.text-soft .display-4,
.text-soft .h1,
.text-soft .h2,
.text-soft .h3,
.text-soft .h4,
.text-soft .h5,
.text-soft .h6,
.text-soft a:not(.btn),
.text-soft h1,
.text-soft h2,
.text-soft h3,
.text-soft h4,
.text-soft h5,
.text-soft h6 {
  color: #f3f7fa;
}
.text-soft .text-muted {
  color: rgba(243, 247, 250, 0.7) !important;
}
.text-black,
.text-black:hover {
  color: #3e4555 !important;
}
.text-black .display-1,
.text-black .display-2,
.text-black .display-3,
.text-black .display-4,
.text-black .h1,
.text-black .h2,
.text-black .h3,
.text-black .h4,
.text-black .h5,
.text-black .h6,
.text-black a:not(.btn),
.text-black h1,
.text-black h2,
.text-black h3,
.text-black h4,
.text-black h5,
.text-black h6 {
  color: #3e4555;
}
.text-black .text-muted {
  color: rgba(62, 69, 85, 0.7) !important;
}
.text-purple,
.text-purple:hover {
  color: #8965e0 !important;
}
.text-purple .display-1,
.text-purple .display-2,
.text-purple .display-3,
.text-purple .display-4,
.text-purple .h1,
.text-purple .h2,
.text-purple .h3,
.text-purple .h4,
.text-purple .h5,
.text-purple .h6,
.text-purple a:not(.btn),
.text-purple h1,
.text-purple h2,
.text-purple h3,
.text-purple h4,
.text-purple h5,
.text-purple h6 {
  color: #8965e0;
}
.text-purple .text-muted {
  color: rgba(137, 101, 224, 0.7) !important;
}
.text-gray-100,
.text-gray-100:hover {
  color: #f3f7fa !important;
}
.text-gray-100 .display-1,
.text-gray-100 .display-2,
.text-gray-100 .display-3,
.text-gray-100 .display-4,
.text-gray-100 .h1,
.text-gray-100 .h2,
.text-gray-100 .h3,
.text-gray-100 .h4,
.text-gray-100 .h5,
.text-gray-100 .h6,
.text-gray-100 a:not(.btn),
.text-gray-100 h1,
.text-gray-100 h2,
.text-gray-100 h3,
.text-gray-100 h4,
.text-gray-100 h5,
.text-gray-100 h6 {
  color: #f3f7fa;
}
.text-gray-100 .text-muted {
  color: rgba(243, 247, 250, 0.7) !important;
}
.text-gray-200,
.text-gray-200:hover {
  color: #e9ecef !important;
}
.text-gray-200 .display-1,
.text-gray-200 .display-2,
.text-gray-200 .display-3,
.text-gray-200 .display-4,
.text-gray-200 .h1,
.text-gray-200 .h2,
.text-gray-200 .h3,
.text-gray-200 .h4,
.text-gray-200 .h5,
.text-gray-200 .h6,
.text-gray-200 a:not(.btn),
.text-gray-200 h1,
.text-gray-200 h2,
.text-gray-200 h3,
.text-gray-200 h4,
.text-gray-200 h5,
.text-gray-200 h6 {
  color: #e9ecef;
}
.text-gray-200 .text-muted {
  color: rgba(233, 236, 239, 0.7) !important;
}
.text-gray-300,
.text-gray-300:hover {
  color: #dde1ed !important;
}
.text-gray-300 .display-1,
.text-gray-300 .display-2,
.text-gray-300 .display-3,
.text-gray-300 .display-4,
.text-gray-300 .h1,
.text-gray-300 .h2,
.text-gray-300 .h3,
.text-gray-300 .h4,
.text-gray-300 .h5,
.text-gray-300 .h6,
.text-gray-300 a:not(.btn),
.text-gray-300 h1,
.text-gray-300 h2,
.text-gray-300 h3,
.text-gray-300 h4,
.text-gray-300 h5,
.text-gray-300 h6 {
  color: #dde1ed;
}
.text-gray-300 .text-muted {
  color: rgba(221, 225, 237, 0.7) !important;
}
.text-gray-400,
.text-gray-400:hover {
  color: #e0e6f0 !important;
}
.text-gray-400 .display-1,
.text-gray-400 .display-2,
.text-gray-400 .display-3,
.text-gray-400 .display-4,
.text-gray-400 .h1,
.text-gray-400 .h2,
.text-gray-400 .h3,
.text-gray-400 .h4,
.text-gray-400 .h5,
.text-gray-400 .h6,
.text-gray-400 a:not(.btn),
.text-gray-400 h1,
.text-gray-400 h2,
.text-gray-400 h3,
.text-gray-400 h4,
.text-gray-400 h5,
.text-gray-400 h6 {
  color: #e0e6f0;
}
.text-gray-400 .text-muted {
  color: rgba(224, 230, 240, 0.7) !important;
}
.text-gray-500,
.text-gray-500:hover {
  color: #adb5bd !important;
}
.text-gray-500 .display-1,
.text-gray-500 .display-2,
.text-gray-500 .display-3,
.text-gray-500 .display-4,
.text-gray-500 .h1,
.text-gray-500 .h2,
.text-gray-500 .h3,
.text-gray-500 .h4,
.text-gray-500 .h5,
.text-gray-500 .h6,
.text-gray-500 a:not(.btn),
.text-gray-500 h1,
.text-gray-500 h2,
.text-gray-500 h3,
.text-gray-500 h4,
.text-gray-500 h5,
.text-gray-500 h6 {
  color: #adb5bd;
}
.text-gray-500 .text-muted {
  color: rgba(173, 181, 189, 0.7) !important;
}
.text-gray-600,
.text-gray-600:hover {
  color: #869ab8 !important;
}
.text-gray-600 .display-1,
.text-gray-600 .display-2,
.text-gray-600 .display-3,
.text-gray-600 .display-4,
.text-gray-600 .h1,
.text-gray-600 .h2,
.text-gray-600 .h3,
.text-gray-600 .h4,
.text-gray-600 .h5,
.text-gray-600 .h6,
.text-gray-600 a:not(.btn),
.text-gray-600 h1,
.text-gray-600 h2,
.text-gray-600 h3,
.text-gray-600 h4,
.text-gray-600 h5,
.text-gray-600 h6 {
  color: #869ab8;
}
.text-gray-600 .text-muted {
  color: rgba(134, 154, 184, 0.7) !important;
}
.text-gray-700,
.text-gray-700:hover {
  color: #66799e !important;
}
.text-gray-700 .display-1,
.text-gray-700 .display-2,
.text-gray-700 .display-3,
.text-gray-700 .display-4,
.text-gray-700 .h1,
.text-gray-700 .h2,
.text-gray-700 .h3,
.text-gray-700 .h4,
.text-gray-700 .h5,
.text-gray-700 .h6,
.text-gray-700 a:not(.btn),
.text-gray-700 h1,
.text-gray-700 h2,
.text-gray-700 h3,
.text-gray-700 h4,
.text-gray-700 h5,
.text-gray-700 h6 {
  color: #66799e;
}
.text-gray-700 .text-muted {
  color: rgba(102, 121, 158, 0.7) !important;
}
.text-gray-800,
.text-gray-800:hover {
  color: #506690 !important;
}
.text-gray-800 .display-1,
.text-gray-800 .display-2,
.text-gray-800 .display-3,
.text-gray-800 .display-4,
.text-gray-800 .h1,
.text-gray-800 .h2,
.text-gray-800 .h3,
.text-gray-800 .h4,
.text-gray-800 .h5,
.text-gray-800 .h6,
.text-gray-800 a:not(.btn),
.text-gray-800 h1,
.text-gray-800 h2,
.text-gray-800 h3,
.text-gray-800 h4,
.text-gray-800 h5,
.text-gray-800 h6 {
  color: #506690;
}
.text-gray-800 .text-muted {
  color: rgba(80, 102, 144, 0.7) !important;
}
.timeline {
  width: 100%;
}
.timeline.timeline-one .timeline-item {
  position: relative;
  width: 50%;
  margin-left: 50%;
  padding-bottom: 2.75rem;
  padding-left: 2.75rem;
  text-align: left;
}
@media (max-width: 767.98px) {
  .timeline.timeline-one .timeline-item {
    width: 100%;
    margin-left: 0;
  }
}
.timeline.timeline-one .timeline-item img {
  margin-bottom: 1.25rem;
}
.timeline.timeline-one .timeline-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: 0.425rem;
  margin-left: -0.5rem;
  border: 2px solid #e9ecef;
  border-radius: 50%;
}
.timeline.timeline-one .timeline-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: -0.1875rem;
  width: 2px;
  margin-top: 1.875rem;
  margin-left: -0.0625rem;
  background-color: #e9ecef;
}
.timeline.timeline-one .timeline-item:nth-child(2n + 1) {
  margin-left: 0;
  padding-right: 2.75rem;
  padding-left: 0;
  text-align: right;
}
@media (max-width: 767.98px) {
  .timeline.timeline-one .timeline-item:nth-child(2n + 1) {
    padding-right: 0;
    padding-left: 2.75rem;
    text-align: left;
  }
}
.timeline.timeline-one .timeline-item:nth-child(2n + 1):after,
.timeline.timeline-one .timeline-item:nth-child(2n + 1):before {
  right: 0;
  left: auto;
}
@media (max-width: 767.98px) {
  .timeline.timeline-one .timeline-item:nth-child(2n + 1):after,
  .timeline.timeline-one .timeline-item:nth-child(2n + 1):before {
    right: auto;
    left: 0;
  }
}
.timeline.timeline-one .timeline-item:nth-child(2n + 1):before {
  margin-right: -0.5rem;
}
.timeline.timeline-one .timeline-item:nth-child(2n + 1):after {
  margin-right: -0.0625rem;
}
.timeline.timeline-two {
  margin-left: 1.25rem;
}
.timeline.timeline-two .timeline-item {
  position: relative;
  background-color: #fff;
  padding: 2rem;
  text-align: left;
  margin-bottom: 2.75rem;
  margin-right: 15px;
  border: 1px solid;
}
.timeline.timeline-two .timeline-item:after,
.timeline.timeline-two .timeline-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}
.timeline.timeline-two .timeline-item:before {
  border: 2px solid #e9ecef;
  content: '';
  right: auto;
  width: 12px;
  height: 12px;
  margin-left: -1.5rem;
  border-radius: 50%;
}
.timeline.timeline-two .timeline-item:after {
  background-color: #e9ecef;
  width: 2px;
  height: 100%;
  margin-top: 1.7rem;
  right: auto;
  margin-left: -1.25rem;
}
.timeline.timeline-two .timeline-item .post-meta a i,
.timeline.timeline-two .timeline-item .post-meta span i {
  padding-right: 0.125rem;
}
.timeline.timeline-four {
  margin: 1.25rem 0;
  position: relative;
}
.timeline.timeline-four::before {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background: #e9ecef;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media (max-width: 767.98px) {
  .timeline.timeline-four::before {
    margin: 0;
    left: 0;
  }
}
.timeline.timeline-four .timeline-item {
  margin-bottom: 2.75rem;
  position: relative;
}
.timeline.timeline-four .timeline-item::after {
  content: '';
  display: block;
  clear: both;
}
.timeline.timeline-four .timeline-item:hover .date-outer::before {
  left: 6px;
}
.timeline.timeline-four .timeline-item:hover .date-outer::after {
  left: -6px;
}
.timeline.timeline-four .timeline-item:nth-child(2n) .date-content {
  float: right;
}
.timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
  padding: 0.625rem 3.125rem 0.625rem 0;
  text-align: right;
}
@media (max-width: 991.98px) {
  .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
    padding: 0.5rem 1.75rem 0.5rem 0;
  }
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
    width: 95%;
    padding: 0.5rem 0;
    text-align: center;
  }
}
.timeline.timeline-four .icon {
  width: 16px;
  height: 16px;
  margin: auto;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e9ecef;
  margin-top: 1rem;
  top: 0;
  left: 0;
  right: 0;
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .icon {
    margin: auto -7px;
    top: 3rem;
  }
}
.timeline.timeline-four .date-content {
  width: 50%;
  float: left;
  position: relative;
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .date-content {
    width: 95%;
    float: right;
  }
}
.timeline.timeline-four .date-outer {
  width: 150px;
  height: 150px;
  text-align: center;
  margin: auto;
  z-index: 1;
}
.timeline.timeline-four .date-outer::before {
  content: '';
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border: 2px solid #11ab7c;
  left: -6px;
  transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .date-outer::before {
    width: 130px;
    height: 130px;
  }
}
.timeline.timeline-four .date-outer::after {
  content: '';
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #11ab7c;
  left: 6px;
  transition: all 0.9s cubic-bezier(0.34, 1.45, 0.7, 1);
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .date-outer::after {
    width: 130px;
    height: 130px;
  }
}
.timeline.timeline-four .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 33%;
  left: 0;
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .date {
    top: 27%;
  }
}
.timeline.timeline-four .year {
  display: block;
  font-weight: 700;
  font-size: 0.875rem;
}
.timeline.timeline-four i {
  font-size: 1.5rem;
}
.timeline.timeline-four .timeline-content {
  width: 50%;
  padding: 0.625rem 0 0.625rem 3.125rem;
  float: right;
}
@media (max-width: 991.98px) {
  .timeline.timeline-four .timeline-content {
    padding: 0.5rem 0 0.5rem 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .timeline.timeline-four .timeline-content {
    width: 95%;
    padding: 0.5rem 0;
    text-align: center;
  }
}
.timeline.timeline-five .row .avatar-separator {
  width: 30px;
  height: 30px;
}
.timeline.timeline-five .row .col.middle-line {
  border-right: 2px solid #e9ecef;
}
.timeline.timeline-five .card .card-body .post-meta i {
  padding-right: 0.25rem;
}
.timeline.timeline-six .row .col.middle-line {
  border-right: 2px solid #e9ecef;
}
.timeline.timeline-six .row .avatar-separator img {
  border: 2px solid #e9ecef;
  padding: 0.125rem;
}
.vertical-timeline .row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vertical-timeline .row.timeline-inner div {
  padding: 0;
  height: 38px;
}
.vertical-timeline .row.timeline-inner hr {
  border-top: 2px solid #e9ecef;
  margin: 0;
  top: 1.0625rem;
  position: relative;
}
.vertical-timeline .row.timeline-inner .col-md-2 {
  display: flex;
  overflow: hidden;
}
.vertical-timeline .row.timeline-inner .corner {
  border: 2px solid #e9ecef;
  width: 100%;
  position: relative;
}
.vertical-timeline .row.timeline-inner .top-right {
  left: calc(50% - 1px);
  top: -50%;
}
.vertical-timeline .row.timeline-inner .left-bottom {
  left: -50%;
  top: calc(50% - 2px);
}
.vertical-timeline .row.timeline-inner .top-left {
  left: -50%;
  top: -50%;
}
.vertical-timeline .row.timeline-inner .right-bottom {
  left: 50%;
  top: calc(50% - 2px);
}
@media (max-width: 767.98px) {
  .vertical-timeline .row.timeline-inner .right-bottom {
    top: auto;
    bottom: calc(50% - 6px);
    left: calc(50% - 4px);
  }
  .vertical-timeline .row.timeline-inner .top-left {
    top: auto;
    bottom: -50%;
  }
}
@media (max-width: 767.98px) {
  .vertical-timeline .row .col-md-6 {
    text-align: center !important;
    margin-top: 3rem;
  }
}
.vertical-timeline .row .col-md-2 {
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.vertical-timeline .row .col-md-2:after {
  content: '';
  position: absolute;
  border-left: 2px solid #e9ecef;
  z-index: 1;
}
.vertical-timeline .row .col-md-2.bottom:after {
  height: 50%;
  top: 50%;
}
.vertical-timeline .row .col-md-2.full:after {
  height: 100%;
  left: calc(50% - 1px);
}
.vertical-timeline .row .col-md-2.top:after {
  height: 50%;
  left: 50%;
  top: 0;
}
.vertical-timeline .row .shape {
  background-color: #e9ecef;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.vertical-timeline .row .right {
  margin-right: 0.1875rem;
}
@media (max-width: 767.98px) {
  .vertical-timeline .row .rectangle {
    line-height: 3;
    margin-bottom: 2rem;
  }
  .vertical-timeline .row .right {
    margin-bottom: 0;
  }
}
.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(42, 53, 79, 0.12);
}
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #2a354f;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: all 0.2s ease;
}
.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #2a354f;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}
.ct-area,
.ct-line {
  pointer-events: none;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(17, 17, 17, 0.01);
}
.table td,
.table th {
  border-top: 0.0625rem solid #f1f1f1;
}
.table thead th {
  border-bottom: 0.125rem solid #f1f1f1;
  border-top: 0;
}
[data-tooltip] {
  position: relative;
  display: inline-block;
}

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
}

[data-tooltip]:before {
  content: '';
  position: absolute;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
}

[data-tooltip]:after {
  height: 32px;
  padding: 11px;
  font-size: 13px;
  line-height: 11px;
  content: attr(data-tooltip);
  white-space: nowrap;
}

[data-tooltip].simptip-position-top:before {
  border-top-color: #323232;
}

[data-tooltip].simptip-position-top:after {
  background-color: #323232;
  color: #ecf0f1;
}

[data-tooltip].simptip-position-bottom:before {
  border-bottom-color: #323232;
}

[data-tooltip].simptip-position-bottom:after {
  background-color: #323232;
  color: #ecf0f1;
}

[data-tooltip].simptip-position-left:before {
  border-left-color: #323232;
}

[data-tooltip].simptip-position-left:after {
  background-color: #323232;
  color: #ecf0f1;
}

[data-tooltip].simptip-position-right:before {
  border-right-color: #323232;
}

[data-tooltip].simptip-position-right:after {
  background-color: #323232;
  color: #ecf0f1;
}

[data-tooltip].simptip-position-top.half-arrow:before {
  border-right: 7px solid #323232;
}

[data-tooltip].simptip-position-bottom.half-arrow:before {
  border-right: 7px solid #323232;
}

[data-tooltip]:hover,
[data-tooltip]:focus {
  background-color: transparent;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after {
  visibility: visible;
  opacity: 1;
}

/******************************* Position ****************************/

.simptip-position-right.simptip-movable:before,
.simptip-position-right.simptip-movable:after,
.simptip-position-left.simptip-movable:before,
.simptip-position-left.simptip-movable:after,
.simptip-position-top.simptip-movable:before,
.simptip-position-top.simptip-movable:after,
.simptip-position-bottom.simptip-movable:before,
.simptip-position-bottom.simptip-movable:after {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.simptip-position-right:before,
.simptip-position-right:after,
.simptip-position-left:before,
.simptip-position-left:after {
  bottom: 50%;
}

.simptip-position-right:before,
.simptip-position-left:before {
  margin-bottom: -5px;
  /*-5px*/
}

.simptip-position-right:after,
.simptip-position-left:after {
  margin-bottom: -14.66667px;
}

.simptip-position-right.half-arrow:before,
.simptip-position-left.half-arrow:before {
  bottom: 16px;
  border-style: none;
  border-top: 7px solid transparent;
}

.simptip-position-right.simptip-multiline:before,
.simptip-position-right.simptip-multiline:after,
.simptip-position-left.simptip-multiline:before,
.simptip-position-left.simptip-multiline:after {
  bottom: -50%;
}

.simptip-position-right.simptip-multiline:before,
.simptip-position-left.simptip-multiline:before {
  margin-bottom: 15px;
}

.simptip-position-right:before,
.simptip-position-right:after {
  left: 100%;
}

.simptip-position-right:before {
  margin-left: -2px;
}

.simptip-position-right:after {
  margin-left: 10px;
}

.simptip-position-right.simptip-movable:before {
  margin-left: -10px;
}

.simptip-position-right.simptip-movable:after {
  margin-left: 2px;
}

.simptip-position-right.simptip-movable:hover:before,
.simptip-position-right.simptip-movable:hover:after {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}

.simptip-position-right.simptip-movable.half-arrow:before {
  margin-left: -5px;
}

.simptip-position-right.half-arrow:before {
  margin-left: 3px;
  border-right: 7px solid #323232;
}

.simptip-position-left:before,
.simptip-position-left:after {
  right: 100%;
}

.simptip-position-left:before {
  margin-right: -2px;
}

.simptip-position-left:after {
  margin-right: 10px;
}

.simptip-position-left.simptip-movable:before {
  margin-right: -10px;
}

.simptip-position-left.simptip-movable:after {
  margin-right: 2px;
}

.simptip-position-left.simptip-movable:hover:before,
.simptip-position-left.simptip-movable:hover:after {
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  transform: translateX(-10px);
}

.simptip-position-left.simptip-movable.half-arrow:before {
  margin-right: -5px;
}

.simptip-position-left.half-arrow:before {
  margin-right: 3px;
  border-left: 7px solid #323232;
}

.simptip-position-bottom:before,
.simptip-position-bottom:after,
.simptip-position-top:before,
.simptip-position-top:after {
  left: 30%;
}

.simptip-position-bottom:after,
.simptip-position-top:after {
  margin-left: -28px;
}

.simptip-position-bottom.half-arrow:before,
.simptip-position-top.half-arrow:before {
  border-style: none;
  border-right: 7px solid #323232;
}

.simptip-position-bottom:before,
.simptip-position-bottom:after {
  top: 100%;
}

.simptip-position-bottom:before {
  margin-top: -5px;
  /*-5px*/
}

.simptip-position-bottom:after {
  margin-top: 7px;
}

.simptip-position-bottom.simptip-movable:before {
  margin-top: -15px;
}

.simptip-position-bottom.simptip-movable:after {
  margin-top: -3px;
}

.simptip-position-bottom.simptip-movable:hover:before,
.simptip-position-bottom.simptip-movable:hover:after {
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

.simptip-position-bottom.simptip-movable.half-arrow:before {
  margin-top: -10px;
}

.simptip-position-bottom.half-arrow:before {
  margin-top: 0;
  border-top: 7px solid transparent;
}

.simptip-position-top:before,
.simptip-position-top:after {
  bottom: 100%;
}

.simptip-position-top:before {
  margin-bottom: -5px;
  /*-5px*/
}

.simptip-position-top:after {
  margin-bottom: 7px;
}

.simptip-position-top.simptip-movable:before {
  margin-bottom: -15px;
}

.simptip-position-top.simptip-movable:after {
  margin-bottom: -3px;
}

.simptip-position-top.simptip-movable:hover:before,
.simptip-position-top.simptip-movable:hover:after {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.simptip-position-top.simptip-movable.half-arrow:before {
  margin-bottom: -10px;
}

.simptip-position-top.half-arrow:before {
  margin-bottom: 0;
  border-bottom: 7px solid transparent;
}

/******************************* Features ****************************/

.simptip-smooth:after {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.simptip-fade:before,
.simptip-fade:after {
  -webkit-transition: opacity 0.2s linear, visibility 0.2s linear;
  -moz-transition: opacity 0.2s linear, visibility 0.2s linear;
  -ms-transition: opacity 0.2s linear, visibility 0.2s linear;
  -o-transition: opacity 0.2s linear, visibility 0.2s linear;
  transition: opacity 0.2s linear, visibility 0.2s linear;
}

.simptip-multiline:after {
  height: auto;
  width: 150px;
  padding: 11px;
  line-height: 19px;
  white-space: normal;
  text-align: left;
}

/**************************** Diverse Colors *************************/

.simptip-success.simptip-position-top:before {
  border-top-color: #62c462;
}

.simptip-success.simptip-position-top:after {
  background-color: #62c462;
  color: #ecf0f1;
}

.simptip-success.simptip-position-bottom:before {
  border-bottom-color: #62c462;
}

.simptip-success.simptip-position-bottom:after {
  background-color: #62c462;
  color: #ecf0f1;
}

.simptip-success.simptip-position-left:before {
  border-left-color: #62c462;
}

.simptip-success.simptip-position-left:after {
  background-color: #62c462;
  color: #ecf0f1;
}

.simptip-success.simptip-position-right:before {
  border-right-color: #62c462;
}

.simptip-success.simptip-position-right:after {
  background-color: #62c462;
  color: #ecf0f1;
}

.simptip-success.simptip-position-top.half-arrow:before {
  border-right: 7px solid #62c462;
}

.simptip-success.simptip-position-bottom.half-arrow:before {
  border-right: 7px solid #62c462;
}

.simptip-info.simptip-position-top:before {
  border-top-color: #5bc0de;
}

.simptip-info.simptip-position-top:after {
  background-color: #5bc0de;
  color: #ecf0f1;
}

.simptip-info.simptip-position-bottom:before {
  border-bottom-color: #5bc0de;
}

.simptip-info.simptip-position-bottom:after {
  background-color: #5bc0de;
  color: #ecf0f1;
}

.simptip-info.simptip-position-left:before {
  border-left-color: #5bc0de;
}

.simptip-info.simptip-position-left:after {
  background-color: #5bc0de;
  color: #ecf0f1;
}

.simptip-info.simptip-position-right:before {
  border-right-color: #5bc0de;
}

.simptip-info.simptip-position-right:after {
  background-color: #5bc0de;
  color: #ecf0f1;
}

.simptip-info.simptip-position-top.half-arrow:before {
  border-right: 7px solid #5bc0de;
}

.simptip-info.simptip-position-bottom.half-arrow:before {
  border-right: 7px solid #5bc0de;
}

.simptip-danger.simptip-position-top:before {
  border-top-color: #e74c3c;
}

.simptip-danger.simptip-position-top:after {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.simptip-danger.simptip-position-bottom:before {
  border-bottom-color: #e74c3c;
}

.simptip-danger.simptip-position-bottom:after {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.simptip-danger.simptip-position-left:before {
  border-left-color: #e74c3c;
}

.simptip-danger.simptip-position-left:after {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.simptip-danger.simptip-position-right:before {
  border-right-color: #e74c3c;
}

.simptip-danger.simptip-position-right:after {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.simptip-danger.simptip-position-top.half-arrow:before {
  border-right: 7px solid #e74c3c;
}

.simptip-danger.simptip-position-bottom.half-arrow:before {
  border-right: 7px solid #e74c3c;
}

.simptip-warning.simptip-position-top:before {
  border-top-color: #e67e22;
}

.simptip-warning.simptip-position-top:after {
  background-color: #e67e22;
  color: #ecf0f1;
}

.simptip-warning.simptip-position-bottom:before {
  border-bottom-color: #e67e22;
}

.simptip-warning.simptip-position-bottom:after {
  background-color: #e67e22;
  color: #ecf0f1;
}

.simptip-warning.simptip-position-left:before {
  border-left-color: #e67e22;
}

.simptip-warning.simptip-position-left:after {
  background-color: #e67e22;
  color: #ecf0f1;
}

.simptip-warning.simptip-position-right:before {
  border-right-color: #e67e22;
}

.simptip-warning.simptip-position-right:after {
  background-color: #e67e22;
  color: #ecf0f1;
}

.simptip-warning.simptip-position-top.half-arrow:before {
  border-right: 7px solid #e67e22;
}

.simptip-warning.simptip-position-bottom.half-arrow:before {
  border-right: 7px solid #e67e22;
}

.doc-sidebar {
  display: none;
}
@media (min-width: 992px) {
  #doc-index {
    display: block;
  }
  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
  }
  .doc-sidebar .nav-link[data-toggle='collapse'] > .icon {
    transform: rotateZ(-90deg);
    position: relative;
    right: 0.25rem;
  }
  .doc-sidebar .nav .nav-item {
    font-size: 0.875rem;
  }
}
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  transition: max-width 0.1s;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}
@media (min-width: 830px) {
  .sidebar {
    width: 100%;
    max-width: 260px;
  }
}
.sidebar .nav {
  white-space: nowrap;
}
.sidebar .nav-item {
  margin-bottom: 0.2rem;
}
.sidebar .nav-item.active > .nav-link {
  color: #d1d5db;
  background-color: #354562;
}
.sidebar .nav-item .nav-link {
  color: #2a354f;
  cursor: pointer;
}
.sidebar .nav-item .nav-link:hover {
  color: #2a354f;
  background-color: #f2f5f9;
}
.sidebar .nav-link + .multi-level {
  margin-top: 0.2rem;
}
.sidebar .nav-link {
  font-size: 1rem;
  vertical-align: middle;
  padding: 0.55rem 0.75rem;
  border-radius: 0.2rem;
}
.sidebar .nav-link .sidebar-icon {
  margin-right: 0.5rem;
  color: #fff;
}
.sidebar .nav-link .sidebar-icon span {
  min-width: 22px;
}
.sidebar .nav-link .link-arrow {
  font-size: 0.875rem;
}
.sidebar
  .nav-link[data-bs-toggle='collapse'][aria-expanded='true']
  .link-arrow {
  transform: rotate(90deg);
  transition: all 0.2s ease;
}
.sidebar .nav-link.active {
  color: #363c54;
}
.sidebar .multi-level .nav-link {
  padding-left: 45px;
}
.sidebar .badge,
.sidebar .link-arrow,
.sidebar .notification-count,
.sidebar .sidebar-text {
  opacity: 1;
}
.sidebar .sidebar-text-contracted {
  display: none;
}
.sidebar.contracted {
  max-width: 95px;
}
.sidebar.contracted .badge,
.sidebar.contracted .link-arrow,
.sidebar.contracted .sidebar-text {
  opacity: 0;
}
.sidebar.contracted .notification-count {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 40px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 0.7rem;
  padding: 0.2rem;
}
.sidebar.contracted .nav-item {
  position: relative;
}
.sidebar.contracted .nav-item.active > .nav-link {
  background-color: #363c54;
  width: 44px;
}
.sidebar.contracted .nav-item .nav-link:hover {
  background-color: #262b40;
}
.sidebar.contracted .sidebar-text-contracted {
  display: inline;
}
.sidebar.contracted .multi-level .nav-link {
  padding-left: 17px;
}
.sidebar.contracted .nav-item {
  white-space: nowrap;
}
.sidebar.contracted .sidebar-icon {
  text-align: center;
}
@media (min-width: 768px) {
  .sidebar.contracted + .content2 {
    margin-left: 95px;
  }
}
.sidebar-inner {
  position: relative;
  overflow-y: hidden;
}
.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}
@media (max-width: 575.98px) {
  .sidebar {
    width: 100%;
  }
}
.content2 {
  overflow: visible;
  padding: 0 1rem 0 1rem;
  transition: all 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .content2 {
    transition: none;
  }
}
@media (min-width: 830px) {
  .content2 {
    margin-left: 260px;
  }
}
.sidebar-toggle {
  font-size: 1.3rem;
}
.sidebar-toggle:focus,
.sidebar-toggle:hover {
  outline: 0;
  box-shadow: none;
  background: #d1d5db;
}
@media (max-width: 829px) {
  .sidebar-top-mobile {
    margin-top: 43px !important;
  }
  .setmargin {
    margin-top: 119px !important;
  }
}
input[type='color'] {
  height: 50px !important;
  border-radius: 5px;
}

@media (max-width: 767px) {
  input[type='color'] {
    height: 50px !important;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .classdefranco {
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
    justify-content: flex-start !important;
  }
  .btn_del_continue {
    margin-top: 12px;
  }
}

.row .col-10 .display-1 {
  font-size: calc(1.425rem + 4.5vw);
  margin-bottom: 1rem !important;
}

@media (max-width: 991px) {
  .w-100-en-991 {
    width: 100% !important;
  }
}


@media (min-width: 768px) {
  .profile-card {
    width: 40% !important;
    margin: 0 auto !important;
  }
}
